import gql from 'graphql-tag';

// id
// date
// userId

export default {
  query: gql`
    query History($entityName: String, $entityId: String) {
      history(entityName: $entityName, entityId: $entityId) {
        entries {
          date
          entityId
          entityName
          id
          operationType
          payload
          userId
          version
        }
      }
    }
  `,
};
