<template>
  <div class="custom-entity-field">
    <base-tags
      v-if="isRefsField(field)"
      :value="value"
    />

    <base-tags
      v-else-if="isArrayField(field) && getArrayDisplayValue(value).length"
      :value="getArrayDisplayValue(value).length"
    />

    <div
      v-else-if="isComponentField(field)"
      class="custom-entity-field__embed"
    >
      <slot name="embedd">{{ field }} — {{ (value || []).length }}</slot>
    </div>

    <base-tags
      v-else-if="isRefField(field)"
      :value="value"
    />

    <p v-else-if="isDateTimeField(field)">
      {{ formatDateTime(value) }}
    </p>

    <p v-else-if="isDateField(field)">
      {{ formatDate(value) }}
    </p>

    <p v-else-if="isTimeField(field)">
      {{ formatTime(value) }}
    </p>

    <p v-else-if="isColorField(field)">
      <color-plate :color="value" />
    </p>

    <template v-else-if="isImageField(field)">
      <base-image
        v-bind="imageParams(field)"
        class="image"
        :path="value"
        @click.native="showPreview(value)"
      />
    </template>

    <template v-else-if="isGalleryField(field)">
      <div class="custom-entity-field__gallery">
        <base-image
          v-for="path of value"
          :key="path"
          v-bind="imageParams(field)"
          class="image"
          :path="path"
          @click.native="showPreview(path)"
        />
      </div>
    </template>

    <p v-else-if="isBooleanField(field)">
      <span
        class="checkbox-wrap readOnly"
        @click.stop
      >
        <a-checkbox
          :checked="value"
          disabled
        >
          {{ field.label }}
        </a-checkbox>
      </span>
    </p>

    <p
      v-else-if="isWysiwygField(field)"
      class="tui-editor-contents"
      v-html="value"
    ></p>

    <div
      v-else-if="isJsonTextField(field)"
      class="custom-entity-field__json-wrapper"
    >
      <!-- eslint-disable-next-line vuejs-accessibility/mouse-events-have-key-events -->
      <pre
        v-html="getJsonValue(value)"
        @mouseup="jsonMouseUp"
        @mousemove="jsonMouseMove"
        @mouseleave="jsonMouseLeave"
        @click.stop
      ></pre>
    </div>

    <p
      v-else-if="isLongTextField(field)"
      class="white-pre"
      v-html="value"
    ></p>

    <p
      v-else-if="value"
      class="custom-entity-field__string"
    >
      {{ value }}
    </p>

    <a-modal
      :visible="!!activePreview"
      :footer="null"
      @cancel="closePreview"
    >
      <img
        style="width: 100%"
        :src="activePreview"
        alt=""
      />
    </a-modal>
  </div>
</template>

<script>
import { imageUrl, formatDateTime, formatDate, formatTime } from '@/helpers';
import BaseImage from '@/components/base/BaseImage.vue';
import BaseTags from '@/components/base/BaseTags.vue';
import ColorPlate from '@/components/base/ColorPlate.vue';

export default {
  name: 'KanbanCard',
  components: {
    BaseImage,
    BaseTags,
    ColorPlate,
  },

  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, Array, String, Number, Boolean],
      default: null,
    },
  },

  data() {
    return {
      activePreview: null,
      dragging: false,
    };
  },

  methods: {
    formatDateTime,
    formatDate,
    formatTime,

    isDateTimeField(field) {
      return field.renderer === 'date-time';
    },

    isDateField(field) {
      return field.renderer === 'date';
    },

    isTimeField(field) {
      return field.renderer === 'time';
    },

    isRefsField(field) {
      return ['refs', 'enum'].includes(field.renderer);
    },

    isArrayField(field) {
      return field.renderer === 'array';
    },

    isRefField(field) {
      return ['ref-like', 'ref', 'ref2'].includes(field.renderer);
    },

    isImageField(field) {
      return field.renderer === 'image';
    },

    isGalleryField(field) {
      return field.renderer === 'gallery';
    },

    isBooleanField(field) {
      return field.renderer === 'boolean';
    },

    isColorField(field) {
      return field.renderer === 'color';
    },

    isWysiwygField(field) {
      return field.renderer === 'wysiwyg';
    },

    isComponentField(field) {
      return ['component', 'embed'].includes(field.renderer);
    },

    isJsonTextField(field) {
      return field.renderer === 'json';
    },

    isLongTextField(field) {
      return field.renderer === 'text';
    },

    getJsonValue(value) {
      return value || '';
    },

    imageParams(field) {
      return {
        width: field.width || 100,
        height: field.height || 100,
      };
    },

    showPreview(path) {
      this.activePreview = imageUrl(path);
    },

    closePreview() {
      this.activePreview = null;
    },

    getArrayDisplayValue(value) {
      let displayValue = null;
      try {
        displayValue = value.slice(1, -1).split(',');
      } finally {
        displayValue = displayValue || [];
      }

      return displayValue;
    },

    jsonMouseLeave() {
      this.dragging = false;
    },

    jsonMouseMove({ which }) {
      if (which === 1) {
        this.dragging = true;
      }
    },

    jsonMouseUp({ which }) {
      if (!this.dragging && which === 1) {
        this.editEntity();
      }

      setTimeout(() => {
        this.dragging = false;
      }, 50);
    },
  },
};
</script>

<style lang="scss">
.custom-entity-field {
  &__json-wrapper {
    @include scrollbars();
    overflow-x: auto;
    padding-bottom: 5px;
    margin-bottom: 14px;
  }

  &__string {
    line-break: anywhere;
  }

  pre {
    overflow-x: visible;
    margin-bottom: 0;
    width: fit-content;
    font-size: 80%;
    -webkit-user-select: text;
    user-select: text;
    cursor: auto;
  }

  .tui-editor-contents {
    font-size: inherit;
    margin-bottom: 1em;
  }

  p.white-pre {
    white-space: pre-wrap;
  }

  & > .tags,
  & > .image,
  &__gallery {
    margin-bottom: 14px;
    max-width: 100%;
  }

  .tags:not(.clickable) > span {
    &:hover {
      opacity: 1;
    }
    &:after {
      // disable ant click animation
      display: none;
    }
  }

  &__gallery {
    .image {
      max-width: 100%;
      margin-right: 5px;
    }
  }

  .image:hover {
    opacity: 0.65;
    cursor: pointer;
  }

  .ant-checkbox-wrapper {
    color: inherit;
  }

  .color-plate {
    max-width: 120px;
  }

  &__embed {
    margin-bottom: 10px;
  }
}
</style>
