<template>
  <form-layout-provider
    #default="{ formLayout }"
    :formConfig="formConfig"
    :fieldsMeta="formMeta.fields"
  >
    <div>
      <entity-form
        :id="'NOID'"
        :type="'META_FORM'"
        :formLayout="formLayout"
        :sourceData="savedFormData"
        :currentData="currentFormData"
        :formConfig="formConfig"
        :descriptors="formMeta.fields"
        :formState="subFormState"
        canReadModel
        @dataChanged="onDataChange"
      />

      <div
        v-if="$slots.footer"
        class="publishable-form__footer"
      >
        <slot name="footer"></slot>
      </div>

      <a-alert
        v-if="formErrors.length"
        class="publishable-form__errors"
        :message="$t('metaForms.actionError')"
        :description="formErrors.join('<br />')"
        type="error"
      />
    </div>
  </form-layout-provider>
</template>

<script>
import { isEqual } from 'lodash';
import FormConfigService from '@/services/FormConfigService';
import FormLayoutProvider from '@/components/edit-form/FormLayoutProvider';
import EntityForm from '@/components/edit-form/EntityForm';

export default {
  components: {
    EntityForm,
    FormLayoutProvider,
  },

  props: {
    formName: {
      type: String,
      required: true,
    },
    formMeta: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
    currentData: {
      type: Object,
      required: true,
    },
    formState: {
      type: Object,
      required: true,
    },
    excludeFieldRenderers: {
      type: Array,
      default: () => ['history', 'history-entry', 'button'],
    },
    formErrors: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      formConfig: this.getConfig(),
      savedFormData: this.parsePublishableData(this.formData),
      currentFormData: this.parsePublishableData(this.currentData),
    };
  },

  computed: {
    subFormState() {
      const isDirty = !isEqual(this.formData, this.currentFormData);

      return {
        ...this.formState,
        handbooks: this.formMeta.handbooks,
        isDirty,
      };
    },
  },

  watch: {
    formData() {
      this.savedFormData = this.parsePublishableData(this.formData);
      this.currentFormData = this.parsePublishableData(this.formData);
    },

    currentData(value, oldValue) {
      if (value !== oldValue) {
        this.currentFormData = this.parsePublishableData(value);
      }
    },
  },

  methods: {
    /**
     * Преобразование структуры эмбедов из publishable в обычный вид
     */
    parsePublishableData(data, fields) {
      fields = fields || this.formMeta.fields;
      return fields.reduce((acc, field) => {
        let value = data[field.name];
        if (value && field.renderer === 'embed') {
          value = value.map(({ __typename, ...itemData }) => ({
            type: __typename,
            data: this.parsePublishableData(itemData, field.typesDict[__typename].fields),
          }));
        }

        acc[field.name] = value;
        return acc;
      }, {});
    },

    getConfig() {
      return FormConfigService.getPublishableFormConfig(this.formName, {
        ...this.formMeta,
        fields: this.formMeta.fields.filter(
          (field) => !this.excludeFieldRenderers.includes(field.renderer),
        ),
      });
    },

    onDataChange(data) {
      Object.keys(data).forEach((key) => {
        this.currentFormData[key] = data[key];
      });

      this.$emit('dataChanged', data);
    },
  },
};
</script>

<style lang="scss">
.publishable-form {
  &__footer {
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f9f9f9;
    margin-top: -20px;

    .publishable-modal-button {
      margin: 20px 20px 0 0;
    }
  }

  &__errors.ant-alert {
    margin-top: 30px;
  }
}
</style>
