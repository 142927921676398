<template>
  <div
    v-if="tablesConfigLoaded"
    class="the-page"
  >
    <component
      :is="customPageComponent"
      v-if="customPageComponent"
      @openInModal="openInModal"
    />

    <template v-else>
      <component
        :is="pageBlockComponent"
        v-for="(block, index) of blocks"
        :key="`${pageConfig.url}${block}`"
        :pageUrl="pageConfig.url"
        :entityType="block"
        :showSidebarButton="index === 0"
        @entityClick="editEntity"
        @openInModal="openInModal"
      />
    </template>

    <a-modal
      :class="['entity-modal-root', 'table-modal', { fullscreenModal: fullscreenModal }]"
      :visible="isModalVisible"
      :title="null"
      :footer="null"
      :closable="false"
      width="1170px"
      transitionName="none"
      maskTransitionName="none"
      @cancel="isModalVisible = false"
    >
      <component
        :is="modalBlockComponent"
        :pageUrl="modalEntityCode"
        :entityType="modalEntityCode"
        :setupFilters="modalEntityFilters"
        :setupSortParams="modalEntitySortParams"
        :showSidebarButton="false"
        :isUseConfig="false"
        @entityClick="(...args) => editEntity(...args, true)"
        @openInModal="openInModal"
      />
    </a-modal>
  </div>
</template>

<script>
import store from '@/store';
import TableConfigService from '@/services/TableConfigService';
import EntityViewHead from '@/components/edit-form/EntityViewHead';
import PageBlock from './pageblock/PageBlock.vue';
import Log from './log/TheLog';
import MetaForm from '../meta-form/MetaForm';
import PageBlockPublishable from './pageblock/PageBlock_Publishable.vue';

export default {
  name: 'ThePage',

  components: {
    PageBlock,
    PageBlockPublishable,
    EntityViewHead,

    // используются как значения свойства customprops.customPage в конфиге страницы:
    Log,
    MetaForm,
  },

  data() {
    return {
      tablesConfigLoaded: false,
      isModalVisible: false,
      pathForModal: null,
      fullscreenModal: false,
      modalEntityCode: null,
      modalEntityFilters: {},
      modalEntitySortParams: {},
      modalBlockComponent: false,
    };
  },

  computed: {
    fullscreenIcon() {
      return this.fullscreenModal ? 'fullscreen-exit' : 'fullscreen';
    },
    pageConfig() {
      return store.state.activeSidebarItem || {};
    },
    blocks() {
      return this.pageConfig.code ? [this.pageConfig.code] : [];
    },
    customPageComponent() {
      return this.pageConfig?.customprops?.customPage;
    },
    isPublishableEntity() {
      if (!this.pageConfig.code) return false;
      return !!store.state.meta.components[this.pageConfig.code]?.publishable;
    },
    pageBlockComponent() {
      return this.isPublishableEntity ? PageBlockPublishable : PageBlock;
    },
  },
  watch: {
    $route() {
      this.isModalVisible = false;
    },
  },

  async created() {
    await TableConfigService.load();
    this.tablesConfigLoaded = true;
  },

  methods: {
    editEntity(...args) {
      if (this.isModalVisible) {
        this.isModalVisible = false;
        const isClickFromModal = args.pop();
        if (isClickFromModal) {
          args[0] = this.modalEntityCode;
        }
      }
      this.$emit('editEntity', ...args);
    },
    openInModal(path) {
      this.isModalVisible = true;
      this.pathForModal = path;
      this.getDataByPath(path.path);
    },
    getDataByPath(path) {
      const [code, filters] = path.split(/[ ?/]+/).slice(-2);

      this.modalEntityCode = code;
      const b = decodeURIComponent(filters).split(/[&]+/);
      b.forEach((filter) => {
        const [key, value] = filter.split(/[=]+/);
        if (key === 'sort' || key === 'sortDir') {
          this.modalEntitySortParams[key] = value;
        } else {
          this.modalEntityFilters[key] = value;
        }
      });

      const isModalEntityPublishable = !!store.state.meta.components[code]?.publishable;
      this.modalBlockComponent = isModalEntityPublishable ? PageBlockPublishable : PageBlock;
    },
  },
};
</script>

<style lang="scss">
.the-page {
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-x: visible;
}

@media (min-width: $desktopBreakpoint) {
  .the-page {
    @include scrollbars();
    height: 100%;
    overflow-y: scroll;

    &.noscroll {
      overflow-y: hidden;
    }
  }

  .menu--vertical .the-page {
    padding-left: 40px;
    padding-right: 26px;

    &.noscroll {
      padding-right: 34px;
    }
  }

  .menu--horizontal .the-page {
    padding-left: 24px;
    padding-right: 24px;

    &.noscroll {
      padding-right: 32px;
    }
  }
}
</style>
