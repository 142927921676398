import gql from 'graphql-tag';

export default gql`
  mutation update($type: String, $id: String, $data: Json) {
    update(type: $type, id: $id, data: $data) {
      document {
        type
        id
        deleted
        data
      }
      errors {
        field
        msg
      }
    }
  }
`;
