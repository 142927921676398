<template>
  <a-time-picker
    v-model="value"
    class="filter-timepicker"
    :name="config.name"
    format="HH:mm"
    :placeholder="$t('base.placeholder.selectTime')"
    size="small"
    @change="value"
  />
</template>

<script>
export default {
  name: 'FilterElementTimepicker',
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  methods: {
    onChange(value) {
      this.value = value;
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss">
.filter-timepicker {
  width: 100%;
}
</style>
