<template>
  <edit-form-validator
    #default="{ errors }"
    :config="fieldDescriptor"
  >
    <component
      :is="fieldComponent"
      v-if="noWrapField(fieldDescriptor)"
      v-model="data"
      :config="fieldDescriptor"
      :errors="errors"
      :parentType="parentType"
      :disabled="isDisabled"
      :readOnly="isReadOnly"
      :focusOnMount="focusOnMount"
      :isInlineTable="isInlineTable"
      :size="elementSize"
      :formState="formState"
      @updateEntity="reemit('updateEntity', arguments)"
      @buttonClick="reemit('buttonClick', arguments)"
    />
    <edit-form-field
      v-else
      :field="fieldDescriptor"
      :hideTitle="hideTitle"
      :showTitleAppendix="showTitleAppendix"
    >
      <component
        :is="fieldComponent"
        v-model="data"
        v-bind="additionalProps"
        :config="fieldDescriptor"
        :errors="errors"
        :parentType="parentType"
        :disabled="isDisabled"
        :readOnly="isReadOnly"
        :focusOnMount="focusOnMount"
        :isInlineTable="isInlineTable"
        :firstModalDepthLevel="firstModalDepthLevel"
        :jsonCompare="jsonCompare"
        :size="elementSize"
        :formState="formState"
        @clickRef="clickRef"
      />
    </edit-form-field>
  </edit-form-validator>
</template>

<script>
import { isSystemField } from '@/helpers';
import { appSettings } from '@/AppSettings';
import BaseTable from '@/components/base/BaseTable';
import InputCodeEditor from '@/components/base/InputCodeEditor';
import InputColor from '@/components/base/InputColor';
import InputEnum from '@/components/base/InputEnum';
import InputNumber from '@/components/base/InputNumber';
import InputUploader from '@/components/base/InputUploader';
import InputAttachment from '@/components/base/InputAttachment';
import InputStringList from '@/components/base/InputStringList';
import InputUploaderGallery from '@/components/base/InputUploaderGallery';
// New meta form
import FormButton from '@/components/meta-form/fields/FormButton';

import EditFormField from './EditFormField';
import EditFormValidator from './EditFormValidator';
import RefAutocomplete from './fields/ref/RefAutocomplete';
import RefAutocomplete2 from './fields/ref/RefAutocomplete2';
import RefCheckboxes from './fields/ref/RefCheckboxes';
import RefCheckboxes2 from './fields/ref/RefCheckboxes2';
import EditFormEmbed from './fields/embed/EditFormEmbed';
import EditFormBoolean from './fields/EditFormBoolean';
import EditFormDate from './fields/EditFormDate';
import EditFormDropdown from './fields/EditFormDropdown';
import EditFormHidden from './fields/EditFormHidden';
import EditFormStaticText from './fields/EditFormStaticText';
import EditFormString from './fields/EditFormString';
import EditFormText from './fields/EditFormText';
import EditFormWysiwyg from './fields/EditFormWysiwyg';
import EditFormEnumModelField from './fields/EditFormEnumModelField';
import EditFormEnum from './fields/EditFormEnum';
import EditFormGpoint from './fields/EditFormGpoint';
import EditFormArray from './fields/EditFormArray';
import EditFormProcessFunctions from './fields/EditFormProcessFunctions';
import EditFormSqlEditor from './fields/EditFormSqlEditor';
import EditFormShellEditor from './fields/EditFormShellEditor';

export default {
  name: 'EditFormFieldWrap',
  components: {
    BaseTable,
    InputCodeEditor,
    InputColor,
    InputEnum,
    InputNumber,
    InputUploader,
    InputAttachment,
    InputStringList,
    InputUploaderGallery,
    EditFormField,
    EditFormValidator,
    RefAutocomplete,
    RefAutocomplete2,
    RefCheckboxes,
    RefCheckboxes2,
    EditFormEmbed,
    EditFormBoolean,
    EditFormDate,
    EditFormDropdown,
    EditFormHidden,
    EditFormStaticText,
    EditFormString,
    EditFormText,
    EditFormWysiwyg,
    EditFormEnumModelField,
    EditFormEnum,
    EditFormGpoint,
    EditFormArray,
    EditFormProcessFunctions,
    EditFormSqlEditor,
    EditFormShellEditor,
    // New meta form
    FormButton,
  },

  props: {
    fieldDescriptor: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
      default: null,
    },
    parentType: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String, Boolean, Object, Array],
      default: null,
    },
    size: {
      type: [String, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    isInlineTable: {
      type: Boolean,
      default: false,
    },
    focusOnMount: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    showTitleAppendix: {
      type: Boolean,
      default: true,
    },
    firstModalDepthLevel: {
      type: Boolean,
      default: false,
    },
    disabledRenderer: {
      type: String,
      default: 'global',
    },
    formState: {
      type: Object,
      default: null,
    },
    jsonCompare: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      defaultSize: 'default',
      jsonView: false,
    };
  },

  computed: {
    _disabledRenderer() {
      return this.disabledRenderer === 'global'
        ? appSettings.get('renderDisabledFields')
        : this.disabledRenderer;
    },
    isRefsClickable() {
      return appSettings.get('isRefsClickable');
    },

    elementSize() {
      const { name, renderer } = this.fieldDescriptor;

      if (renderer === 'table') {
        return 'small';
      }

      if (typeof this.size === 'string') {
        return this.size;
      }

      return this.size?.[name] || this.size?.default || this.defaultSize;
    },

    isHidden() {
      if (this._disabledRenderer === 'inherit') {
        return false;
      }

      if (this._disabledRenderer === 'hidden') {
        return this.disabled || this.fieldDescriptor.disabled;
      }

      return false;
    },

    isDisabled() {
      if (this._disabledRenderer === 'inherit') {
        return this.disabled;
      }

      if (this._disabledRenderer === 'disabled') {
        return this.disabled || this.fieldDescriptor.disabled;
      }

      return false;
    },

    isReadOnly() {
      if (this._disabledRenderer === 'inherit') {
        return this.readOnly;
      }

      if (this._disabledRenderer === 'readOnly') {
        return this.disabled || this.fieldDescriptor.disabled;
      }

      return (
        (!this.isDisabled && (this.readOnly || this.fieldDescriptor.readOnly)) ||
        isSystemField(this.fieldDescriptor)
      );
    },

    data: {
      get() {
        return this.secureValue(this.value, this.fieldDescriptor);
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    additionalProps() {
      const { renderer } = this.fieldDescriptor;

      if (renderer === 'int') {
        return { float: false };
      }

      if (renderer === 'json') {
        if (this.jsonCompare?.[this.fieldDescriptor.name])
          return { diffValue: this.jsonCompare[this.fieldDescriptor.name] };
      }

      return {};
    },

    fieldComponent() {
      const { refDisplay, config, name, renderer } = this.fieldDescriptor;

      if (this.isHidden) return 'edit-form-hidden';

      if (this.entity?.type === 'EnumEntityConfigField' && name === 'value') {
        return 'edit-form-enum-model-field';
      }

      if (name === 'component' && this.parentType === 'EntityConfigOperationFilter') {
        return 'input-code-editor';
      }

      if (name === 'fns' && this.parentType === 'JProcess') {
        return 'edit-form-process-functions';
      }

      // Поле внутри JProcess.fns
      if (name === 'fn' && this.parentType === 'ExecutionConfig') {
        return 'edit-form-process-functions';
      }

      if (['ref', 'refs'].includes(renderer) && !config?.urls && !config?.url) {
        if (!this.isInlineTable && refDisplay?.key === 'checkboxes') {
          return 'ref-checkboxes';
        }

        return 'ref-autocomplete';
      }

      if (['ref', 'refs', 'ref2', 'ref-like'].includes(renderer)) {
        if (!this.isInlineTable && refDisplay?.key === 'checkboxes') {
          return 'ref-checkboxes2';
        }

        return 'ref-autocomplete2';
      }

      // Meta Form renderers
      if (['button'].includes(renderer)) {
        return 'form-button';
      }

      if (renderer === 'long') {
        return 'input-number';
      }

      if (renderer === 'int') {
        return 'input-number';
      }
      // End of meta form renderers

      return (
        {
          'date-time': 'edit-form-date',
          time: 'edit-form-date',
          handbook: 'input-enum',
          image: 'input-uploader',
          attachment: 'input-attachment',
          gallery: 'input-uploader-gallery',
          json: 'input-code-editor',
          filterScript: 'input-code-editor',
          integer: 'input-number',
          color: 'input-color',
          uuid: 'edit-form-string',
          component: 'edit-form-embed',
          embed: 'edit-form-embed',
          secured: 'edit-form-string',
          sql: 'edit-form-sql-editor',
          shell: 'edit-form-shell-editor',
          'string-list': 'input-string-list',
          table: 'base-table',
        }[renderer] || `edit-form-${renderer}`
      );
    },

    // @TODO not used for a long time, disabled on backend too
    // fieldComponentOptions() {
    //   if (
    //     this.fieldDescriptor.field === 'script' &&
    //     this.parentType === 'EntityConfigOperationFilter'
    //   ) {
    //     return {
    //       language: 'javascript',
    //       suggestionOperation: this.fullModel.operation, // deepest in ref entites nested row,
    //       suggestionFields: this.parentModel.fields, // top level entity data, looped long way through components
    //     };
    //   }

    //   return {};
    // },
  },

  methods: {
    clickRef(key, e) {
      if (
        this.isRefsClickable &&
        e.composedPath()[0].tagName !== 'svg' &&
        e.composedPath()[0].tagName !== 'path'
      ) {
        let splittedPath = key.split(':');

        const { url } = this.fieldDescriptor.config;
        const splittedUrl = url.split('/');
        const handbookIndex = splittedUrl.findIndex((el) => el === 'handbooks');
        const handbookTitle = splittedUrl[handbookIndex + 1];
        if (handbookTitle) {
          splittedPath = ['JHandbook', handbookTitle];
        }

        window.open(`/page/${splittedPath[0]}/${splittedPath[1]}`, '_blank');
      }
    },
    secureValue(value, { renderer }) {
      if (renderer === 'json') {
        if (!value) value = '';
        else if (typeof value !== 'string') value = JSON.stringify(value);
      } else if (!value && renderer === 'gallery') {
        value = [];
      } else if (value === undefined) {
        value = renderer === 'boolean' ? false : null;
      }

      return value;
    },

    noWrapField({ renderer, hidden }) {
      return (
        this.isHidden ||
        this.isInlineTable ||
        hidden ||
        ['boolean', 'component', 'embed', 'button'].indexOf(renderer) > -1
      );
    },
  },
};
</script>
