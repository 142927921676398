<template>
  <a-layout-header class="app-header">
    <a-row
      type="flex"
      :justify="headerFlexJustify"
    >
      <a-col
        v-if="configErrors"
        class="app-header__warnings"
      >
        <a-tooltip :title="$t('header.settingsWarning')">
          <a-icon
            type="warning"
            @click="$emit('openAppSettings')"
          />
        </a-tooltip>
      </a-col>
      <a-col v-if="draftsCount">
        <router-link
          :to="{ name: 'Drafts' }"
          class="app-header__link"
        >
          {{ $t('header.drafts', { count: draftsCount }) }}
        </router-link>
      </a-col>
      <a-col>
        <a
          class="app-header__link"
          href="/graphql/gui"
          target="_blank"
        >
          {{ $t('header.API') }}
        </a>
        <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
        <a
          class="app-header__link app-header__mode-switch"
          href="#"
          @click.prevent="switchMode"
        >
          <a-icon
            :type="menuSwitchIconType"
            :style="themeIconStyle"
          />
        </a>
        <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
        <a
          class="app-header__link"
          href="#"
          @click="darkTheme = !darkTheme"
        >
          <a-icon
            type="bulb"
            :style="themeIconStyle"
          />
        </a>
        <locale-switcher
          v-if="showLocaleSwitcher"
          class="app-header__link"
        />
      </a-col>
      <a-col class="menu-toggle">
        <a-dropdown
          :trigger="['click']"
          placement="bottomRight"
        >
          <a
            class="ant-dropdown-link"
            @click.prevent
          >
            <a-icon type="user" /> {{ user.username }}
          </a>
          <a-menu
            slot="overlay"
            class="app-header__menu app-header__menu--user"
          >
            <template v-if="user.isConstructor">
              <a-menu-item
                key="settings"
                @click="$emit('openAppSettings')"
              >
                {{ $t('header.appSettings') }}
              </a-menu-item>
            </template>
            <a-menu-item
              key="logout"
              @click="$emit('logout')"
            >
              <a class="app-header__logout ant-dropdown-link">
                {{ $t('header.logout') }}
              </a>
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item
              key="version"
              class="app-header__version"
              @click.stop
            >
              <span>{{ feVersion }}</span>
              <span>{{ beVersion }}</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-col>
    </a-row>
  </a-layout-header>
</template>

<script>
import { appSettings } from '@/AppSettings';
import store from '@/store';
import LocaleService from '@/services/LocaleService';
import LocaleSwitcher from './LocaleSwitcher';

export default {
  name: 'TheHeader',
  components: {
    LocaleSwitcher,
  },
  data() {
    return {
      feVersion: store.state.appVersion,
    };
  },
  computed: {
    beVersion() {
      return store.state.meta.version;
    },
    draftsCount() {
      return Object.values(store.state.formsDrafts)
        .map((typeDrafts) => Object.keys(typeDrafts).length)
        .reduce((totalCount, typeCount) => totalCount + typeCount, 0);
    },
    user() {
      return store.state.user;
    },
    darkTheme: {
      get() {
        return store.state.darkTheme;
      },
      set(value) {
        return store.mutate.switchDarkTheme(value);
      },
    },
    themeIconStyle() {
      return { color: this.darkTheme ? '#e0e0e0' : '#262626' };
    },
    configErrors() {
      return (
        !appSettings.get('resizerUrl') ||
        !appSettings.get('uploaderUrl') ||
        !appSettings.get('userInfoUrl') ||
        !appSettings.get('mediaUrl')
      );
    },
    menuMode: {
      get() {
        return store.state.menuMode;
      },
      set(value) {
        return store.mutate.switchMenuMode(value);
      },
    },
    headerFlexJustify() {
      return this.menuMode === 'vertical' ? 'end' : 'start';
    },
    menuSwitchIconType() {
      return this.menuMode === 'vertical' ? 'border-left' : 'border-top';
    },
    showLocaleSwitcher: () => LocaleService.hasMultipleLanguages,
  },
  watch: {
    darkTheme: {
      immediate: true,
      handler(value) {
        document.querySelector('body').classList.toggle('theme--dark', value);
      },
    },
  },
  methods: {
    switchMode() {
      this.menuMode = this.menuMode === 'vertical' ? 'horizontal' : 'vertical';
    },
  },
};
</script>

<style lang="scss">
.app-header {
  &.ant-layout-header {
    height: 34px;
    padding: 10px 0 0;
    line-height: 34px;
    box-sizing: content-box;
  }

  &__menu.ant-dropdown-menu {
    margin-top: 5px;
  }

  &__menu--user.ant-dropdown-menu {
    min-width: 200px;
  }

  &__version {
    display: flex;
    justify-content: space-between;
    opacity: 0.66;
    line-height: 1;
    font-size: 90%;

    &:hover {
      background: transparent;
      cursor: text;
    }
  }

  &__warnings {
    .anticon {
      margin-right: 10px;
      font-size: 32px;
      cursor: pointer;
    }
    .ant-tooltip {
      width: 200px;
    }
    svg {
      color: #f70;
    }
  }
}

@media (min-width: $desktopBreakpoint) {
  .app-header {
    &__logout {
      z-index: 1;
      margin-right: -15px;
    }

    &__link {
      display: inline-block;
      margin-right: 30px;
    }

    &.ant-layout-header {
      padding-left: 40px;
    }
  }

  .menu--vertical {
    .app-header.ant-layout-header {
      padding-right: 35px;
    }
  }

  .menu--horizontal {
    .app-header.ant-layout-header {
      padding-right: 11px;
    }
  }
}
</style>
