<template>
  <div class="so-course">
    <div class="so-course__main">
      <so-course-preview :data="data.data" />
    </div>
    <div class="so-course__side">
      <so-course-actions
        v-for="(action, idx) in actions"
        :id="data.id"
        :key="idx"
        :action="action.action"
        :title="action.title"
        :fieldsMeta="getFieldsMeta(action.action)"
      />
      <so-course-history :id="data.id" />
    </div>
  </div>
</template>

<script>
import MetaService from '@/services/MetaService';
import store from '@/store';
import SoCoursePreview from './SoCoursePreview';
import SoCourseActions from './SoCourseActions';
import SoCourseHistory from './SoCourseHistory';

export default {
  name: 'SoCourse',

  components: {
    SoCoursePreview,
    SoCourseActions,
    SoCourseHistory,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    extActionsMeta: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      actionsMeta: {},
    };
  },
  computed: {
    actions() {
      return store.state.activeSidebarItem.customprops?.form?.actions || {};
    },
    customFormTitles() {
      return this.actions?.reduce((acc, form) => {
        let action = form;
        let title = form;
        if (typeof action !== 'string') {
          action = form.action;
          title = form.title || form.action;
        }

        acc[action] = title;
        return acc;
      }, {});
    },
  },
  watch: {
    async extActionsMeta() {
      this.updateActionsMeta();
    },
    async data() {
      this.updateActionsMeta();
    },
  },
  created() {
    this.updateActionsMeta();
  },
  methods: {
    getFieldsMeta(action) {
      return this.actionsMeta[action]?.fieldsList || {};
    },
    async updateActionsMeta() {
      this.actionsMeta =
        this.extActionsMeta || (await MetaService.getEntityActions('SoCourse', this.actions));
    },
  },
};
</script>

<style lang="scss">
.so-course {
  background-color: #f9f9f9;
  width: 100%;
  height: 600px;
  overflow-y: hidden;
  display: flex;

  &__main {
    padding: 30px 25px 50px;
    flex: 0 0 70%;
    width: 70%;
    height: 100%;
    @include scrollbars();
    overflow-y: scroll;
  }

  &__side {
    padding: 30px 25px 50px;
    flex: 0 0 30%;
    width: 30%;
    height: 100%;
    @include scrollbars();
    overflow-y: scroll;
  }
}
</style>
