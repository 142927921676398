import gql from 'graphql-tag';

export default {
  query: gql`
    query Suggests($operation: OperationType, $fields: [Json]) {
      scriptSuggestions(operation: $operation, fields: $fields)
    }
  `,
  update: ({ scriptSuggestions }) => scriptSuggestions,
};
