<template>
  <validation-provider
    :rules="fieldRules"
    #default="{ errors }"
    :name="config.field"
  >
    <slot :errors="errors"></slot>
  </validation-provider>
</template>

<script>
export default {
  name: 'EditFormValidator',
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    fieldRules() {
      const restrictions = this.config.restrictions;

      if (restrictions === undefined) return;

      let rules = '';
      restrictions.forEach((restriction) => {
        const type = restriction.type;
        const value = restriction.props.value;

        if (typeof value === 'boolean' && value) {
          rules += `${type}|`;
        } else {
          rules += `${type}:${value}|`;
        }
      });

      return rules;
    },
  },
};
</script>
