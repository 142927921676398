<template>
  <div
    :class="[
      'kanban-card',
      { firstFieldBold, canReadModel: canReadModel, fullView: fullView, unselectable: !fullView },
    ]"
    :style="cardStyle"
    @click="editEntity"
  >
    <template v-for="field of fields">
      <h4
        v-if="showTitle(field)"
        :key="`${field.name}-title`"
        class="kanban-card__field-title"
      >
        {{ field.label || field.name }}
      </h4>

      <template v-if="fullView && isComponentField(field) && data[field.name]">
        <edit-form-field-wrap
          v-if="data[field.name].length"
          :key="field.name"
          v-model="data[field.name]"
          :fieldDescriptor="field"
          :parentType="parentType"
          :disabled="disabled"
          :readOnly="isReadOnly"
          size="small"
        />
      </template>

      <base-tags
        v-else-if="isRefsField(field) && data[field.name] && data[field.name].length"
        :key="field.name"
        :value="getRefDisplayValue(data[field.name], field)"
        :ellipsis="!fullView"
      />

      <base-tags
        v-else-if="isArrayField(field) && getArrayDisplayValue(data[field.name]).length"
        :key="field.name"
        :value="getArrayDisplayValue(data[field.name]).length"
        :ellipsis="!fullView"
      />

      <div
        v-else-if="isComponentField(field)"
        :key="field.name"
        class="kanban-card__embed"
      >
        {{ field.name }} — {{ (data[field.name] || []).length }}
      </div>

      <base-tags
        v-else-if="isRefField(field) && data[field.name]"
        :key="field.name"
        :value="getRefDisplayValue(data[field.name], field)"
      />

      <p
        v-else-if="isDateTimeField(field) && data[field.name]"
        :key="field.name"
      >
        {{ formatDateTime(data[field.name]) }}
      </p>

      <p
        v-else-if="isDateField(field) && data[field.name]"
        :key="field.name"
      >
        {{ formatDate(data[field.name]) }}
      </p>

      <p
        v-else-if="isTimeField(field) && data[field.name]"
        :key="field.name"
      >
        {{ formatTime(data[field.name]) }}
      </p>

      <p
        v-else-if="isColorField(field)"
        :key="field.name"
      >
        <template v-if="!showTitles">{{ field.name }}</template>
        <color-plate :color="data[field.name]" />
      </p>

      <template v-else-if="isImageField(field) && data[field.name]">
        <base-image
          :key="field.name"
          v-bind="imageParams(field)"
          class="image"
          :path="data[field.name]"
          @click.native="showPreview(data[field.name])"
        />
      </template>

      <template v-else-if="isGalleryField(field) && data[field.name] && data[field.name].length">
        <div
          :key="field.name"
          :class="['kanban-card__gallery', { ellipsis: !fullView }]"
        >
          <base-image
            v-for="path of data[field.name]"
            :key="path"
            v-bind="imageParams(field)"
            class="image"
            :path="path"
            @click.native="showPreview(path)"
          />
        </div>
      </template>

      <p
        v-else-if="isBooleanField(field)"
        :key="field.name"
      >
        <span
          :class="['checkbox-wrap', { readOnly: !canSwitchCheckboxes }]"
          @click.stop
        >
          <a-checkbox
            :checked="data[field.name]"
            :disabled="disabled || !canSwitchCheckboxes"
            @change="checkboxChanged($event, data, field.name)"
          >
            {{ field.label }}
          </a-checkbox>
        </span>
      </p>

      <p
        v-else-if="isWysiwygField(field) && data[field.name]"
        :key="field.name"
        class="tui-editor-contents"
        v-html="getWysiwygTextForDisplay(data[field.name])"
      ></p>

      <div
        v-else-if="isJsonTextField(field) && data[field.name]"
        :key="field.name"
        class="kanban-card__json-wrapper"
      >
        <!-- eslint-disable-next-line vuejs-accessibility/mouse-events-have-key-events -->
        <pre
          v-html="getJsonValue(data[field.name])"
          @mouseup="jsonMouseUp"
          @mousemove="jsonMouseMove"
          @mouseleave="jsonMouseLeave"
          @click.stop
        ></pre>
      </div>

      <p
        v-else-if="isLongTextField(field) && data[field.name]"
        :key="field.name"
        :class="{ 'white-pre': fullView }"
        v-html="data[field.name]"
      ></p>

      <p
        v-else-if="data[field.name]"
        :key="field.name"
        class="kanban-card__string"
      >
        {{ data[field.name] }}
      </p>
    </template>

    <a-modal
      :visible="!!activePreview"
      :footer="null"
      @cancel="closePreview"
    >
      <img
        style="width: 100%"
        :src="activePreview"
        alt=""
      />
    </a-modal>
  </div>
</template>

<script>
import { imageUrl, formatDateTime, formatDate, formatTime, decodeHtml, Colors } from '@/helpers';
import BaseImage from '@/components/base/BaseImage.vue';
import BaseTags from '@/components/base/BaseTags.vue';
import ColorPlate from '@/components/base/ColorPlate.vue';
import EditFormFieldWrap from '@/components/edit-form/EditFormFieldWrap.vue';

export default {
  name: 'KanbanCard',
  components: {
    BaseImage,
    BaseTags,
    ColorPlate,
    EditFormFieldWrap,
  },

  props: {
    fieldsToDisplay: {
      type: Array,
      required: true,
    },
    parentType: {
      type: String,
      default: '',
    },
    card: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    canReadModel: {
      type: Boolean,
      default: false,
    },
    canUpdateModel: {
      type: Boolean,
      default: false,
    },
    showTitles: {
      type: Boolean,
      default: false,
    },
    firstFieldBold: {
      type: Boolean,
      default: true,
    },
    fullView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activePreview: null,
      dragging: false,
    };
  },

  computed: {
    data() {
      return this.card.data;
    },
    cardStyle() {
      let style = '';
      if (this.fields && !this.fullView) {
        const colorField = this.fields.find((field) => field.renderer === 'color' && !field.hidden);
        const bgColor = colorField && this.card[colorField.field];
        if (bgColor) {
          style = `
            background-color: ${bgColor};
            color: ${Colors.contrast(bgColor)};
          `;
        }
      }

      return style;
    },
    fields() {
      const fields = this.fieldsToDisplay;

      return (
        fields &&
        fields.map((field) => ({
          ...field,
          hidden: field.hidden || ['form', 'color'].includes(field.renderer),
        }))
      );
    },
    isReadOnly() {
      return this.readOnly || this.fullView;
    },
    canSwitchCheckboxes() {
      return !this.isReadOnly && this.canUpdateModel;
    },
  },

  methods: {
    decodeHtml,
    formatDateTime,
    formatDate,
    formatTime,

    showTitle(field) {
      const value = this.data[field.name];
      return this.showTitles && value && (!Array.isArray(value) || value.length);
    },

    isDateTimeField(field) {
      return field.renderer === 'date-time';
    },

    isDateField(field) {
      return field.renderer === 'date';
    },

    isTimeField(field) {
      return field.renderer === 'time';
    },

    isRefsField(field) {
      return ['refs', 'enum'].includes(field.renderer);
    },

    isArrayField(field) {
      return field.renderer === 'array';
    },

    isRefField(field) {
      return ['ref-like', 'ref', 'ref2'].includes(field.renderer);
    },

    isImageField(field) {
      return field.renderer === 'image';
    },

    isGalleryField(field) {
      return field.renderer === 'gallery';
    },

    isBooleanField(field) {
      return field.renderer === 'boolean';
    },

    isColorField(field) {
      return field.renderer === 'color';
    },

    isWysiwygField(field) {
      return field.renderer === 'wysiwyg';
    },

    isComponentField(field) {
      return ['component', 'embed'].includes(field.renderer);
    },

    isJsonTextField(field) {
      return field.renderer === 'json';
    },

    isLongTextField(field) {
      return field.renderer === 'text';
    },

    getJsonValue(value) {
      return value || '';
    },

    imageParams(field) {
      return {
        width: (this.fullView && field.width) || 100,
        height: (this.fullView && field.height) || 100,
      };
    },

    getWysiwygTextForDisplay(src) {
      return this.fullView ? src : decodeHtml(src);
    },

    getRefDisplayValue(value, { config }) {
      let display = value;
      const type = config?.url?.replace(/.*?\/handbooks\/(.*?)\/.*/, '$1');
      if (type) {
        const transKey = `handbooks.${type}.${value}`;
        display = this.$t(transKey);
        if (display === transKey) display = value;
      }

      return display;
    },

    editEntity() {
      if (this.canReadModel && !this.dragging) {
        this.$emit('editEntity', this.card.id);
      }
    },

    checkboxChanged({ target: { checked } }, entity, field) {
      entity[field] = checked;
      this.$emit('updateEntity', this.card.id, entity, { [field]: checked });
    },

    showPreview(path) {
      this.activePreview = imageUrl(path);
    },

    closePreview() {
      this.activePreview = null;
    },

    getArrayDisplayValue(value) {
      let displayValue = null;
      try {
        displayValue = value.slice(1, -1).split(',');
      } finally {
        displayValue = displayValue || [];
      }

      return displayValue;
    },

    jsonMouseLeave() {
      this.dragging = false;
    },

    jsonMouseMove({ which }) {
      if (which === 1) {
        this.dragging = true;
      }
    },

    jsonMouseUp({ which }) {
      if (!this.dragging && which === 1) {
        this.editEntity();
      }

      setTimeout(() => {
        this.dragging = false;
      }, 50);
    },
  },
};
</script>

<style lang="scss">
.kanban-card {
  position: relative;
  color: $darkTextColor;
  padding: 20px 16px 6px;
  background-color: #f9f9f9ff;

  &__json-wrapper {
    @include scrollbars();
    overflow-x: auto;
    padding-bottom: 5px;
    margin-bottom: 14px;
  }

  &__string {
    line-break: anywhere;
  }

  pre {
    overflow-x: visible;
    margin-bottom: 0;
    width: fit-content;
    font-size: 80%;
    -webkit-user-select: text;
    user-select: text;
    cursor: auto;
  }

  &.canReadModel {
    cursor: pointer;
  }

  &.fullView {
    padding-left: 0;
  }

  &.firstFieldBold > *:nth-child(1) {
    font-weight: 700;

    .renderer--text,
    .renderer--wysiwyg,
    .renderer--json,
    .renderer--ref,
    .renderer--refs,
    .renderer--ref2,
    .renderer--ref-like,
    .renderer--enum,
    .renderer--array,
    .renderer--embed {
      font-weight: 400;
    }
  }

  .tui-editor-contents {
    font-size: inherit;
    margin-bottom: 1em;
  }

  .entity-field {
    margin-top: 0;
    margin-bottom: 20px;
  }

  &__field-title {
    margin-bottom: 4px;
  }

  & + & {
    margin-top: 16px;
  }

  p.white-pre {
    white-space: pre-wrap;
  }

  & > .tags,
  & > .image,
  &__gallery {
    margin-bottom: 14px;
    max-width: 100%;
  }

  .tags.clickable > span {
    cursor: pointer;
    &:hover {
      background-color: #e0e0e0;
    }
  }
  .tags:not(.clickable) > span {
    &:hover {
      opacity: 1;
    }
    &:after {
      // disable ant click animation
      display: none;
    }
  }

  &__gallery {
    .image {
      max-width: 100%;
      margin-right: 5px;
    }
  }

  .image:hover {
    opacity: 0.65;
    cursor: pointer;
  }

  .ant-checkbox-wrapper {
    color: inherit;
  }

  .entity-field__title {
    display: none;
  }

  .color-plate {
    max-width: 120px;
  }

  &__embed {
    margin-bottom: 10px;
  }
}
</style>
