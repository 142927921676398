import { bus } from '@/helpers/bus';

class BaseService {
  emitError(message, description, type = 'error') {
    bus.$emit('error', {
      type,
      message,
      description,
    });
  }
}

export default BaseService;
