<template>
  <input
    type="hidden"
    :value="value"
  />
</template>

<script>
export default {
  name: 'EditFormHidden',
  props: {
    value: {
      type: String,
      default: null,
    },
  },
};
</script>
