const OPS_DEFAULT = {
  ILIKE: { key: 'ILIKE' },
  EQUALS: { key: 'EQUALS' },
  GT: { key: 'GT' },
  LT: { key: 'LT' },
  NONEMPTY: { key: 'EQUALS' },
};

const OPS_PUBLISHABLE = {
  ILIKE: { key: '_ilike' },
  EQUALS: { key: '_eq' },
  GT: { key: '_gt' },
  LT: { key: '_lt' },
  NONEMPTY: { key: '_eq' },
};

function renderersDictMaker(ops) {
  return [
    {
      renderer: 'string',
      applicableFormRenderers: ['string', 'text', 'wysiwyg', 'json'],
      operators: [ops.ILIKE],
    },
    {
      renderer: 'checkbox',
      applicableFormRenderers: ['boolean'],
      operators: [ops.EQUALS],
    },
    {
      renderer: 'checkbox',
      applicableFormRenderers: ['image', 'gallery', 'color', 'component', 'attachment', 'embed'],
      operators: [ops.NONEMPTY],
    },
    {
      renderer: 'datetimepicker',
      applicableFormRenderers: ['date', 'date-time', 'time'],
      operators: [ops.EQUALS, ops.GT, ops.LT],
    },
    {
      renderer: 'enum',
      applicableFormRenderers: ['handbook'],
      operators: [ops.EQUALS],
    },
    {
      renderer: 'number',
      applicableFormRenderers: ['integer'],
      operators: [ops.EQUALS, ops.GT, ops.LT],
      valueProcessor: (v) => Number(v),
    },
    {
      renderer: 'autocomplete',
      applicableFormRenderers: ['ref', 'refs'],
      operators: [ops.EQUALS],
    },
    {
      renderer: 'autocomplete2',
      applicableFormRenderers: ['ref2'],
      operators: [ops.ILIKE, ops.EQUALS],
    },
    {
      renderer: 'autocomplete2',
      applicableFormRenderers: ['ref-like'],
      operators: [ops.ILIKE],
    },
  ];
}

const renderersDict = renderersDictMaker(OPS_DEFAULT);
const renderersDictPublishable = renderersDictMaker(OPS_PUBLISHABLE);

function getFilterRendererByFormRenderer(fieldRenderer, options) {
  const renderers = options?.publishable ? renderersDictPublishable : renderersDict;
  return renderers.find(({ applicableFormRenderers }) =>
    applicableFormRenderers.includes(fieldRenderer),
  );
}

export { getFilterRendererByFormRenderer };
