<template>
  <div class="so-course-actions">
    <h2 class="so-course-actions__h2">{{ title }}</h2>
    <a-textarea
      v-if="actionCustomprops.comment"
      v-model="comment"
      class="form-textarea so-course-actions__text-area"
      style="height: 50px"
      :placeholder="$t('base.placeholder.enterField', { name: 'комментарий' })"
    />
    <a-button
      type="primary"
      class="so-course-actions__button"
      @click="clickAction"
    >
      {{ $t('SOCourse.actionButtonText') }}
    </a-button>
    <hr />
  </div>
</template>

<script>
import store from '@/store';
import customMutation from '@/queries/mutations/customMutation';
import { bus, preparePayload } from '@/helpers';

export default {
  name: 'SoCourseActions',
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    fieldsMeta: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      comment: '',
      statusId: null,
    };
  },
  computed: {
    actionCustomprops() {
      return (
        store.state.activeSidebarItem.customprops?.form?.actions.filter(
          (el) => el.action === this.action && el.title === this.title,
        )[0] || null
      );
    },
  },
  methods: {
    clickAction() {
      this.isLoading = true;
      this.statusId = Number(this.actionCustomprops?.statusId) || null;
      this.$apollo
        .mutate({
          mutation: customMutation(this.action, 'SOCourse', this.fieldsMeta),
          variables: preparePayload({ ...this.$data, ...this.$props }, this.fieldsMeta),
        })
        .then(() => {
          store.mutate.deleteFormDraft(this.action, this.id);
          this.isLoading = false;
          this.statusId = null;
          bus.$emit('refetchTable');
          this.$notification.success({
            message: this.$t('customForm.saveSuccess'),
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.statusId = null;
          this.$notification.error({
            message: this.$t('customForm.saveError'),
            description: error.message,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.so-course-actions {
  &__h2 {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  &__button {
    margin: 8px 0;
    background: #1890ff;
    border-radius: 2px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
  }
  hr {
    background-color: #ededed;
    border: 0 none;
    color: #ededed;
    height: 1px;
  }
}
</style>
