<template>
  <a-empty
    v-if="noData"
    :image="emptyImage"
  />
  <div
    v-else
    class="tiles"
  >
    <kanban-card
      v-for="card in cards"
      :key="card.id"
      class="tiles__card"
      :fieldsToDisplay="fieldsToDisplay"
      :card="card"
      :disabled="disabled"
      :canReadModel="canReadModel"
      @editEntity="editEntity"
      @updateEntity="updateEntity"
    />
  </div>
</template>

<script>
import KanbanCardContainer from '../kanban/KanbanCardContainer.mixin.js';

export default {
  name: 'TheTiles',
  mixins: [KanbanCardContainer],
};
</script>

<style lang="scss">
@media (min-width: $tabletBreakpoint) {
  .tiles {
    display: flex;
    flex-wrap: wrap;
    margin: -16px 0 0 -16px;

    &__card {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: calc(100% / 2 - 16px);
      max-width: 330px;
      margin: 16px 0 0 16px;
    }
  }
}
</style>
