<template>
  <div class="type-config">
    <a-popover
      v-model="showConfig"
      trigger="click"
      placement="bottomLeft"
    >
      <a-button
        class="type-config__button"
        :size="controlsSize"
      >
        <a-icon type="number" />
      </a-button>
      <template #content>
        <div class="type-config__popover">
          <div
            v-for="option of options"
            :key="option.value"
            class="type-config__option"
            :class="{ 'type-config__option--active': option.value === currentValue }"
            @click="setDisplayType(option.value)"
          >
            {{ optionLabel(option) }}
          </div>
        </div>
      </template>
    </a-popover>
  </div>
</template>

<script>
import configFormMixin from './configFormMixin';

export default {
  name: 'TypeConfig',

  mixins: [configFormMixin],

  props: {
    controlsSize: {
      type: String,
      default: 'large',
    },
  },

  data() {
    return {
      showConfig: false,
    };
  },

  computed: {
    displayType() {
      return this.value.displayType;
    },
    currentValue: {
      get() {
        return this.displayType.value;
      },
      async set(newValue) {
        this.value.displayType.options = await this.getConditionalFormDefaults(
          'displayType',
          newValue,
        );
        this.value.displayType.value = newValue;
        this.configUpdated();
      },
    },
    options() {
      return this.configLayout.displayType.options(this.entityFields);
    },
  },

  methods: {
    setDisplayType(value) {
      this.currentValue = value;
      this.showConfig = false;
    },
    optionLabel(option) {
      return this.$t(`page.displayType.${option.value}`);
    },
  },
};
</script>

<style lang="scss">
.type-config {
  height: 100%;
  &__popover {
    margin: -5px 0;
  }
  &__option {
    margin: 0 -16px;
    padding: 6px 16px;
    white-space: nowrap;
    cursor: pointer;
    &--active,
    &:hover {
      background: #e6f7ff;
    }
  }
}
</style>
