<template>
  <div
    ref="container"
    class="table-cell"
    @click="editCell"
  >
    <template
      v-if="
        column.renderer === 'component' ||
        column.renderer === 'embed' ||
        column.renderer === 'boolean' ||
        column.renderer === 'uuid' ||
        (column.renderer === 'integer' && value != null) ||
        (value && (value.length === undefined || value.length > 0))
      "
    >
      <div
        v-if="column.renderer === 'uuid'"
        class="table__text table__text--short ellipsis"
      >
        {{ value }}
      </div>

      <a
        v-else-if="column.renderer === 'param-link' || column.renderer === 'link'"
        class="table__text"
        :href="getLinkValue(column, value)"
        :target="getLinkTarget(column, value)"
        @click.stop="linkClick($event, column)"
      >
        {{ getLinkTitle(column, value) }}
      </a>

      <div
        v-else-if="column.renderer === 'integer'"
        class="table__text ellipsis"
      >
        {{ value }}
      </div>

      <div v-else-if="column.renderer === 'date-time'">
        {{ formatDateTime(value) }}
      </div>

      <div v-else-if="column.renderer === 'date'">
        {{ formatDate(value) }}
      </div>

      <div v-else-if="column.renderer === 'time'">
        {{ formatTime(value) }}
      </div>

      <div v-else-if="['ref2', 'ref', 'refs', 'ref-like', 'enum'].includes(column.renderer)">
        <base-tags
          :value="getRefDisplayValue(value, column)"
          @clickTag="editCell"
        />
      </div>

      <div v-else-if="column.renderer === 'array'">
        <base-tags
          :value="getArrayDisplayValue(value)"
          @clickTag="editCell"
        />
      </div>

      <div
        v-else-if="column.renderer === 'boolean'"
        :class="['checkbox-wrap', { readOnly: !canUpdateModel }]"
      >
        <a-checkbox
          :checked="value"
          :disabled="!canUpdateModel || column.disabled"
          @change="$emit('checkboxChanged', $event, entity, column.name, value)"
          @click.stop
        />
      </div>

      <div
        v-else-if="column.renderer === 'actions'"
        class="table__actions"
      >
        <slot
          name="actions"
          :entity="entity"
        ></slot>
      </div>

      <div
        v-else-if="column.renderer === 'color'"
        class="table__color-field"
      >
        <color-plate :color="value" />
      </div>

      <div
        v-else-if="column.renderer === 'image' && value"
        class="table__gallery"
      >
        <base-image
          :width="40"
          :height="40"
          :path="value"
        />
      </div>

      <div
        v-else-if="column.renderer === 'gallery'"
        class="table__gallery ellipsis"
      >
        <base-image
          v-for="path of value"
          :key="path"
          :width="40"
          :height="40"
          :path="path"
        />
      </div>

      <div
        v-else-if="column.renderer === 'wysiwyg'"
        class="table__text ellipsis"
      >
        {{ decodeHtml(value) }}
      </div>

      <div
        v-else-if="column.renderer === 'component' || column.renderer === 'embed'"
        class="table__text ellipsis"
      >
        <template v-if="exp">
          <template v-if="componentValueLength">
            <a-icon
              class="table-cell__expand-icon"
              :type="active ? 'down' : 'right'"
              @click="$emit('toggleExpand', column)"
            />
            <a
              class="table-cell__component-size"
              href="#"
              @click.prevent="$emit('toggleExpand', column)"
              >{{ componentValueLength }}
            </a>
          </template>
          <template v-if="canUpdateModel">
            <edit-form-embed-create-button
              v-if="arrayConfig.multiple || !componentValueLength"
              :config="arrayConfig"
              :formConfigs="formConfigs"
              @click="reemit('createItem', arguments)"
            >
              <a-button
                class="table-cell__array-create"
                type="link"
              >
                {{ $t('embed.add') }}
              </a-button>
            </edit-form-embed-create-button>
            <a-popconfirm
              v-else
              :okText="$t('embed.deletionConfirm')"
              :cancelText="$t('embed.deletionCancel')"
              :title="$t('embed.deletionTitle')"
              :getPopupContainer="getPopupContainer"
              @confirm="$emit('deleteItem', column)"
            >
              <a-button
                class="table-cell__array-create"
                type="link"
              >
                {{ $t('embed.delete') }}
              </a-button>
            </a-popconfirm>
          </template>
        </template>
        <template v-else>
          {{ componentValueLength }}
        </template>
      </div>

      <div
        v-else
        class="table__text ellipsis"
      >
        {{ value }}
      </div>
    </template>
    <div
      v-else
      class="table-cell__placeholder"
    >
      {{ placeholder }}
    </div>
  </div>
</template>

<script>
import { formatDateTime, formatDate, formatTime, decodeHtml } from '@/helpers';
import BaseImage from '@/components/base/BaseImage.vue';
import BaseTags from '@/components/base/BaseTags.vue';
import ColorPlate from '@/components/base/ColorPlate.vue';
import EditFormEmbedCreateButton from '../../edit-form/fields/embed/EditFormEmbedCreateButton';

export default {
  name: 'TableCell',
  components: {
    BaseImage,
    BaseTags,
    ColorPlate,
    EditFormEmbedCreateButton,
  },
  props: {
    arrayConfig: {
      type: Object,
      default: null,
    },
    formConfigs: {
      type: Object,
      default: null,
    },
    column: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, Array, String, Number, Boolean, Date],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    entity: {
      type: Object,
      required: true,
    },
    canUpdateModel: {
      type: Boolean,
      required: true,
    },
    exp: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentValueLength() {
      return (this.value || []).length;
    },
  },
  methods: {
    decodeHtml,
    formatDateTime,
    formatDate,
    formatTime,

    editCell() {
      this.$emit('click', this.$refs.container);
    },

    getArrayDisplayValue(value) {
      return value || [];
    },

    getRefDisplayValue(value, { config }) {
      let display = value;
      if (config?.url?.indexOf('/handbooks/') > -1) {
        const type = config?.url?.replace(/.*?\/handbooks\/(.*?)\/.*/, '$1');
        const transKey = `handbooks.${type}.${value}`;
        display = this.$t(transKey);
        if (display === transKey) display = value;
      } else if (display) {
        // Refs in Publishable
        const testObj = Array.isArray(display) ? display[0] : display;

        if (testObj && testObj.__typename) {
          let stringKey =
            Object.entries(testObj).find(
              (key, val) => key !== '__typename' && typeof val === 'string',
            ) ||
            Object.entries(testObj).find(
              (key, val) => key !== '__typename' && typeof val === 'number',
            );

          stringKey = stringKey && stringKey[0];
          if (stringKey) {
            display = Array.isArray(display)
              ? display.map((d) => d[stringKey])
              : display[stringKey];

            display = String(display);
          }
        }
      }

      return display;
    },

    getPopupContainer() {
      return document.querySelector('.entity-modal, .entity-page');
    },

    getLinkValue(column, value) {
      if (typeof column.value === 'function') {
        return column.value(column.valueTemplate, value.data);
      }

      return value;
    },

    getLinkTitle(column, value) {
      return column.valueTitle || this.getLinkValue(column, value);
    },

    getLinkTarget(column) {
      return (column.valueTemplate || column.value)?.match?.(/^(\/\/|[a-z]+?:\/\/)/) ||
        column.openInNewTab
        ? '_blank'
        : '_self';
    },

    linkClick($event) {
      if (this.column.openInModal) {
        $event.preventDefault();
        this.$emit('openInModal', { path: $event.target.pathname + $event.target.search });
      } else if ($event.target.target !== '_blank') {
        $event.preventDefault();
        this.$router.push({ path: $event.target.pathname + $event.target.search });
      }
    },
  },
};
</script>

<style lang="scss">
.table-cell {
  position: relative;
  z-index: 1;
  max-width: 100%;

  width: 100%;
  min-height: 21px;

  &__placeholder {
    color: #ccc;
  }
  &__array-create.ant-btn {
    height: 22px;
    padding: 0;
  }
  &__component-size {
    margin-right: 10px;
  }
  &__expand-icon {
    margin-right: 10px;
  }
}
.theme--dark {
  .table-cell {
    &__placeholder {
      color: #777;
    }
  }
}
</style>
