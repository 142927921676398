<template>
  <a-date-picker
    v-model="datetime"
    class="filter-datetimepicker"
    :format="format"
    :showTime="showTime"
    locale="ru-RU"
    :placeholder="$t('base.placeholder.selectDate')"
    size="small"
  />
</template>

<script>
import moment from 'moment';

export default {
  name: 'FilterElementDatetimepicker',
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  computed: {
    datetime: {
      get() {
        return this.value && moment(this.value);
      },
      set(datetime) {
        this.value = datetime.startOf(this.roundingPeriod).valueOf();
        this.$emit('input', this.value);
      },
    },
    roundingPeriod() {
      return {
        'date-time': 'second',
        date: 'day',
        time: 'second',
      }[this.config.formRenderer];
    },
    format() {
      return {
        'date-time': 'DD.MM.YYYY HH:mm:ss',
        date: 'DD.MM.YYYY',
        time: 'HH:mm:ss',
      }[this.config.formRenderer];
    },
    showTime() {
      return Boolean(this.format.match(/[Hhkms]/));
    },
  },
};
</script>

<style lang="scss">
.filter-datetimepicker {
  width: 100%;
  .filter-timepicker {
    margin-bottom: 10px;
    display: block;
  }
}
</style>
