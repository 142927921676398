<template>
  <div class="filter">
    <a-row
      class="filter__top"
      type="flex"
      :gutter="10"
    >
      <a-col>
        <type-config
          v-model="config"
          :controlsSize="controlsSize"
          :configLayout="configLayout"
          :entityFields="fieldsMeta"
          @configUpdated="configUpdated"
        />
      </a-col>
      <a-col v-if="isKanbanDisplayType">
        <kanban-config
          v-model="config.displayType.options"
          :controlsSize="controlsSize"
          :configLayout="configLayout"
          :entityType="entityType"
          :entityFields="fieldsMeta"
          icon="project"
          @configUpdated="configUpdated"
        />
      </a-col>
      <a-col v-if="isCalendarDisplayType">
        <calendar-config
          v-model="config.displayType.options"
          :entityType="entityType"
          :controlsSize="controlsSize"
          :configLayout="configLayout"
          :entityFields="fieldsMeta"
          @configUpdated="configUpdated"
        />
      </a-col>
      <a-col
        v-if="searchField"
        :xs="24"
        :sm="12"
        :lg="9"
        :xl="10"
      >
        <search-config
          v-model="config.searchField"
          :search="search"
          :controlsSize="controlsSize"
          :configLayout="configLayout"
          :entityFields="fieldsMeta"
          :entityType="entityType"
          formConfig="formConfig"
          @configUpdated="configUpdated"
          @applyParams="applyParams(false)"
          @setSearchValue="setSearchValue"
        />
      </a-col>
      <a-col v-if="config.displayType.value !== 'gantt'">
        <fields-config
          v-model="config.displayFields"
          :controlsSize="controlsSize"
          :configLayout="configLayout"
          :entityType="entityType"
          :entityFields="fieldsMeta"
          :formConfig="formConfig"
          :icon="config.displayType.value === 'table' ? 'project' : 'profile'"
          @configUpdated="configUpdated"
        >
          <template #header>
            <h3>{{ $t('filter.displayFields') }}</h3>
          </template>
        </fields-config>
      </a-col>
      <a-col>
        <a-popover
          v-if="!hideFilters"
          v-model="showFilters"
          trigger="click"
          placement="bottom"
        >
          <a-button
            v-if="hasFilters"
            class="filter__button"
            :type="filterButtonType"
            :size="controlsSize"
          >
            <a-icon type="filter" />
          </a-button>
          <div
            slot="content"
            class="filter__popover"
          >
            <div
              v-if="openedFilter === null"
              class="filter__options"
            >
              <h3>{{ $t('filter.filter') }}</h3>
              <div
                v-for="(element, index) in filterConfig"
                :key="element.name"
                class="filter__titles"
                @click="changeFilter(index)"
              >
                <span>
                  {{ element.label }}
                </span>
                <a-icon type="right" />
              </div>
            </div>
            <div
              v-else
              class="filter__field"
            >
              <div
                class="filter__back"
                @click="changeFilter(null)"
              >
                <a-icon type="left" />
                {{ $t('filter.back') }}
              </div>
              <filter-element
                v-for="(element, index) in filterConfig"
                v-show="index === openedFilter"
                :key="element.name"
                :title="element.label"
                :element="element"
                :value="tempParams[element.name].operator"
                @input="setParamOperator(element, $event)"
              >
                <component
                  :is="getElementComponent(element)"
                  :config="element"
                  :value="tempParams[element.name].value"
                  size="small"
                  @input="setParamValue(element, $event)"
                />
              </filter-element>
              <a-button
                type="primary"
                size="small"
                @click.prevent.stop="applyParams(false)"
              >
                {{ $t('filter.apply') }}
              </a-button>
            </div>
          </div>
        </a-popover>
        <a-button
          v-if="canCreateModel"
          class="filter__button"
          type="primary"
          :size="controlsSize"
          @click="$emit('createEntity')"
        >
          {{ $t('filter.createEntity') }}
        </a-button>
      </a-col>
      <page-count-config
        v-model="config.pageSize"
        @input="$emit('pageSizeUpdated')"
      />
      <a-col class="pager">
        <slot name="pagination"></slot>
      </a-col>
      <a-col
        v-if="$slots.last"
        class="filter__top-last-slot"
      >
        <slot name="last"></slot>
      </a-col>
    </a-row>
    <a-row
      v-if="showCategoryTree"
      class="filter__category-tree"
    >
      <category-tree-config
        v-model="config"
        :controlsSize="controlsSize"
        :configLayout="configLayout"
        :entityFields="fieldsMeta"
        :categorySelected="params?.category?.value ?? []"
        @configUpdated="configUpdated"
        @selectCategory="selectCategory"
      />
    </a-row>
    <a-row
      class="filter__tags"
      type="flex"
    >
      <a-tag
        v-for="filter in activeFilters"
        :key="filter.name"
        color="blue"
      >
        <a-icon
          type="close"
          @click="removeFilter(filter)"
        />
        <span class="filter__tag-text">{{ filter.filterSlug }}</span>
      </a-tag>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment';
import store from '@/store';
import { getFieldLabel } from '@/helpers';
import FormConfigService from '@/services/FormConfigService';
import TypeConfig from '@/components/page/config/TypeConfig';
import CategoryTreeConfig from '@/components/page/config/CategoryTree/CategoryTreeConfig';
import CalendarConfig from '@/components/page/config/CalendarConfig';
import FieldsConfig from '@/components/page/config/FieldsConfig';
import PageCountConfig from '@/components/page/config/PageCountConfig';
import KanbanConfig from '@/components/page/config/KanbanConfig';
import SearchConfig from '@/components/page/config/SearchConfig';
import { getFilterRendererByFormRenderer } from '@/components/page/filter/fieldOperators.js';
import FilterElement from './FilterElement.vue';
import FilterElementAutocomplete from './autocomplete/FilterElementAutocomplete.vue';
import FilterElementAutocomplete2 from './autocomplete/FilterElementAutocomplete2.vue';
import FilterElementCheckbox from './FilterElementCheckbox.vue';
import FilterElementDatetimepicker from './FilterElementDatetimepicker.vue';
import FilterElementEnum from './FilterElementEnum.vue';
import FilterElementNumber from './FilterElementNumber.vue';
import FilterElementRadio from './FilterElementRadio.vue';
import FilterElementString from './FilterElementString.vue';
import FilterElementTimepicker from './FilterElementTimepicker.vue';

export default {
  name: 'TheFilter',
  components: {
    TypeConfig,
    CategoryTreeConfig,
    CalendarConfig,
    FieldsConfig,
    KanbanConfig,
    SearchConfig,
    PageCountConfig,
    FilterElementEnum,
    FilterElementNumber,
    FilterElement,
    FilterElementAutocomplete,
    FilterElementAutocomplete2,
    FilterElementCheckbox,
    FilterElementString,
    FilterElementRadio,
    FilterElementTimepicker,
    FilterElementDatetimepicker,
  },

  props: {
    pageConfig: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Array,
      default: () => [],
    },
    configData: {
      type: Object,
      default: null,
    },
    tableConfig: {
      type: Object,
      default: null,
    },
    formConfig: {
      type: Object,
      default: null,
    },
    configLayout: {
      type: Object,
      default: null,
    },
    fieldsMeta: {
      type: Array,
      default: null,
    },
    entityType: {
      type: String,
      required: true,
    },
    canCreateModel: {
      type: Boolean,
      default: null,
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
    isPublishable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filterConfig: null,
      showFilters: false,
      filterApplied: false,
      tempParams: null,
      params: null,
      search: '',
      openedFilter: null,
      activeFilters: [],
    };
  },

  computed: {
    showCategoryTree() {
      // @TODO hardcode for lada
      return this.entityType === 'Item';
    },
    controlsSize() {
      return store.state.isDesktop ? 'large' : 'small';
    },
    filterButtonType() {
      let type = 'default';
      if (this.filterApplied) {
        type = 'primary';
      }

      return type;
    },
    hasFilters() {
      return this.filterConfig.length > 0;
    },
    searchField() {
      return this.pageConfig.searchField;
    },
    isKanbanDisplayType() {
      return this.config.displayType.value === 'kanban';
    },
    isCalendarDisplayType() {
      return ['calendar', 'gantt'].includes(this.config.displayType.value);
    },
    config() {
      return this.configData.config;
    },
  },

  watch: {
    searchField(selectedField, oldField) {
      const search = this.params[oldField].value;
      const operator = this.params[oldField].operator;
      this.params[oldField].value = [];
      this.params[oldField].operator = [];
      if (search && this.params[selectedField].value) {
        this.params[selectedField].value = search;
        this.params[selectedField].operator = operator;
      }
      this.applyParams();
    },
    pageConfig() {
      this.updateConfig();
    },
  },

  created() {
    this.filterConfig = this.createConfig();
    this.resetParameters();
    this.applySavedParams();
  },

  methods: {
    createConfig() {
      if (this.hideFilters) return [];
      return this.pageConfig.displayFields
        .filter(({ name }) => name !== 'id' && !this.tableConfig?.columns[name]?.renderer)
        .map((fieldFilter) => {
          const descriptor = this.fieldsMeta.find(({ name }) => name === fieldFilter.name);
          const filterRenderer = getFilterRendererByFormRenderer(descriptor.renderer, {
            publishable: this.isPublishable,
          });

          return (
            filterRenderer && {
              ...descriptor,
              ...fieldFilter,
              ...getFilterRendererByFormRenderer(descriptor.renderer, {
                publishable: this.isPublishable,
              }),
              formRenderer: descriptor.renderer,
              label: getFieldLabel(
                FormConfigService.getFormConfig(this.entityType).locale[store.state.lang].fields,
                descriptor,
                store.state.lang,
              ),
            }
          );
        })
        .filter((field) => !!field);
    },

    updateConfig() {
      this.filterConfig.forEach((filter) => {
        filter.label = getFieldLabel(
          FormConfigService.getFormConfig(this.entityType).locale[store.state.lang].fields,
          filter,
          store.state.lang,
        );
      });
    },

    setParamOperator({ name }, operator) {
      this.tempParams[name].operator = operator;
    },

    setParamValue({ name }, value) {
      this.tempParams[name].value = value || [];
    },

    selectCategory(isAddCategory, category) {
      if (isAddCategory) {
        this.setParamValue({ name: 'category' }, category);
      } else {
        const index = this.params.category.value.findIndex((el) => el.value === category.value);
        this.params.category.value.splice(index, 1);
        this.params.category.operator.splice(index, 1);
      }

      this.applyParams(false);
    },

    setSearchValue(value) {
      this.search = value;
    },

    changeFilter(index) {
      this.openedFilter = index;
    },

    resetParam({ name }) {
      this.$set(this.params, name, {
        operator: [],
        value: [],
      });
    },

    resetTempParam({ name, operators }) {
      this.$set(this.tempParams, name, {
        operator: operators[0].key,
        value: null,
      });
    },

    resetParameters() {
      this.search = '';
      this.tempParams = {};
      this.params = {};
      this.filterConfig.forEach(this.resetParam);
      this.filterConfig.forEach(this.resetTempParam);
    },

    applySavedParams() {
      if (this.filters.length > 0) {
        this.filters.forEach(({ field, operator, value }) => {
          this.params[field].operator = Array.isArray(operator) ? operator : [operator];
          this.params[field].value = Array.isArray(value) ? value : [value];
        });

        this.applyParams(true);
      }
    },

    toggleFiltersPopover(showStatus) {
      this.showFilters = showStatus;
    },

    getElementComponent({ renderer }) {
      return (
        {
          ref: 'filter-element-autocomplete',
          refs: 'filter-element-autocomplete',
          ref2: 'filter-element-autocomplete2',
          'ref-like': 'edit-form-autocomplete2',
        }[renderer] || `filter-element-${renderer}`
      );
    },

    removeFilter(param) {
      this.params[param.name].operator = [];
      this.params[param.name].value = [];

      if (param.name === this.searchField) {
        this.search = '';
      }

      this.applyParams();
    },

    emptyValue(v) {
      return ['', null, undefined].includes(v);
    },

    getFormattedDateTime(value, fieldConfig) {
      value = moment(Number(value));

      const format = {
        'date-time': 'DD.MM.YYYY HH:mm:ss',
        date: 'DD.MM.YYYY',
        time: 'HH:mm:ss',
      }[fieldConfig.formRenderer];

      return value.format(format);
    },

    getActiveFilterSlug(valuesList, operatorsList, fieldConfig) {
      return valuesList
        .map((value, index) => {
          let slugValue = value;
          if (fieldConfig.renderer === 'datetimepicker') {
            slugValue = this.getFormattedDateTime(value, fieldConfig);
          } else if (typeof value === 'object') {
            slugValue = value.title;
          }

          const operatorSlug = fieldConfig.operators.find(
            (o) => o.key === operatorsList[index],
          ).key;

          return `${this.$t(`filter.operator.${operatorSlug}`).toLowerCase()} "${slugValue}"`;
        })
        .join(this.$t('filter.active.or'));
    },

    applyParams(initialApply = false) {
      Object.keys(this.params).forEach((key) => {
        if (this.tempParams[key].value) {
          this.params[key].operator.push(this.tempParams[key].operator);
          this.params[key].value.push(this.tempParams[key].value);
        }
      });

      if (this.search) {
        this.params[this.searchField].operator = [this.tempParams[this.searchField].operator];
        this.params[this.searchField].value = [this.search];
        this.search = '';
      }

      this.filterConfig.forEach(this.resetTempParam);
      this.activeFilters = [];
      const params = Object.entries(this.params)
        .filter((p) => p[1].value.length)
        .map(([name, { operator, value }]) => {
          const config = this.filterConfig.find((filter) => filter.name === name);
          const { valueProcessor = (v) => v } = config;

          if (name === this.searchField && !this.emptyValue(this.search)) {
            this.search = value[0];
          }

          const filterSlug = `"${config.label}" ${this.getActiveFilterSlug(
            value,
            operator,
            config,
          )}`;

          this.activeFilters.push({
            name,
            filterSlug,
          });

          return {
            field: name,
            value: value.map((v) => valueProcessor(v)),
            operator,
          };
        });

      this.toggleFiltersPopover(false);
      this.changeFilter(null);
      this.filterApplied = params.length > 0;
      this.$emit('setFilterParams', params, initialApply);
    },

    configUpdated() {
      this.$emit('configUpdated');
    },
  },
};
</script>

<style lang="scss">
.filter {
  margin-bottom: 10px;

  &__top {
    margin: -5px 0;

    & > div {
      margin: 5px 0;
    }

    &-last-slot {
      display: flex;
      align-items: center;
    }
  }

  &__button {
    vertical-align: middle;

    & + & {
      margin-left: 10px;
    }
  }

  &__popover {
    width: 250px;
  }

  &__options {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: stretch;
    min-width: 250px;
  }

  &__back {
    cursor: pointer;
    color: #1890ff;
    margin-bottom: 10px;
  }

  &__search-wrapper {
    flex-grow: 1;
  }

  &__tags {
    padding-top: 10px;
    .ant-tag {
      max-width: 100%;
      overflow: hidden;
      .anticon-close {
        color: inherit;
        margin-right: 6px;
      }
    }
  }
  &__tag-text {
    max-width: 100%;
    word-wrap: break-word;
  }

  &__category-tree {
    margin-top: 8px;
  }

  &__titles {
    padding: 8px 0px;
    position: relative;
    cursor: pointer;
    span {
      z-index: 2;
      position: relative;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:before {
      content: '';
      position: absolute;
      width: calc(100% + 30px);
      height: 100%;
      left: -15px;
      top: 0;
      background-color: #e6f7ff;
      opacity: 0;
      transition: opacity ease 0.3s;
      z-index: 1;
    }
    &:hover,
    &.active {
      &:before {
        opacity: 1;
      }
    }
    i {
      position: absolute;
      right: 0;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  button + button {
    margin-left: 10px;
  }
}

.pager {
  display: flex;
  align-items: center;

  button + button {
    margin-left: 10px;
  }
}

@media (min-width: $tabletBreakpoint) {
  .filter {
    &__options {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>
