<template>
  <img
    :src="src"
    :width="width"
    :height="height"
    alt=""
  />
</template>

<script>
import store from '@/store';
import { imageUrl } from '@/helpers';

export default {
  name: 'BaseImage',
  props: {
    path: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    fit: {
      type: String,
      default: 'cover',
    },
  },
  computed: {
    src() {
      const sizeMultiplier =
        store.state.display.isRetina || store.state.display.isHighDensity ? 2 : 1;
      return imageUrl(this.path, {
        width: this.width * sizeMultiplier,
        height: this.height * sizeMultiplier,
      });
    },
  },
};
</script>
