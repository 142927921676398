import gql from 'graphql-tag';

export default {
  fetchPolicy: 'network-only',
  query: gql`
    query Get($type: String, $id: String) {
      get(type: $type, id: $id) {
        document {
          data
          deleted
          draftId
          id
          snapshot
          type
        }
      }
    }
  `,
  update: ({ get }) => get,
};
