import gql from 'graphql-tag';

export default {
  fetchPolicy: 'network-only',
  query: gql`
    query Search($type: String!, $value: String!, $first: Int) {
      search(entityName: $type, search: $value, first: $first) {
        documents
      }
    }
  `,
  update({ search }) {
    return search?.documents
      .filter((entry) => !entry.data.deleted)
      .map((entry) => ({
        value: entry.data.id,
        title: entry.snapshot,
      }));
  },
};
