<template>
  <div
    v-if="users"
    class="so-course-history"
  >
    <h2 class="so-course-history__h2">{{ $t('SOCourse.moderationHistory') }}</h2>
    <div
      v-for="(entry, idx) in history"
      :key="idx"
      class="so-course-history__item"
    >
      <p class="so-course-history__user">{{ users[entry.userId] || '' }}</p>
      <p class="so-course-history__date-time">{{ localeDateTame(entry.date) }}</p>
      <p class="so-course-history__status">
        {{ getLocalStatus(entry.payload.statusId) }}
      </p>
      <p
        v-if="entry.payload._comment"
        class="so-course-history__comment"
      >
        Комментарий: {{ entry.payload._comment }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import HISTORY_QUERY from '@/queries/historySOCourse';
import TABLE_QUERY from '@/queries/table';
import 'moment/locale/ru';

moment.locale('ru');

export default {
  name: 'SoCourseHistory',
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {},
  apollo: {
    history: {
      ...HISTORY_QUERY,
      loadingKey: 'loading',
      fetchPolicy: 'network-only',
      variables() {
        return {
          entityName: 'SOCourse',
          entityId: this.id,
        };
      },
      update({ history }) {
        return history && history.entries.sort((a, b) => b.date - a.date);
      },
      error(error) {
        this.emitError(this.$t('history.error.getList'), error.message);
      },
    },
    users: {
      ...TABLE_QUERY,
      loadingKey: 'loading',
      fetchPolicy: 'network-only',
      variables: {
        type: 'User',
        page: 0,
        pageSize: 999,
      },
      update({ table }) {
        return table.documents.reduce((users, el) => {
          users[el.id] = el.data.username;
          return users;
        }, {});
      },
      error(error) {
        this.emitError(this.$t('entity.error.getTable'), error.message);
      },
    },
    SOStatuses: {
      ...TABLE_QUERY,
      loadingKey: 'loading',
      fetchPolicy: 'network-only',
      variables: {
        type: 'SOStatuses',
        page: 0,
        pageSize: 999,
      },
      update({ table }) {
        return table.documents.reduce((statuses, el) => {
          statuses[el.data.id] = el.data.title;
          return statuses;
        }, {});
      },
      error(error) {
        this.emitError(this.$t('entity.error.getTable'), error.message);
      },
    },
  },
  methods: {
    localeDateTame(timestamp) {
      timestamp = timestamp && moment(timestamp);
      timestamp = timestamp.tz('Europe/Moscow').format('DD MMMM, HH:mm');

      return timestamp || '';
    },
    getLocalStatus(statusId) {
      const localized =
        (this.SOStatuses && this.$t(`SOCourse.status.${this.SOStatuses[statusId]}`)) || '';
      return localized.startsWith('SOCourse.status')
        ? this.SOStatuses[statusId] || statusId
        : localized;
    },
  },
};
</script>

<style lang="scss">
.so-course-history {
  &__h2 {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }

  &__item {
    & > * {
      margin-bottom: 0;
    }
    margin-bottom: 32px;
  }
  &__user {
    color: black;
  }

  &__date-time,
  &__comment {
    color: rgba(0, 0, 0, 0.45);
  }

  &__status {
    color: #1890ff;
  }
}
</style>
