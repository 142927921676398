<template>
  <error-output :errors="errors">
    <a-date-picker
      ref="focusOnMount"
      v-model="input"
      v-bind="optionalPickerProps"
      :class="[
        'datepicker',
        { 'datepicker--with-time': showTime, 'datepicker--full-wdth': fullWidth, readOnly },
      ]"
      :format="format"
      :showTime="showTime"
      :disabled="disabled || readOnly"
      locale="ru-RU"
      :placeholder="placeholder"
      :align="{
        offset: isInlineTable ? [-16, -6] : [0, 0],
      }"
      @blur="$emit('blur')"
      @ok="close"
      @change="change"
    />
  </error-output>
</template>

<script>
import moment from 'moment';
import FocusOnMountMixin from '@/components/base/focusOnMount.mixin';
import ErrorOutput from '@/components/base/ErrorOutput';

export default {
  name: 'EditFormDate',
  mixins: [FocusOnMountMixin],
  components: {
    ErrorOutput,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'default',
    },
    isInlineTable: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    input: {
      get() {
        const value = this.value && moment(this.value);
        return value;
      },
      set(value) {
        if (value) {
          if (this.config.renderer === 'date') {
            value = value.startOf('day').valueOf();
          } else {
            value = value.startOf('second').valueOf();
          }
        }

        this.$emit('input', value);
      },
    },
    format() {
      return {
        'date-time': 'DD.MM.YYYY HH:mm:ss',
        date: 'DD.MM.YYYY',
        time: 'HH:mm:ss',
      }[this.config.renderer];
    },
    showTime() {
      return Boolean(this.format.match(/[Hhkms]/));
    },
    placeholder() {
      return this.disabled || this.readOnly ? '' : this.$t(`base.placeholder.select`);
    },
    optionalPickerProps() {
      // нужно в таблицах где открывается "авто"-фокусом и не нужно в формах,
      // чтобы работал антовский обработчик по клику
      return this.focusOnMount ? { open: this.open } : {};
    },
  },
  methods: {
    async setFocus() {
      setTimeout(() => {
        this.open = true;
      }, 100);
    },

    close() {
      this.open = false;
    },

    change() {
      if (this.isInlineTable) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss">
.datepicker {
  &.readOnly .ant-input {
    background-color: #fff;
    color: inherit;
    cursor: text;
  }

  &--full-wdth {
    width: 100%;
  }
}

.entity-form.small .datepicker .ant-input {
  line-height: 24px;
  height: 24px;
}
</style>
