<template>
  <div
    :class="[
      'entity-modal publishable',
      { subformActive: subformMeta, hiddenHistory: isDraft || subformMeta },
    ]"
  >
    <div class="publishable__wrapper">
      <div
        v-show="loading > 0"
        class="publishable__loader"
      ></div>

      <entity-view
        v-if="contentVisible && savedData"
        :id="id"
        :type="entityName"
        :dataProcessor="dataProcessor"
        :data="savedData"
        isModal
        noHistory
        :extMeta="formMeta.fields"
        @goBack="close"
        @switchMode="switchMode"
      >
        <template #additionalHeadControls>
          <a-icon
            class="entity-view__head-button"
            :type="fullscreenIcon"
            @click="editFullEntity"
          />
        </template>

        <template
          #form1
          v-if="!subformMeta && history"
        >
          <publishable-entity-history
            :history="history"
            :selectedVersion="formMode === 'history' ? selectedVersionHistoryMode : selectedVersion"
            :currentVersion="currentVersion"
            :published="published"
            @selectVersion="selectVersion"
          />
        </template>

        <template #form2>
          <div v-if="subformMeta">
            <publishable-entity-form
              key="sub-form"
              :formName="subformName"
              :formMeta="subformMeta"
              :formData="subformCtx"
              :formErrors="formErrors"
              :currentData="subformCtx"
              :formState="formState"
              @dataChanged="subformDataChanged"
            >
              <template #footer>
                <form-button
                  v-for="button of subformButtonsMeta"
                  :key="button.name"
                  :config="button"
                  :formState="formState"
                  @click="onSubformButtonClick"
                />
              </template>
            </publishable-entity-form>
          </div>

          <div v-else-if="!formConfig || !savedData || !formMeta"></div>

          <div v-else>
            <div
              v-if="formMode === 'history'"
              class="entity-view__compare-form"
            >
              <entity-form-json-compare
                loading
                :rawSavedData="dataHistoryMode"
                :rawCurrentData="data"
                :meta="componentMeta"
                @dataUpdated="jsonChanged"
              />
            </div>

            <publishable-entity-form
              v-show="formMode !== 'history'"
              key="top-level-form"
              :formName="entityName"
              :formMeta="formMeta"
              :formData="savedData"
              :formErrors="formErrors"
              :currentData="data"
              :formState="formState"
              @dataChanged="currentDataChanged"
            >
              <template #footer>
                <form-button
                  v-for="button of buttonsMeta"
                  :key="button.name"
                  :config="button"
                  :formState="formState"
                  @click="onButtonClick"
                />
              </template>
            </publishable-entity-form>
          </div>
        </template>
      </entity-view>
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import store from '@/store';
import {
  XHR,
  bus,
  parseJson,
  deepFind,
  composeGqlQueryFromAction,
  composeGqlQueryFromActionWithVariables,
} from '@/helpers';
import FormConfigService from '@/services/FormConfigService';
import EntityView from '@/components/edit-form/EntityView';
import FormButton from '@/components/meta-form/fields/FormButton';
import EntityFormJsonCompare from '@/components/edit-form/EntityFormJsonCompare.vue';
import PublishableEntityHistory from './PublishableEntityHistory';
import PublishableEntityForm from './PublishableEntityForm';

/**
 * sourceData - получаемые текущие данные сущности с бека, прелетающие из get-экшена
 *   изменяется только по данным из ответов экшенов
 *
 * savedData - sourceData + данные выбранной версии в хистори. чистый набор данных,
 *   с котором сравнивается текущий (data) для определяния наличия изменений в форме
 *   изменяется после экшенов и при выборе версии
 *
 * data - текущие данные формы. savedData + любые изменения полей
 */

export default {
  components: {
    EntityFormJsonCompare,
    EntityView,
    PublishableEntityHistory,
    PublishableEntityForm,
    FormButton,
  },

  apollo: {
    $client: 'newApiClient',
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    fullscreen: {
      type: Boolean,
      required: true,
    },
    dataProcessor: {
      type: Function,
      default: null,
    },
    contentVisible: {
      type: Boolean,
      required: true,
    },
  },

  provide: {
    isPublishable: true,
    comparePane: null,
  },

  data() {
    return {
      loading: 0,
      formMeta: null,
      formConfig: null,
      data: null,
      dataHistoryMode: null,
      savedDataHistoryMode: null,
      sourceData: null,
      savedData: null,
      history: null,
      formMode: 'edit',
      currentVersion: null,
      selectedVersion: null,
      selectedVersionHistoryMode: null,
      selectedVersionEntry: null,
      subformName: null,
      subformMeta: null,
      subformData: {},
      subformCtx: null,
      formErrors: [],
    };
  },

  computed: {
    fullscreenIcon() {
      return this.fullscreen ? 'fullscreen-exit' : 'fullscreen';
    },

    isDraft() {
      return this.id.startsWith('_temp_');
    },

    componentMeta() {
      return store.state.meta.components[this.type];
    },

    entityName() {
      return store.state.activeSidebarItem.code;
    },

    formMetaSource() {
      const metaField = this.isDraft ? 'createMeta' : 'updateMeta';
      return store.state.activeSidebarItem.customprops?.[metaField];
    },

    version() {
      return this.selectedVersion;
    },

    historyData() {
      return this.savedData;
    },

    published() {
      const fieldMeta = this.fieldsMeta.find((field) => field.name === 'j_published');
      if (fieldMeta?.renderer === 'hidden') return !!parseJson(this.data.j_published);
      return !!this.data.j_published;
    },

    fieldsMeta() {
      return this.formMeta.fields.filter(
        (f) => !['history', 'history-entry', 'button'].includes(f.renderer),
      );
    },

    buttonsMeta() {
      return this.formMeta.fields.filter((f) => f.renderer === 'button');
    },

    subformButtonsMeta() {
      return this.subformMeta.fields.filter((f) => f.renderer === 'button');
    },

    formState() {
      const isDirty = !isEqual(this.savedData, this.data);
      let isPublished = !!this.data.j_published;
      if (this.fieldsMeta.find((field) => field.name === 'j_published')?.renderer === 'hidden') {
        isPublished = !!parseJson(this.data.j_published);
      }

      return {
        isDirty,
        isPublished,
        isCurrentVersion: this.selectedVersion === this.currentVersion,
        isNeedsReview: !!this.selectedVersionEntry?.j_needs_review,
        handbooks: this.formMeta.handbooks,
      };
    },
  },

  async created() {
    this.loading++;
    await this.loadMeta();
    await this.loadData();
    await this.loadHistoryList();

    if (!this.isDraft) {
      const firstHistoryEntry = this.history.find((entry) => {
        if (entry.j_version !== this.currentVersion) return;
        return entry;
      });

      await this.selectVersion(firstHistoryEntry, 'any');
    }

    this.loadConfig();
    this.loading--;
  },

  methods: {
    editFullEntity() {
      this.$emit('editFullEntity');
    },

    switchMode(mode) {
      this.formMode = mode;
    },

    editEntity(...args) {
      this.$emit('editEntity', ...args);
    },

    close() {
      this.$emit('close');
    },

    async loadData() {
      if (this.isDraft) {
        this.resetData();
        return;
      }

      let sourcesKeys = this.fieldsMeta.map(
        (field) => field.source?.match(/^\$([a-zA-Z_]+?)\./)[1] || 'ctx',
      );
      sourcesKeys = [...new Set(sourcesKeys)].filter((key) => key !== 'ctx');

      let loadedData = await Promise.all(
        sourcesKeys.map((sourceKey) => this.loadDataSource(this.formMeta[sourceKey])),
      );

      loadedData = this.mergeAndPrepareDataArray(loadedData);
      this.resetData(loadedData);
    },

    /**
     * Слияние нескольких и массивов и необходимая обработка в соответствии с метой --
     * подмена имён, приведение к строке значений скрытых полей.
     */
    mergeAndPrepareDataArray(dataArray) {
      if (!dataArray) return null;
      if (!Array.isArray(dataArray)) dataArray = [dataArray];

      const combinedData = dataArray.reduce((acc, data) => {
        Object.assign(acc, data);
        return acc;
      }, {});

      return this.fieldsMeta.reduce((acc, field) => {
        if (field.source) {
          const path = field.source.match(/^\$(?:[a-zA-Z_]+?)\.(.*)$/)[1];
          const value = deepFind(combinedData, path);
          if (field.renderer === 'hidden') {
            acc[field.name] = JSON.stringify(value);
          } else {
            acc[field.name] = value;
          }
        }

        return acc;
      }, {});
    },

    async loadDataSource(action) {
      if (action.type === 'REST') {
        const data = await this.loadRestData(action);
        return data;
      }

      if (action.type === 'GRAPHQL') {
        const { data } = await this.$apollo.query({
          query: composeGqlQueryFromAction({
            action,
            data: {},
            context: this,
            fieldsMeta: this.fieldsMeta,
          }),
          client: 'newApiClient',
          fetchPolicy: 'network-only',
        });

        const [entity, method] = action.path.split('.').slice(1);
        return data[entity][method];
      }
    },

    async loadRestData(action) {
      return new Promise((resolve, reject) => {
        this.loadingQueries++;
        let url = action.url;

        if (action.params?.length) {
          if (action.paramsType === 'path') {
            url = action.params.reduce(
              (acc, name) => `${acc}/${this.$route.params.rowData[name]}`,
              url,
            );
          }
        }

        XHR.query(action.method, url, {
          absoluteUrl: true,
        }).then(
          (data) => {
            data = parseJson(data);

            this.loadingQueries--;
            resolve(data);
          },
          (error) => {
            this.$notification.error({
              message: error.message,
            });

            this.loadingQueries--;
            reject();
          },
        );
      });
    },

    async loadMeta() {
      const response = await XHR.get(this.formMetaSource);

      const meta = parseJson(parseJson(response));
      meta.fields = meta.fields
        .filter((field) => {
          if (field.renderer !== 'button') return true;
          if (!Array.isArray(field.roles)) return true;

          return field.roles.some((role) => store.state.user.roles.includes(role));
        })
        .map((field) => {
          field.types = field.conf?.types;

          if (['component', 'embed'].includes(field.renderer)) {
            const fieldIsEmbed = field.renderer === 'embed';
            const childDict = fieldIsEmbed ? 'embeds' : 'components';

            field.typesDict = field.types.reduce((dict, type) => {
              dict[type] = store.state.meta[childDict][type];
              return dict;
            }, {});
          }

          if (field.name === 'j_comments') {
            field.name = 'h_j_comments';
            field.source = '$data.h_j_comments';
          }

          return field;
        });

      this.formMeta = meta;
    },

    loadConfig() {
      this.formConfig = FormConfigService.getPublishableFormConfig(
        store.state.activeSidebarItem.id,
        {
          ...this.formMeta,
          fields: this.formMeta.fields.filter(
            (f) => !['history', 'history-entry', 'button'].includes(f.renderer),
          ),
        },
      );
    },

    async loadHistory(action, where = {}) {
      const { data } = await this.$apollo.query({
        client: 'newApiClient',
        fetchPolicy: 'network-only',
        query: composeGqlQueryFromAction({
          action,
          data: this.sourceData,
          context: { ...this, historyEntryFilter: where },
          fieldsMeta: this.fieldsMeta,
        }),
      });

      return data;
    },

    async loadHistoryList(selectVersion) {
      const historyField = this.formMeta.fields.find((f) => f.renderer === 'history');

      if (this.isDraft || !historyField) return;

      this.loading++;
      const data = await this.loadHistory(historyField.action);

      this.history = data[this.entityName].history.data.sort(
        (a, b) => b.j_created_at - a.j_created_at,
      );

      if (selectVersion) {
        await this.selectVersion(this.history.find((entry) => entry.j_version === selectVersion));
      }

      this.loading--;
    },

    async loadSelectedHistoryEntry(jVersion) {
      const historyEntryField = this.formMeta.fields.find((f) => f.renderer === 'history-entry');

      if (!historyEntryField) return;

      let data;
      this.loading++;

      try {
        data = await this.loadHistory(historyEntryField.action, {
          j_version: { _eq: jVersion },
        });

        data = data[this.entityName].history.data[0];
      } catch (e) {
        this.emitError(this.$t('app.history.error.getList'));
      }

      this.loading--;
      return data;
    },

    validateForm(dataSource, fieldsMeta, data) {
      const isObjectValue = typeof dataSource === 'object';
      const isConstant =
        !isObjectValue && (typeof dataSource !== 'string' || !dataSource.startsWith('$'));
      const isContextField = !isObjectValue && !isConstant && dataSource.startsWith('$ctx');
      let fieldName;

      if (isObjectValue) {
        return Object.values(dataSource)
          .map((field) => this.validateForm(field, fieldsMeta, data))
          .flat()
          .filter((e) => e !== false);
      }

      if (isContextField) {
        fieldName = dataSource.slice(5);
      } else if (!isConstant) {
        fieldName = dataSource.slice(1);
      }

      const fieldMeta = fieldsMeta.find((field) => field.name === fieldName);

      if (!fieldMeta) return false;

      if (fieldMeta.required && !data[fieldName]) {
        return this.$t('metaForms.fieldIsRequired', { field: fieldMeta.label });
      }

      return false;
    },

    onButtonClick({ action }) {
      this.formErrors = this.validateForm(action.parameters, this.fieldsMeta, this.data);
      if (this.formErrors.length) return;

      this.performAction({
        action,
        data: this.data,
        context: this,
        fieldsMeta: this.fieldsMeta,
      });
    },

    prepareDataForPublishable(data, fields) {
      const datax = fields.reduce((acc, field) => {
        let value = data[field.name];
        if (field.renderer === 'embed' && value) {
          value = value.map(({ type, data: itemData }) => ({
            ...this.prepareDataForPublishable(itemData, field.typesDict[type].fields),
            __typename: type,
          }));
        }

        acc[field.name] = value;
        return acc;
      }, {});

      return datax;
    },

    performAction({
      action = {},
      data,
      context,
      fieldsMeta,
      onSuccess = this.onActionSuccess,
    } = {}) {
      if (action.type === 'GRAPHQL') {
        data = this.prepareDataForPublishable(data, fieldsMeta);

        const query = composeGqlQueryFromActionWithVariables({
          action,
          data,
          context,
          fieldsMeta,
          formMeta: this.formMeta,
        });

        this.loading++;
        this.$apollo
          .mutate({
            mutation: query.query,
            variables: query.variables,
          })
          .then(
            (response) => {
              const path = action.path.split('.').slice(1).join('.');
              const newId = deepFind(response.data, `${path}.id`, true);

              const [responseEntityName, responseDataKey] = action.path.split('.').slice(-2);
              const responseData = response.data[responseEntityName][responseDataKey];

              onSuccess({
                action,
                newId,
                responseData: responseEntityName === this.entityName ? responseData : {},
                response,
              });
            },
            () => {
              this.loading--;
            },
          );
      } else if (action.type === 'REST') {
        let url = action.url;
        this.loading++;

        if (action.params?.length) {
          if (action.paramsType === 'path') {
            url = action.params.reduce(
              (acc, name) => `${acc}/${this.$route.params.rowData[name]}`,
              url,
            );
          }
        }

        XHR.query(action.method, url, {
          absoluteUrl: true,
          data:
            action.params &&
            action.params.reduce((acc, key) => {
              acc[key] = data[key];
              return acc;
            }, {}),
        }).then(
          (response) => {
            onSuccess({ action, responseData: parseJson(response) });
          },
          (error) => {
            this.$notification.error({
              message: error.message,
            });

            this.loading--;
          },
        );
      }
    },

    async onActionSuccess({ action, newId = null, responseData, response }) {
      if (response?.extensions) {
        let url = response.extensions.form;
        url = url.startsWith('/forms/') ? url : `/forms/${url}`;

        const extData = await XHR.get(url);
        this.subformName = response.extensions.form;

        const meta = parseJson(extData);
        meta.fields = meta.fields.map((field) => {
          field.types = field.conf?.types;

          if (['component', 'embed'].includes(field.renderer)) {
            const fieldIsEmbed = field.renderer === 'embed';
            const childDict = fieldIsEmbed ? 'embeds' : 'components';

            field.typesDict = field.types.reduce((dict, type) => {
              dict[type] = store.state.meta[childDict][type];
              return dict;
            }, {});
          }

          return field;
        });

        this.subformMeta = meta;
        this.subformCtx = response.extensions.ctx;
        this.subformData = { ...this.subformCtx };
        this.loading--;

        return;
      }

      bus.$emit('refetchTable');
      this.$notification.success({
        message: this.$t('metaform.dataSaved'),
      });

      if (action.closeForm) {
        return this.close();
      }

      if (newId && newId !== this.id) {
        return this.$emit('editEntity', this.type, newId, false, true);
      }

      if (this.subformMeta) {
        this.closeSubform();
      }

      const version = this.selectedVersion;
      store.mutate.clearPublishableHistoryEntry(this.type);
      await this.selectVersion();
      this.applyData(responseData);
      this.loadHistoryList(version);
      this.loading--;
    },

    resetData(data = {}, { updateSource = true } = {}) {
      this.data = { ...data };
      this.savedData = { ...data };

      if (updateSource) {
        this.sourceData = { ...data };
      }

      if (this.sourceData.j_version) {
        try {
          this.currentVersion = parseJson(this.sourceData.j_version);
        } catch {
          this.currentVersion = this.sourceData.j_version;
        }
      }
    },

    applyData(updatedData) {
      if (!updatedData) return;

      updatedData = this.fieldsMeta.reduce(
        (acc, field) => {
          if (updatedData[field.name] !== undefined) {
            const formField = this.formMeta.fields.find((f) => f.name === field.name);
            acc[field.name] =
              formField.renderer === 'hidden'
                ? JSON.stringify(updatedData[field.name])
                : updatedData[field.name];
          }

          return acc;
        },
        { ...this.data },
      );

      this.resetData(updatedData);
    },

    prepareHistoryData(data, historyData) {
      if (!historyData) return;

      const newFieldsMeta = [];
      const updateData = { ...data };

      Object.keys(historyData).forEach((key) => {
        if (key === '__typename') return;

        const dataKey = key.startsWith('j_') ? `h_${key}` : key;
        const formField = this.formMeta.fields.find((field) => field.name === dataKey);

        if (formField) {
          updateData[dataKey] =
            formField.renderer === 'hidden' ? JSON.stringify(historyData[key]) : historyData[key];
        } else {
          newFieldsMeta.push({
            name: dataKey,
            source: `$_.${dataKey}`,
            renderer: 'hidden',
            sortable: true,
          });

          updateData[dataKey] = JSON.stringify(historyData[key]);
        }
      });

      return { newFieldsMeta, updateData };
    },

    applyHistoryData(historyData) {
      if (!historyData) return;

      const { newFieldsMeta, updateData } = this.prepareHistoryData(this.data, historyData);

      this.formMeta.fields.push(...newFieldsMeta);
      this.resetData(updateData, { updateSource: false });
    },

    async selectVersion(historyEntry, mode = null) {
      mode = mode ?? this.formMode;

      if (historyEntry) {
        let historyEntryData = store.mutate.getPublishableHistoryEntry(
          this.type,
          this.id,
          historyEntry.j_version,
        );

        if (!historyEntryData) {
          historyEntryData = await this.loadSelectedHistoryEntry(historyEntry.j_version);
          store.mutate.setPublishableHistoryEntry(
            this.type,
            this.id,
            historyEntry.j_version,
            historyEntryData,
          );
        }

        if (!historyEntryData) {
          this.emitError(this.$t('app.history.error.get'));
          return;
        }

        if (mode !== 'history' || mode === 'any') {
          this.savedDataHistoryMode = historyEntryData;
          this.applyHistoryData(historyEntryData);
          this.selectedVersion = historyEntryData.j_version;
        }

        if (mode === 'history' || mode === 'any') {
          const { updateData } = this.prepareHistoryData(this.sourceData, historyEntryData);
          this.dataHistoryMode = updateData;
          this.selectedVersionHistoryMode = historyEntryData.j_version;
        }

        this.selectedVersionEntry = historyEntryData;
      } else {
        this.selectedVersion = null;
        this.selectedVersionEntry = null;
      }
    },

    subformDataChanged(data) {
      Object.keys(data).forEach((key) => {
        this.subformData[key] = data[key];
      });
    },

    currentDataChanged(data) {
      Object.keys(data).forEach((key) => {
        this.data[key] = data[key];
      });
    },

    jsonChanged(data) {
      data = { ...this.data, ...data };

      // Format json fields
      data = Object.entries(data).reduce((acc, [key, value]) => {
        const fieldMeta = this.formMeta.fields.find((field) => field.name === key);
        if (fieldMeta?.renderer === 'json' && value) {
          value = JSON.stringify(parseJson(value), null, 4);
        }
        acc[key] = value;
        return acc;
      }, {});

      this.data = { ...this.data, ...data };
    },

    closeSubform() {
      this.subformName = null;
      this.subformMeta = null;
      this.subformCtx = null;
    },

    onSubformButtonClick({ action, conf }) {
      if (conf?.type === 'cancel') {
        return this.closeSubform();
      }

      this.formErrors = this.validateForm(
        action.parameters,
        this.subformMeta.fields,
        this.subformData,
      );

      if (this.formErrors.length) return;

      this.performAction({
        action,
        data: this.subformData,
        context: this.subformCtx,
        fieldsMeta: this.subformMeta.fields.filter((f) => f.renderer !== 'button'),
        onSuccess: this.onSubformActionSuccess,
      });
    },

    async onSubformActionSuccess({ action, responseData }) {
      bus.$emit('refetchTable');

      this.$notification.success({
        message: this.$t('metaform.dataSaved'),
      });

      if (action.closeForm) {
        return this.close();
      }

      this.closeSubform();

      const version = this.selectedVersion;
      store.mutate.clearPublishableHistoryEntry(this.type);
      this.selectVersion();
      this.applyData(responseData);
      this.loadHistoryList(version);
      this.loading--;
    },
  },
};
</script>

<style lang="scss">
.publishable {
  position: relative;
  overflow-y: hidden;

  &__wrapper {
    position: relative;
    height: 100%;
  }

  .entity-view {
    padding-right: 10px;
    padding-bottom: 0;
    min-height: calc(100% - 40px);
    height: 100%;

    &__head {
      flex: 0 0 67px;
    }

    &__forms {
      flex-shrink: 1;
      min-height: 0;
      height: 100%;
      padding-bottom: 10px;

      display: flex;
      flex-grow: 1;
      padding-bottom: 40px;

      & > div:nth-child(1) {
        flex: 0 0 25%;
      }

      & > div:nth-child(2) {
        @include scrollbars();
        padding-left: 20px;
        padding-right: 10px;
        flex: 1;
        min-width: 0;
        overflow-y: scroll;
      }
    }
  }

  &.hiddenHistory {
    .entity-view__forms > div:nth-child(1) {
      @include scrollbars();
      flex: 1;
      min-width: 0;
      overflow-y: scroll;
    }
  }

  &.subformActive .anticon-history {
    display: none;
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    opacity: 0.5;
    z-index: 30;
  }

  .json-compare,
  .json-compare > .editor {
    height: 100%;
  }

  .field-renderer--button {
    width: auto;
    margin-bottom: 30px;
  }

  .field-renderer--hidden {
    height: 0;
    overflow: hidden;
  }
}
</style>
