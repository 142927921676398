<template>
  <a-tooltip :title="$t('base.import')">
    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
    <label class="page-block__import">
      <input
        ref="importFile"
        type="file"
        class="page-block__import-input"
        accept=".json,.zip"
        @change="importFile"
      />
      <a-icon
        class="page-block__import-icon"
        type="import"
      />
    </label>
  </a-tooltip>
</template>

<script>
import { bus } from '@/helpers';

export default {
  props: {
    entityType: {
      type: String,
      required: true,
    },
  },

  methods: {
    importFile() {
      const importFile = this.$refs.importFile.files[0];
      const formData = new FormData();

      formData.append('file', importFile);

      try {
        fetch(`/transfer/upload/${this.entityType}/`, { method: 'POST', body: formData }).then(
          (response) => {
            if (response.status !== 200) {
              this.onUploadError(response.statusText);
            } else {
              bus.$emit('error', {
                type: 'success',
                message: this.$t('base.importSuccess'),
              });
            }
          },
        );
      } catch (e) {
        this.onUploadError(e.message);
      }
    },

    onUploadError(message) {
      this.emitError(this.$t('base.importError'), message);
    },
  },
};
</script>
