<template>
  <div class="auro-releases">
    <auro-release
      v-for="document of data.rows"
      :key="document.id"
      :data="document"
      :actionsMeta="actionsMeta"
      class="auro-releases__item"
    />
  </div>
</template>

<script>
import MetaService from '@/services/MetaService';
import AuroRelease from './AuroRelease';

export default {
  name: 'AuroReleases',

  components: {
    AuroRelease,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      actionsMeta: null,
    };
  },

  async created() {
    this.actionsMeta = await MetaService.getEntityActions('Release', [{ action: 'setStatus' }]);
  },
};
</script>

<style lang="scss">
.auro-releases {
  &__item {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
</style>
