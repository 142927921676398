<template>
  <div
    ref="wrapper"
    v-click-outside="hidePicker"
    class="colorpicker"
  >
    <color-plate
      :disabled="disabled"
      :readOnly="readOnly"
      :color="input"
      :size="size"
      @click="showPicker"
    />
    <div @click.stop>
      <color-picker
        v-show="pickerVisible"
        v-model="input"
        class="colorpicker__picker"
        :style="pickerStyle"
        :presetColors="getPreviousColors()"
      />
    </div>
  </div>
</template>

<script>
import { Sketch as ColorPicker } from 'vue-color';
import { parseJson } from '@/helpers';
import FocusOnMountMixin from '@/components/base/focusOnMount.mixin';
import scrollElementInView from '@/components/base/scrollElementInView.mixin';
import lockScroll from '@/components/base/lockScroll.mixin';
import ColorPlate from '@/components/base/ColorPlate';

const PICKER_HEIGHT = 280;

export default {
  name: 'InputColor',

  mixins: [FocusOnMountMixin, scrollElementInView, lockScroll],

  components: {
    ColorPlate,
    ColorPicker,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
    },
  },

  data() {
    return {
      pickerVisible: false,
      placementTop: 0,
    };
  },

  computed: {
    input: {
      get() {
        return this.value || '';
      },
      set(value) {
        this.$emit('input', value.hex8);
      },
    },
    pickerStyle() {
      return { top: `${this.placementTop}px` };
    },
  },

  methods: {
    async setFocus() {
      this.showPicker();
    },

    async showPicker() {
      if (this.disabled || this.readOnly) return;

      const parentModal = this.$refs.wrapper.closest('.entity-modal');
      if (parentModal) {
        const spaceBelow =
          parentModal.scrollHeight - this.getScrollTopOffset(this.$refs.wrapper, '.entity-modal');

        const margin = 15;
        this.placementTop = Math.min(spaceBelow - PICKER_HEIGHT - margin, 0);
        this.lockEntityFormScroll(true);

        const modalFooterHeight = document.querySelector('.entity-form__footer')?.offsetHeight || 0;
        await this.scrollElementInView(
          this.$refs.wrapper,
          { bottom: PICKER_HEIGHT - modalFooterHeight - margin }, // picker height minus space for modal footer
          0,
          '.entity-modal',
          '.entity-modal',
        );
      }

      this.pickerVisible = true;
    },

    hidePicker() {
      if (!this.pickerVisible) return;

      const previousColors = this.getPreviousColors();
      const currentColor = this.input;
      if (previousColors.includes(currentColor)) {
        const colorIndex = previousColors.indexOf(currentColor);
        previousColors.splice(colorIndex, 1);
      }

      previousColors.unshift(currentColor);
      localStorage.setItem('previousColors', JSON.stringify(previousColors.slice(0, 10)));
      this.pickerVisible = false;
      this.lockEntityFormScroll(false);
    },

    getPreviousColors() {
      return parseJson(localStorage.getItem('previousColors')) || [];
    },
  },
};
</script>

<style lang="scss">
.colorpicker {
  position: relative;
  width: 122px;

  &__picker.vc-sketch {
    position: absolute;
    z-index: 21;
  }
}
</style>
