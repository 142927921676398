<template>
  <error-output :errors="errors">
    <input
      ref="focusOnMount"
      v-model="input"
      :placeholder="placeholder"
      :class="['ant-input input-integer', { readOnly }]"
      :disabled="disabled || readOnly"
      :size="size"
      @keypress="preventNonNumericInput"
    />
  </error-output>
</template>

<script>
import FocusOnMountMixin from '@/components/base/focusOnMount.mixin';
import ErrorOutput from '@/components/base/ErrorOutput';

const FLOAT_REGEXP = /^-?([0-9]+|[0-9]+[.]{1}[0-9]*)$/;
const INTEGER_REGEXP = /^-?[0-9]+$/;

export default {
  name: 'InputNumber',
  mixins: [FocusOnMountMixin],
  components: { ErrorOutput },
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    emptyValue: {
      type: Number,
      default: null,
    },
    float: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  computed: {
    input: {
      get() {
        return this.value && String(this.value).replace(/,/g, '.');
      },
      set(value) {
        const valid = value.match(this.float ? FLOAT_REGEXP : INTEGER_REGEXP);
        value = valid ? Number(value) : this.emptyValue;
        this.$emit('input', value);
      },
    },
    negative: {
      get() {
        return this.value && String(this.value)[0] === '-';
      },
      set(value) {
        if (value) {
          this.input = `-${this.input}`;
        } else {
          this.input = this.input.slice(1);
        }
      },
    },
  },
  methods: {
    preventNonNumericInput(event) {
      const charCode = event.which || event.keyCode;

      if (charCode === 45) {
        this.negative = !this.negative;
        event.preventDefault();
      } else if (charCode === 46 && (!this.float || this.input.indexOf('.') > -1)) {
        event.preventDefault();
      } else if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        event.preventDefault();
      } else if (charCode === 13) {
        this.$emit('apply-filters');
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.input-integer {
  &.ant-input-number {
    width: 100%;
  }

  &.ant-input.readOnly {
    background-color: inherit;
    color: inherit;
    cursor: text;
    &:hover {
      border-color: #40a9ff;
    }
  }

  &[size='small'] {
    height: 24px;
    padding: 1px 7px;
  }
}
</style>
