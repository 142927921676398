import gql from 'graphql-tag';

export default function customMutation(name, type, fields) {
  return gql`
    mutation ${name}(${fields
    .map((field) => `$${field.name}: ${field.gqlType}${field.required ? '!' : ''}`)
    .join(', ')}) {
      ${type}Mutation {
        ${name}(${fields.map((field) => `${field.name}: $${field.name}`).join(', ')})
      }
    }
  `;
}
