export * from './bus';
export * from './cookies';
export * from './display';
export * from './format';
export * from './dataProcessing';
export * from './formFields';
export * from './formLayoutParameters';
export * from './filesAndMedia';
export * from './colors';
export * from './storage';
export * from './gqlMetaHelpers';
export * from './jwt';
export * from './json';
export * from './xhr';

let uid = 0;
export function uniqueId(prefix = 'uid') {
  return `${prefix}-${+new Date()}${uid++}`;
}

export function getTempId() {
  return `_temp_${+new Date()}${uid++}`;
}

export function isWideField({ renderer }) {
  return (
    ['gallery', 'image', 'text', 'wysiwyg', 'json', 'component', 'embed'].indexOf(renderer) > -1
  );
}
