<template>
  <fields-config
    v-model="value.columnsField.options.columns"
    :controlsSize="controlsSize"
    :configLayout="configLayout"
    :entityType="entityType"
    :entityFields="entityFields"
    icon="project"
    @configUpdated="configUpdated"
  >
    <template #header>
      <a-select
        v-model="currentValue"
        class="kanban-konfig__selector"
        size="small"
        :allowClear="false"
        :options="options"
      />
    </template>
  </fields-config>
</template>

<script>
import configFormMixin from './configFormMixin';
import FieldsConfig from './FieldsConfig';

export default {
  name: 'KanbanConfig',
  mixins: [configFormMixin],
  components: {
    FieldsConfig,
  },
  props: {
    controlsSize: {
      type: String,
      default: 'large',
    },
  },
  data() {
    return {
      showConfig: false,
      options: [],
    };
  },
  computed: {
    currentValue: {
      get() {
        return this.value.columnsField.value;
      },
      async set(newValue) {
        this.value.columnsField.value = newValue;
        this.configUpdated();
      },
    },
  },
  watch: {
    configLayout: {
      async handler() {
        this.options = await this.configLayout.displayType
          .options(this.entityFields)
          .find((opt) => opt.value === 'kanban')
          .options.columnsField.options(this.entityFields);
      },
      immediate: true,
    },
  },
  methods: {
    setKanbanGroupingField(value) {
      this.currentValue = value;
    },
  },
};
</script>

<style lang="scss">
.kanban-konfig {
  height: 100%;

  &__popover {
    min-width: 300px;
  }

  &__selector {
    display: block;
    margin-bottom: 12px;
  }
}
</style>
