<template>
  <edit-form-field
    :field="config"
    :hideTitle="!!embedLevel"
    :loaded="!subConfigsLoading"
  >
    <template
      v-if="headControlsMode === 'multiple'"
      #afterTitle
    >
      <edit-form-embed-create-button
        v-if="embedLevel === 0 && canCreateItem"
        class="embed-table__array-create"
        :config="config"
        :formConfigs="cachedFormConfigs"
        @click="reemit('createItem', arguments)"
      >
        <a-button type="link">{{ $t('embed.add') }}</a-button>
      </edit-form-embed-create-button>

      <a-pagination
        v-model="currentPage"
        :pageSize="pagerData.pageSize"
        :total="pagerData.totalCount"
        hideOnSinglePage
        simple
      />
    </template>

    <template
      v-else-if="headControlsMode === 'single'"
      #afterTitle
    >
      <div class="entity-field__title-buttons">
        <a-select
          :value="dataRows[0].type"
          :disabled="disabled || readOnly"
          size="small"
          @select="reemit('switchEntityType', arguments)"
        >
          <a-select-option
            v-for="formType of config.typesDict"
            :key="formType.name"
          >
            {{ formTypeLabel(formType.name) }}
          </a-select-option>
        </a-select>
      </div>
    </template>

    <embed-table
      :config="config"
      :disabled="disabled"
      :readOnly="readOnly"
      :parentType="parentType"
      :focusId="focusId"
      :data="dataRows"
      :formConfigs="cachedFormConfigs"
      :embedLevel="embedLevel"
      :embedParentRowId="embedParentRowId"
      :embedParentField="embedParentField || config.name"
      :dragClassname="dragClassname"
      :inlineFormControls="inlineFormControls"
      @duplicate="duplicateItem"
      @updateItem="reemit('updateItem', arguments)"
      @updateArray="reemit('updateArray', arguments)"
      @createItem="reemit('createItem', arguments)"
      @deleteItem="reemit('deleteItem', arguments)"
      @moveItem="reemit('moveItem', arguments)"
      @createNestedItem="reemit('createNestedItem', arguments)"
    />
  </edit-form-field>
</template>

<script>
import store from '@/store';
import { bus } from '@/helpers';
import EditFormField from '@/components/edit-form/EditFormField';
import EditFormEmbedCreateButton from './EditFormEmbedCreateButton';

export default {
  name: 'EditFormEmbedRenderer',
  components: {
    EditFormField,
    EditFormEmbedCreateButton,
    EmbedTable: () => import('./EmbedTable/EmbedTable.vue'),
  },

  props: {
    config: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    embedLevel: {
      type: Number,
      default: 0,
    },
    embedParentRowId: {
      type: String,
      default: null,
    },
    embedParentField: {
      type: String,
      default: null,
    },
    dragClassname: {
      type: String,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    subConfigsLoading: {
      type: Number,
      required: true,
    },
    cachedFormConfigs: {
      type: Object,
      required: true,
    },
    dataRows: {
      type: Array,
      required: true,
    },
    pagerData: {
      type: Object,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    parentType: {
      type: String,
      required: true,
    },
    focusId: {
      type: String,
      required: true,
    },
    canUpdateModel: {
      type: Boolean,
      default: false,
    },
    inlineFormControls: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    valueForAutocomplete: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.reemit('updateForAutocomplete', [value]);
      },
    },

    currentPage: {
      get() {
        return this.page;
      },
      set(page) {
        bus.$emit('changeEmbedPage');
        this.$nextTick(() => {
          this.$emit('changePage', page);
        });
      },
    },

    canCreateItem() {
      return !this.disabled && !this.readOnly && (!this.dataRows.length || this.multiple);
    },

    headControlsMode() {
      let mode = this.multiple ? 'multiple' : 'hidden';
      if (!this.multiple && this.config.types.length > 1) {
        mode = 'single';
      }

      return mode;
    },
  },

  methods: {
    async duplicateItem({ type, id, data }) {
      if (id.startsWith('_temp')) {
        data = store.mutate.getFormDraft(type, id)?.data || {};
      }

      this.$emit('createItem', type, { ...data });
    },

    formTypeLabel(name) {
      return this.cachedFormConfigs[name]?.locale[store.state.lang].entity || name;
    },
  },
};
</script>
