<template>
  <div
    class="editable-cell"
    @click.stop
  >
    <div class="editable-cell__content">
      <edit-form-field-wrap
        v-if="entity && show"
        v-model="inputValue"
        :fieldDescriptor="column"
        :entity="entity"
        :parentType="entity.type"
        isInlineTable
        focusOnMount
      />
    </div>
    <span
      v-if="showIcon"
      class="editable-cell__apply"
      @click="$emit('stopEdit')"
    >
      <a-icon type="check" />
    </span>
  </div>
</template>

<script>
import EditFormFieldWrap from '@/components/edit-form/EditFormFieldWrap';

export default {
  name: 'TableCellEditable',
  components: {
    EditFormFieldWrap,
  },
  props: {
    column: {
      type: Object,
      default: null,
    },
    value: {
      type: [Object, Array, String, Number, Boolean],
      default: null,
    },
    entity: {
      type: Object,
      default: null,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    entity(v, ov) {
      if (v !== ov) {
        // force recreate component to trigger focus when switch between fields with same renderers
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      }
    },
  },
};
</script>

<style lang="scss">
.editable-cell {
  display: flex;
  margin: -5px 0 -5.5px;

  &__content {
    flex: 1 0;
    align-self: center;
  }

  &__apply {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
  }

  .textinput,
  .strings-list,
  .input-integer,
  .attachment,
  .autocomplete {
    width: 220px;
  }
  .gallery {
    width: 230px;
  }
  .datepicker {
    width: 114px !important;
    &--with-time {
      width: 170px !important;
    }
  }
  .form-textarea {
    margin-top: 1px;
    width: 360px;
    height: 180px;
  }
  .editor {
    width: 360px;
    height: 180px;
  }
  .wysiwyg {
    width: 360px;
  }
  .ant-upload-list-item-card-actions {
    opacity: 1;
  }
  .input-enum {
    width: 220px;
  }
  .datepicker {
    margin-top: 1px;
  }
  .form-array-field,
  .form-array-field .textinput {
    width: 350px;
  }

  .colorpicker__picker {
    margin-top: -6px;
    margin-left: -16px;
  }
}
</style>
