<template>
  <div>
    <a-table
      :class="['table', { 'table--can-read': canReadModel }]"
      :dataSource="rows"
      :pagination="false"
      :customRow="customRow"
      @change="setTableParams"
    >
      <a-table-column
        v-for="column in columns"
        :key="column.name"
        :title="column.label"
        :sorter="column.sortable"
        :defaultSortOrder="column.defaultSortOrder"
        :data-index="column.index"
      >
        <template #default="value, entity">
          <template v-if="isInlineTable && column.name === 'actions'">
            <div class="table__actions">
              <a-icon
                class="table__form-icon"
                type="layout"
                @click="$emit('entityClick', entity.id)"
              />
            </div>
          </template>
          <table-custom-actions
            v-if="customActions.length > 0 && column.name === 'customActions'"
            :actions="customActions"
            :entity="entity"
          />
          <template v-else-if="column.name !== 'actions'">
            <table-cell
              :column="column"
              :value="value"
              :entity="entity"
              :canUpdateModel="canUpdateModel"
              @openInModal="(path) => $emit('openInModal', path)"
              @checkboxChanged="checkboxChanged"
              @click="editCell($event, entity, column)"
            />
          </template>
        </template>
      </a-table-column>
    </a-table>
    <a-popover
      :visible="editingCell.show"
      trigger="click"
      placement="bottomLeft"
      overlayClassName="table-edit-popover"
      :align="{
        target: editingCell.container,
        offset: editPopupOffset,
      }"
      @visibleChange="onEditPopoverVisibilityChange"
    >
      <template #content>
        <table-cell-editable
          v-if="editingCell.show"
          v-model="editingCell.value"
          :column="editingCell.column"
          :entity="editingCell.entity"
          @stopEdit="stopEditCell"
        />
      </template>
    </a-popover>
  </div>
</template>

<script>
import store from '@/store';
import { getFieldLabel, isSystemField, parseJson } from '@/helpers';
import CONFIG_QUERY from '@/queries/config';
import lockScroll from '@/components/base/lockScroll.mixin';
import scrollElementInView from '@/components/base/scrollElementInView.mixin';
import TableCell from './TableCell.vue';
import TableCellEditable from './TableCellEditable.vue';
import TableCustomActions from './TableCustomActions.vue';

const SORT_DIRECTION_API_MAPPING = {
  ascend: 'ASC',
  descend: 'DESC',
};

export default {
  name: 'TheTable',

  mixins: [lockScroll, scrollElementInView],

  components: {
    TableCell,
    TableCellEditable,
    TableCustomActions,
  },

  props: {
    config: {
      type: Object,
      required: true,
    },
    formConfig: {
      type: Object,
      default: null,
    },
    tableConfig: {
      type: Object,
      default: null,
    },
    defaultSort: {
      type: Array,
      required: true,
    },
    entityType: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      required: true,
    },
    entityFields: {
      type: Array,
      default: () => [],
    },
    canReadModel: {
      type: Boolean,
      default: false,
    },
    canUpdateModel: {
      type: Boolean,
      default: false,
    },
  },

  apollo: {
    sortableFields: {
      ...CONFIG_QUERY,
      fetchPolicy: 'cache-first',
      variables() {
        return {
          name: 'glob_SortableFields',
        };
      },
      update({ table }) {
        if (table?.totalCount) {
          return parseJson(table.documents[0].data.config);
        }
        return [];
      },
      error(error) {
        this.emitError(this.$t('app.error.sortableFieldsError'), error.message);
      },
    },
  },

  data() {
    return {
      editingCell: {
        show: false,
        entity: null,
        column: null,
        value: null,
        container: null,
      },
    };
  },

  computed: {
    desktop() {
      return store.state.isDesktop;
    },
    columns() {
      return this.prepareColumnsForAnt(this.entityFields, this.formConfig);
    },
    rows() {
      return this.prepareRowsDataForAnt(this.data.rows);
    },
    editPopupOffset() {
      const renderer = this.editingCell.column?.renderer;
      const rowHeight = 46;
      const padd = 10;
      let offset = [-28, -rowHeight + padd];
      if (renderer === 'color') offset = [-16, -rowHeight + padd];
      else if (['ref', 'refs'].includes(renderer)) offset = [-10, -rowHeight - 3 + padd];

      return offset;
    },
    customActions() {
      return store.state.activeSidebarItem?.customprops?.form?.actions || [];
    },
    isInlineTable() {
      return this.config.displayType.value === 'inline-table';
    },
  },

  methods: {
    customRow({ id, type }) {
      return {
        on: {
          click: () => {
            if (!this.isInlineTable) this.$emit('entityClick', id, type);
          },
        },
      };
    },

    canEditCell(column) {
      return (
        this.canUpdateModel &&
        this.isInlineTable &&
        !isSystemField(column) &&
        [
          'string',
          'integer',
          'date',
          'time',
          'date-time',
          'text',
          'wysiwyg',
          'image',
          'gallery',
          'color',
          'json',
          'attachment',
          'handbook',
          'ref',
          'refs',
          'ref2',
          'ref-like',
          'enum',
          'gpoint',
          'array',
        ].includes(column.renderer)
      );
    },

    isEditingCell({ id }, { name }) {
      return (
        this.editingCell.show &&
        this.editingCell.entity.id === id &&
        this.editingCell.column.name === name
      );
    },

    columnWidth(column) {
      return this.editingCell.column === column.name ? 330 : '';
    },

    async editCell(container, entity, column) {
      if (!this.isEditingCell(entity, column) && this.canEditCell(column)) {
        if (this.editingCell.show) {
          this.stopEditCell();
        }

        await this.scrollElementInView(container, this.getMarginsByRenderer(column.renderer));
        this.lockEntityFormScroll();

        this.editingCell = {
          show: true,
          entity,
          column,
          container,
          value: entity.data[column.name],
        };
      }
    },

    onEditPopoverVisibilityChange(visible) {
      if (!visible && this.editingCell.show) {
        this.stopEditCell();
      }
    },

    stopEditCell() {
      this.lockEntityFormScroll(false);

      const entity = this.rows.find((row) => row.id === this.editingCell.entity.id);
      entity.data[this.editingCell.column.name] = this.editingCell.value;
      this.$emit('updateEntity', entity.id, entity.data, {
        [this.editingCell.column]: this.editingCell.value,
      });

      this.editingCell = {
        show: false,
        entity: null,
        column: null,
        value: null,
        container: null,
      };
    },

    getCellEditPopupContainer() {
      return this.editingCell.container;
    },

    setTableParams(pagination, filters, sorter) {
      const { columnKey, order } = sorter;
      const direction = SORT_DIRECTION_API_MAPPING[order];
      let sortParams = [];

      if (direction) {
        sortParams = [
          {
            direction,
            field: columnKey,
          },
        ];
      }

      this.$emit('setSortParams', sortParams);
    },

    isColumnSortable({ sortable, renderer }) {
      return sortable !== undefined ? sortable : this.sortableFields?.includes(renderer);
    },

    getColumnSortOrder(column) {
      const sort = this.defaultSort.find((sortCol) => sortCol.field === column.field)?.direction;
      return sort ? { ASC: 'ascend', DESC: 'descend' }[sort] : null;
    },

    prepareColumnsForAnt(entityFields, formConfig) {
      const columns = this.config.displayFields
        .filter((column) => !column.hidden && !this.tableConfig?.columns[column.name]?.hidden)
        .map((column) => {
          const customParams = this.tableConfig?.columns[column.name];

          if (customParams?.hidden) return null;

          if (!customParams?.renderer) {
            const meta = entityFields.find((field) => column.name === field.name);
            column = {
              ...column,
              ...meta,
              label: getFieldLabel(
                formConfig?.locale[store.state.lang].fields,
                meta || column,
                store.state.lang,
              ),
            };

            column.index = column.index || `data.${column.name}`;
            column.sortable = this.isColumnSortable(column);
            column.defaultSortOrder = this.getColumnSortOrder(column);
          } else {
            const customColumn = {
              name: column.name,
              label: customParams.label,
              hidden: false,
              conf: null,
              defaultSortOrder: null,
              index: customParams.index,
              renderer: customParams.renderer,
              sortable: false,
              source: '$data.deleted',
            };

            if (customParams.renderer === 'param-link' && customParams.value) {
              customColumn.valueTemplate = customParams.value;
              customColumn.openInModal = customParams.openInModal;
              customColumn.openInNewTab = customParams.openInNewTab;
              customColumn.valueTitle = customParams.valueTitle;
              customColumn.value = (source, data) =>
                source.replaceAll(/{(.*?)}/g, (_match, key) => data[key]);

              return customColumn;
            }

            return null;
          }

          return column;
        })
        .filter((col) => !!col);

      if (this.isInlineTable) {
        if (this.customActions.length > 0) {
          columns.unshift({
            name: 'customActions',
            label: '',
          });
        }

        columns.push({
          name: 'actions',
          label: '',
        });
      }

      return columns;
    },

    prepareRowsDataForAnt(rows) {
      return rows.map((row, key) => ({ ...row, key, type: row.type || this.entityType }));
    },

    checkboxChanged({ target: { checked } }, entity, field) {
      entity.data[field] = checked;
      this.$emit('updateEntity', entity.id, entity.data, { [field]: checked });
    },
  },
};
</script>

<style lang="scss">
.table {
  color: $darkTextColor;

  .ant-table-row {
    height: 55px;
  }

  td {
    white-space: nowrap;
  }

  &--can-read {
    tbody tr {
      cursor: pointer;
    }
  }

  .ant-table-column-title {
    white-space: nowrap;
  }

  &__text {
    max-width: 300px;
    min-height: 1.6em;

    &--short {
      max-width: 50px;
    }
  }

  &__color-field {
    width: 122px;
    margin: -1px 0;
  }

  &__actions {
    visibility: hidden;
    text-align: right;
  }

  &__gallery {
    margin: -20px 0px;
    max-width: 300px;

    img {
      & + img {
        margin-left: 5px;
      }
    }
  }

  tr:hover > td > .table__actions {
    visibility: visible;
  }

  .ant-table-placeholder {
    display: none;
  }

  &.ant-table-wrapper {
    @include scrollbars();
    overflow-x: auto;
  }

  // For editable cells
  .ant-form-item {
    margin: 0;
  }
  .ant-form-item-control {
    line-height: 24px;
  }
}

.table-edit-popover {
  padding-top: 0;

  &:not(.table-edit-popover__no-icon) {
    .ant-popover-inner-content {
      padding-right: 2px;
    }
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-control {
    line-height: 32px;
  }
}
</style>
