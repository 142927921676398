<template>
  <div class="entity-config__params">
    <div
      v-for="paramKey in Object.keys(renderParams)"
      :key="paramKey"
      :class="[
        'entity-config__config entity-config__config--vertical',
        { 'entity-config__config--wide': isBooleanParam(paramKey) },
      ]"
    >
      <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
      <label v-if="!isBooleanParam(paramKey)">{{ paramLabel(paramKey) }}</label>
      <edit-form-boolean
        v-if="isBooleanParam(paramKey)"
        v-model="params[paramKey]"
        :config="{ label: paramLabel(paramKey) }"
        @input="fieldUpdated"
      />
      <a-input-number
        v-else-if="isNumberParam(paramKey)"
        v-model="params[paramKey]"
        :placeholder="$t('base.placeholder.value')"
        @change="fieldUpdated"
      />
      <a-select
        v-else-if="isEnumParam(paramKey)"
        v-model="params[paramKey]"
        :defaultActiveFirstOption="false"
        :options="enumOptions(paramKey)"
        :allowClear="renderParams[paramKey].allowClear"
        labelInValue
        @change="fieldUpdated"
      >
        <template #notFoundContent>
          {{ notFoundContent(paramKey) }}
        </template>
      </a-select>
      <a-input
        v-else
        v-model="params[paramKey]"
        :placeholder="$t('base.placeholder.value')"
        @change="fieldUpdated"
      />
    </div>
  </div>
</template>

<script>
import { FORM_PARAMS_RENDER_PARAMS } from '@/helpers';
import EditFormBoolean from '@/components/edit-form/fields/EditFormBoolean';

export default {
  name: 'EntityFormConfigParams',

  components: {
    EditFormBoolean,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
    fieldsMeta: {
      type: Array,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      renderParams: FORM_PARAMS_RENDER_PARAMS,
    };
  },

  computed: {
    params: {
      get() {
        return this.value;
      },
    },
  },

  methods: {
    isArray(field) {
      return Array.isArray(field);
    },

    isNameParam(fieldKey) {
      return fieldKey === 'name';
    },

    isParamHidden(param) {
      return this.hiddenParams.includes(param);
    },

    paramLabel(paramKey) {
      return this.$t(`entity.config.tab.form.${paramKey}`);
    },

    notFoundContent(paramKey) {
      const key = `entity.config.tab.form.${paramKey}_notFoundContent`;
      const value = this.$t(key);
      return value !== key ? value : this.$t(`entity.config.tab.form.notFoundContent`);
    },

    isBooleanParam(paramKey) {
      return FORM_PARAMS_RENDER_PARAMS[paramKey].renderer === 'checkbox';
    },

    isNumberParam(paramKey) {
      return FORM_PARAMS_RENDER_PARAMS[paramKey].renderer === 'number';
    },

    isEnumParam(paramKey) {
      return FORM_PARAMS_RENDER_PARAMS[paramKey].renderer === 'enum';
    },

    enumOptions(paramKey) {
      let options = FORM_PARAMS_RENDER_PARAMS[paramKey].options;
      if (typeof options === 'function') options = options(this.fieldsMeta, this.config.locale);
      return options;
    },

    fieldUpdated() {
      this.$emit('input', this.params);
    },
  },
};
</script>
