<template>
  <div :class="['json-compare', { 'm-error': !valid }]">
    <div class="json-compare__error"></div>
    <input-code-editor
      :value="currentData"
      :diffValue="savedData"
      :autoHeight="autoHeight"
      :config="{}"
      readOnly
      @input="dataUpdated"
    />
  </div>
</template>

<script>
import { parseJson } from '@/helpers';
import InputCodeEditor from '@/components/base/InputCodeEditor';

export default {
  name: 'EntityFormJsonCompare',

  components: {
    InputCodeEditor,
  },

  props: {
    rawSavedData: {
      type: Object,
      default: null,
    },
    rawCurrentData: {
      type: Object,
      default: null,
    },
    meta: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      valid: true,
      savedData: {},
      currentData: {},
    };
  },

  watch: {
    loading: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.savedData = {};
        }
      },
    },

    rawSavedData: {
      immediate: true,
      handler(value) {
        if (value) {
          this.savedData = this.prepareData(this.rawSavedData);
        }
      },
    },

    rawCurrentData: {
      immediate: true,
      handler(value) {
        if (value) {
          this.currentData = this.prepareData(this.rawCurrentData);
        }
      },
    },
  },

  methods: {
    prepareData(data) {
      data = this.parseNestedJson(data);
      data = this.sortObject(data);
      return JSON.stringify(data, null, 4);
    },

    parseNestedJson(data) {
      Object.keys(data).forEach((key) => {
        data[key] = this.parseNestedJsonField(
          data[key],
          this.meta.fields.find((field) => field.name === key),
        );
      });

      return data;
    },

    parseNestedJsonField(value, meta) {
      if (Array.isArray(value)) {
        return value.map((v) => this.parseNestedJsonField(v, meta));
      }

      if (meta?.renderer === 'json') {
        try {
          return parseJson(value);
        } catch (e) {
          return value;
        }
      }

      return value;
    },

    sortObject(data) {
      if (Array.isArray(data)) {
        return data.map(this.sortObject);
      }

      if (data && typeof data === 'object') {
        return Object.entries(data)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .reduce((acc, [key, value]) => {
            acc[key] = this.sortObject(value);
            return acc;
          }, {});
      }

      return data;
    },

    dataUpdated(value) {
      try {
        if (typeof value === 'string') {
          value = JSON.parse(value);
        }

        this.valid = true;
        this.$emit('dataUpdated', value);
      } catch (e) {
        this.valid = false;
      }
    },
  },
};
</script>

<style lang="scss">
.json-compare {
  position: relative;
  width: 100%;

  &__error {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 2px solid #aaa;
    z-index: 1;
    pointer-events: none;
  }

  &.m-error &__error {
    border-color: #e20;
  }
}
</style>
