import gql from 'graphql-tag';

export default {
  query: gql`
    query IntrospectionQuery {
      __schema {
        types {
          kind
          name
          enumValues(includeDeprecated: true) {
            name
            description
            isDeprecated
            #deprecationReason
          }
        }
      }
    }
  `,
  update({ __schema }) {
    return __schema.types.filter((type) => type.kind === 'ENUM' && !type.name.startsWith('__'));
  },
};
