import Vue from 'vue';
import store from '@/store';
import META_ACTIONS_QUERY from '@/queries/metaActions';
import BaseService from './BaseService';

class MetaService extends BaseService {
  constructor() {
    super();
    this.cachedMutations = Vue.observable({});
  }

  sendRequest(query, variables = {}) {
    return store.state.apolloClientOldApi.query({ query, variables });
  }

  async getEntityMutations(type, customForms) {
    return new Promise((resolve, reject) => {
      let mutations = this.cachedMutations[type];
      if (mutations) {
        resolve(mutations);
      } else {
        this.sendRequest(META_ACTIONS_QUERY.query, { mutationName: `${type}Mutation` })
          .then(({ data }) => {
            mutations = META_ACTIONS_QUERY.update(data, customForms);
            this.cachedMutations[type] = mutations;
            resolve(mutations);
          })
          .catch((error) => {
            const message = store.state.transProvider.$t('app.error.metaActions');
            this.emitError(message, error.message);
            reject(new Error(error));
          });
      }
    });
  }

  async getEntityActions(type, customForms, actionsList) {
    return new Promise((resolve, reject) => {
      this.getEntityMutations(type, customForms)
        .then((mutations) => {
          if (actionsList) {
            actionsList = Array.isArray(actionsList) ? actionsList : [actionsList];
            actionsList = actionsList.map((action) => ({ action }));
          } else {
            actionsList = customForms;
          }

          const actions = Object.entries(mutations)
            .filter(([key]) => actionsList.find((form) => form.action === key))
            .reduce((filtered, [key, item]) => {
              filtered[key] = item;
              return filtered;
            }, {});

          resolve(actions);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }
}

export default new MetaService();
