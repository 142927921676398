<template>
  <div
    ref="container"
    :class="['strings-list', { hasScrollbar }]"
    :style="listStyle"
  >
    <div
      v-for="(string, index) of items"
      :key="index"
      class="strings-list__item"
    >
      <a-input
        v-model="items[index]"
        :class="['textinput', { readOnly }]"
        :disabled="!readOnly && disabled"
      />
      <a-icon
        v-if="!disabled && !readOnly"
        class="strings-list__delete"
        type="minus"
        @click="deleteItem(index)"
      />
    </div>
    <div
      v-if="!disabled && !readOnly"
      class="strings-list__add"
    >
      <a
        href=""
        @click.prevent="addItem"
      >
        Добавить
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputStringList',

  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hasScrollbar: false,
    };
  },

  computed: {
    items: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    listStyle() {
      return {
        maxHeight: `${this.config.height || 180}px`,
      };
    },
  },

  watch: {
    items() {
      this.updateScrollbar();
    },
  },

  mounted() {
    this.updateScrollbar();
  },

  methods: {
    addItem() {
      this.items.push('');
    },

    deleteItem(index) {
      this.items.splice(index, 1);
    },

    updateScrollbar() {
      this.hasScrollbar = this.$refs.container.scrollHeight > this.$refs.container.clientHeight;
    },
  },
};
</script>

<style lang="scss">
.strings-list {
  padding-bottom: 10px;
  overflow-y: hidden;
  @include scrollbars();

  &.hasScrollbar {
    overflow-y: auto;
    padding-right: 12px;
  }

  &__item {
    position: relative;
    margin-bottom: 6px;

    .ant-input {
      padding-right: 30px;
    }

    .anticon {
      position: absolute;
      right: 3px;
      top: 3px;
      display: none;
      padding: 6px;
      cursor: pointer;
    }

    &:hover > .anticon,
    .ant-input:focus + .anticon {
      display: block;
    }
  }

  &__add {
    text-align: right;
    line-height: 20px;
  }
}
</style>
