import gql from 'graphql-tag';

export default {
  fetchPolicy: 'network-only',
  query: gql`
    query HistoryEntry($id: String!) {
      historyEntry(id: $id) {
        payload
      }
    }
  `,
  update: ({ historyEntry }) => historyEntry,
};
