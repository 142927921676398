export default function activatePolifills() {
  // Array.prototype.fill()
  if (!Array.prototype.fill) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Array.prototype, 'fill', {
      value(value, ...args) {
        if (this == null) {
          throw new TypeError('this is null or not defined');
        }

        const O = Object(this);
        const len = O.length >>> 0;

        const start = args[0];
        const relativeStart = start >> 0;

        let k = relativeStart < 0 ? Math.max(len + relativeStart, 0) : Math.min(relativeStart, len);
        const end = args[1];
        const relativeEnd = end === undefined ? len : end >> 0;

        const final = relativeEnd < 0 ? Math.max(len + relativeEnd, 0) : Math.min(relativeEnd, len);

        while (k < final) {
          O[k] = value;
          k++;
        }

        return O;
      },
    });
  }

  if (!('path' in MouseEvent.prototype)) {
    Object.defineProperty(Event.prototype, 'path', {
      get() {
        var path = [];
        var currentElem = this.target;
        while (currentElem) {
          path.push(currentElem);
          currentElem = currentElem.parentElement;
        }
        if (path.indexOf(window) === -1 && path.indexOf(document) === -1) path.push(document);
        if (path.indexOf(window) === -1) path.push(window);
        return path;
      },
    });
  }

  function setRemovePolyfill() {
    const elemsToCheck = [window.Element, window.CharacterData, window.DocumentType];
    const prototypesToCheck = [];

    elemsToCheck.forEach((item) => {
      if (item) {
        prototypesToCheck.push(item.prototype);
      }
    });

    prototypesToCheck.forEach((item) => {
      if (!Object.prototype.hasOwnProperty.call(item, 'remove')) {
        Object.defineProperty(item, 'remove', {
          configurable: true,
          enumerable: true,
          writable: true,
          value: function remove() {
            this.parentNode.removeChild(this);
          },
        });
      }
    });
  }
  setRemovePolyfill();
}
