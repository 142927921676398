<template>
  <input-enum
    v-model="input"
    :config="config"
    :size="size"
  />
</template>

<script>
import InputEnum from '@/components/base/InputEnum.vue';

export default {
  name: 'FilterElementEnum',
  components: { InputEnum },
  props: {
    config: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      value: undefined,
    };
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.value = value;
        this.$emit('input', value);
      },
    },
  },
};
</script>
