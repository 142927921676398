<template>
  <a-radio-group
    v-model="value"
    :name="config.name"
    @change="onChange"
  >
    <a-radio
      v-for="option of config.options"
      :key="option.value"
      class="filter-radio"
      :value="option.value"
    >
      {{ option.title || option.value }}
    </a-radio>
  </a-radio-group>
</template>

<script>
export default {
  name: 'FilterElementRadio',
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: undefined,
    };
  },
  methods: {
    onChange(value) {
      this.value = value;
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss">
.filter-radio {
  line-height: 24px;

  & + & {
    margin-left: 25px;
  }
}
</style>
