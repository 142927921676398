<template>
  <div :class="['edit-form-checkbox', 'checkbox-wrap', { readOnly }]">
    <error-output :errors="errors">
      <a-checkbox
        v-model="input"
        :disabled="disabled || readOnly"
      >
        <!-- @change="$emit('input', input)" -->
        <a-tooltip placement="bottomLeft">
          <template
            v-if="config.name"
            slot="title"
          >
            {{ config.name }}
          </template>
          <component :is="labelTag">{{ config.label }}</component>
        </a-tooltip>
        <a-tooltip
          v-if="config.info"
          placement="bottomLeft"
          class="edit-form-checkbox__info"
        >
          <template slot="title">{{ config.info }}</template>
          <a-icon type="question-circle" />
        </a-tooltip>
      </a-checkbox>
    </error-output>
  </div>
</template>

<script>
import ErrorOutput from '@/components/base/ErrorOutput';

export default {
  name: 'EditFormBoolean',
  components: {
    ErrorOutput,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    labelTag: {
      type: String,
      default: 'h3',
    },
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);

        if (this.config.action) {
          this.$nextTick(() => {
            this.$emit('buttonClick', this.config);
          });
        }
      },
    },
  },
};
</script>

<style lang="scss">
.edit-form-checkbox {
  h3,
  h4 {
    display: inline;
    position: relative;
    top: 1px;
  }

  &:not(.readOnly) {
    .ant-checkbox-wrapper-disabled h3 {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  &__info {
    margin-left: 5px;
    padding: 4px;
    opacity: 0.5;
  }
}

.checkbox-wrap {
  &.readOnly {
    .ant-checkbox-wrapper-disabled,
    .ant-checkbox-input,
    .ant-checkbox-disabled,
    .ant-checkbox-disabled + span {
      cursor: default;
    }

    .ant-checkbox-disabled + span {
      cursor: text;
      color: inherit;
    }
  }
}
</style>
