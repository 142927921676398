<template>
  <a-select
    v-model="input"
    class="input-autocomplete"
    :size="size"
    :filterOption="false"
    :defaultActiveFirstOption="false"
    allowClear
    showSearch
    labelInValue
    :placeholder="$t('base.placeholder.select')"
    :notFoundContent="loading ? undefined : null"
    :options="options"
    :disabled="disabled"
    @search="onSearch"
  >
    <a-spin
      v-if="loading"
      slot="notFoundContent"
      size="small"
    />
  </a-select>
</template>

<script>
import { appSettings } from '@/AppSettings';
import SEARCH_QUERY from '@/queries/search';

export default {
  name: 'FilterElementAutocompleteRef2',

  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      default: undefined,
    },
    size: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  apollo: {
    autocomplete: {
      ...SEARCH_QUERY,
      variables() {
        return {
          type: this.type,
          value: this.search,
        };
      },
    },
  },

  data() {
    return {
      search: '',
    };
  },

  computed: {
    type() {
      return this.config.types.filter(
        (type) => !type.startsWith(appSettings.get('systemFieldsPrefix')),
      )[0];
    },

    options() {
      return this.autocomplete?.map((option) => ({
        title: this.getOptionLabel(option.title, this.config),
        value: `${this.type}:${option.value}`,
      }));
    },

    input: {
      get() {
        return this.value
          ? { key: this.value.value, label: this.getOptionLabel(this.value.title, this.config) }
          : undefined;
      },
      set(option) {
        option = option || undefined;
        this.$emit(
          'input',
          option && {
            value: option.key,
            title: option.label,
          },
        );
      },
    },

    loading() {
      return this.$apollo.queries.autocomplete.loading;
    },
  },

  methods: {
    onSearch(value) {
      this.search = value;
    },

    getOptionLabel(value, { config }) {
      let display = value;
      const type = config?.url?.replace(/.*?\/handbooks\/(.*?)\/.*/, '$1');
      if (type) {
        const transKey = `handbooks.${type}.${value}`;
        display = this.$t(transKey);
        if (display === transKey) display = value;
      }

      return display;
    },
  },
};
</script>

<style lang="scss">
.input-autocomplete {
  width: 100%;
}
</style>
