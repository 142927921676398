<template>
  <div>
    <edit-form-embed
      v-model="data"
      :config="config"
      :parentType="parentType"
      :disabled="disabled"
      :readOnly="readOnly"
      inlineFormControls
    />
  </div>
</template>

<script>
import EditFormEmbed from './embed/EditFormEmbed';

export default {
  name: 'EditFormProcessFunctions',

  components: {
    EditFormEmbed,
  },

  props: {
    parentType: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: [Array, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    data: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
