<template>
  <div :style="previewStyle">
    <h1 class="action-form-preview-title">{{ previewTitle }}</h1>
    <kanban-card
      v-if="fieldsToDisplay"
      :fieldsToDisplay="fieldsToDisplay"
      :card="data"
      readOnly
      showTitles
    />
  </div>
</template>

<script>
import store from '@/store';
import { getFieldLabel } from '@/helpers';
import KanbanCard from '@/components/page/kanban/KanbanCard.vue';

export default {
  name: 'ActionFormPreview',
  components: {
    KanbanCard,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    formConfig: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    width: {
      type: [String, Number],
      default: '400px',
    },
  },
  computed: {
    fieldsToDisplay() {
      return store.state.meta.components[this.type].fields.map((field) => ({
        ...field,
        label: getFieldLabel(
          this.formConfig.locale[store.state.lang]?.fields,
          field,
          store.state.lang,
        ),
      }));
    },
    previewTitle() {
      return this.formConfig.locale[store.state.lang]?.entity || this.type;
    },
    previewStyle() {
      const width = typeof this.width === 'string' ? this.width : `${this.width}px`;
      return {
        flex: `0 0 ${width}`,
      };
    },
  },
};
</script>

<style lang="scss">
.action-form-preview-title {
  padding: 0 16px;
  font-weight: 700;
}
</style>
