<template>
  <div class="gantt">
    <div
      ref="gantt"
      class="gantt__container"
    ></div>
  </div>
</template>

<script>
import { gantt } from 'dhtmlx-gantt';
import store from '@/store';
import EntityService from '@/services/EntityService';

export default {
  name: 'TheGantt',
  props: {
    entityType: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    formConfig: {
      type: Object,
      default: null,
    },
    data: {
      type: Object,
      required: true,
    },
    entityFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      ganttConfig: {
        xml_date: '%Y-%m-%d',
        drag_progress: false,
        select_task: false,
        drag_links: false,
        details_on_dblclick: false,
        columns: [
          {
            name: 'text',
            label: this.getColumnLabel(this.data.rows[0]?.snapshot || 'title'),
            tree: false,
            width: '*',
            align: 'left',
          },
          {
            name: 'start_date',
            label: this.getColumnLabel(this.config.displayType.options.startDate),
            width: '90',
            align: 'center',
          },
          {
            name: 'end_date',
            label: this.getColumnLabel(this.config.displayType.options.endDate),
            width: '90',
            align: 'center',
          },
        ],
        scales: [
          { unit: 'month', step: 1, format: '%F, %Y' },
          {
            unit: 'day',
            step: 1,
            format: '%d, %D',
          },
        ],
      },
    };
  },
  computed: {
    dragEnabled() {
      return store.state.isDesktop;
    },
    displayOptions() {
      return this.config.displayType.options;
    },
    ganttRows() {
      const data = this.data.rows
        .filter(
          (item) =>
            item.data[this.config.displayType.options.startDate] &&
            item.data[this.config.displayType.options.endDate],
        )
        .map((item) => {
          const start = new Date(item.data[this.config.displayType.options.startDate]);
          const end = new Date(item.data[this.config.displayType.options.endDate]);
          return {
            id: item.id,
            text: item.data[item.snapshot || item.title],
            start_date: `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
            end_date: `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
          };
        });

      return {
        data,
        links: [],
      };
    },
  },
  watch: {
    ganttRows() {
      this.ganttUpdateRows();
    },
  },
  created() {
    gantt.i18n.setLocale('ru');
    Object.assign(gantt.config, this.ganttConfig);

    gantt.attachEvent('onBeforeTaskUpdate', (id, task) => {
      this.updateEventDates(task);
      return false;
    });

    gantt.attachEvent('onTaskClick', (id) => {
      this.$emit('entityClick', id);
      return false;
    });
  },
  mounted() {
    gantt.init(this.$refs.gantt);
  },
  methods: {
    ganttUpdateRows() {
      gantt.clearAll();
      gantt.parse(this.ganttRows);
    },

    getColumnLabel(field) {
      return this.formConfig?.locale[store.state.lang].fields[field]?.label || field;
    },

    updateEventDates(task) {
      const data = this.data.rows.find((row) => row.id === task.id).data;
      data[this.config.displayType.options.startDate] = +task.start_date;
      data[this.config.displayType.options.endDate] = +task.end_date;

      EntityService.update(this.entityFields, {
        id: task.id,
        type: this.entityType,
        data,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~dhtmlx-gantt/codebase/dhtmlxgantt.css';
.gantt {
  position: relative;
  height: 500px;

  &__container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .gantt_task_line {
    background-color: #1890ff;
    border: 1px solid #1377d4;
  }
}
</style>
