<template>
  <div class="audio">
    <!-- eslint-disable vuejs-accessibility/media-has-caption -->
    <audio
      controls
      :src="path"
    >
      {{ $t('noEmbeddedAudioSupport.message') }}
    </audio>
  </div>
</template>

<script>
export default {
  name: 'BaseAudio',
  props: {
    path: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
