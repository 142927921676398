<script>
import store from '@/store';
import { getFieldLabel, getFieldInfo } from '@/helpers';

export default {
  name: 'FormLayoutProvider',

  props: {
    fieldsMeta: {
      type: Array,
      required: true,
    },
    formConfig: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formLayout() {
      return this.prepareFormLayout(this.formConfig, this.fieldsMeta);
    },
  },

  methods: {
    prepareFormLayout(formConfig, fieldsMeta) {
      let tabs = formConfig.tabs;
      tabs = tabs.map((tab) => ({
        ...tab,
        columns: (() => {
          const cols = tab.columns
            .map((column) => this.prepareFormColumnLayout(column, formConfig, fieldsMeta))
            .filter((column) => column.length);

          return cols;
        })(),
      }));

      return { tabs };
    },

    prepareFormColumnLayout(columnFieldsList, formConfig, fieldsMeta) {
      const column = columnFieldsList.map((config) => {
        const meta = fieldsMeta.find((desc) => desc.name === config.name);
        return {
          ...meta,
          ...config,
          label: getFieldLabel(formConfig.locale[store.state.lang].fields, meta, store.state.lang),
          info: getFieldInfo(formConfig.locale[store.state.lang].fields, meta),
        };
      });

      return column;
    },
  },

  render() {
    return this.$scopedSlots.default({
      formLayout: this.formLayout,
    });
  },
};
</script>
