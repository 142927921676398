import gql from 'graphql-tag';

export default function customMutation(name, type, fields) {
  return gql`
    query {
      ${type}Query {
        ${name}(${Object.entries(fields)
    .map(([field, value]) => `${field}: "${value}"`)
    .join(', ')})
      }
    }
  `;
}
