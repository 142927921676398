import { Empty } from 'ant-design-vue';
import { prepareKanbanCardLayout } from '@/helpers';
import KanbanCard from './KanbanCard.vue';

export default {
  components: {
    KanbanCard,
  },

  props: {
    entityType: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    entityFields: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canReadModel: {
      type: Boolean,
      default: false,
    },
    canUpdateModel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
    };
  },

  computed: {
    noData() {
      return this.data.rows.length === 0;
    },
    cards: {
      get() {
        return this.data.rows;
      },
      set(value) {
        this.$emit('updateRows', value);
      },
    },
    fieldsToDisplay() {
      return prepareKanbanCardLayout(this.entityFields, this.config.displayFields);
    },
  },

  methods: {
    editEntity(id) {
      this.$emit('entityClick', id);
    },
    updateEntity(data, updatedData) {
      this.$emit('updateEntity', data, updatedData);
    },
  },
};
