<template>
  <div class="lada-items-moderation">
    <template v-for="document of data.rows">
      <lada-new-item-card
        v-if="document.data.status === 'NEW'"
        :key="document.id"
        :data="document"
        :entityFields="entityFields"
        class="lada-items__item"
        @updateStatus="updateStatus"
      />
    </template>
  </div>
</template>

<script>
import UPDATE_MUTATION from '@/queries/mutations/update';
import { bus } from '@/helpers/bus';
import LadaNewItemCard from './LadaNewItemCard.vue';

export default {
  name: 'LadaItems',

  components: {
    LadaNewItemCard,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    entityFields: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    updateStatus(id, status, newData) {
      this.$apollo
        .mutate({
          mutation: UPDATE_MUTATION,
          variables: {
            id,
            type: 'NewItem',
            data: { ...newData, status },
          },
        })
        .then(() => {
          bus.$emit('refetchTable');
          this.$notification.success({
            message: this.$t('customForm.saveSuccess'),
          });
        })
        .catch((error) => {
          this.$notification.error({
            message: this.$t('customForm.saveError'),
            description: error.message,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.lada-items {
  &__item {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
</style>
