<template>
  <a-table
    class="base-table"
    :data-source="sortedData"
    :size="size"
    rowKey="id"
    :pagination="{
      hideOnSinglePage: true,
      pageSize: 10,
    }"
  >
    <a-table-column
      v-for="column in columns"
      :key="column.name"
      :title="column.label || column.name"
      :data-index="column.source.replace(/^\$/, '')"
    >
      <template #default="colValue">
        <template v-if="column.renderer === 'date-time'">
          {{ formatDateTime(colValue) }}
        </template>

        <template v-else-if="column.renderer === 'date'">
          {{ formatDate(colValue) }}
        </template>

        <template v-else-if="column.renderer === 'time'">
          {{ formatTime(colValue) }}
        </template>

        <template v-else>
          {{ colValue }}
        </template>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import { XHR, parseJson, formatDateTime, formatDate, formatTime } from '@/helpers';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'middle',
    },
    config: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      formMeta: null,
    };
  },

  computed: {
    columns() {
      return this.formMeta?.fields || [];
    },

    sortedData() {
      const data = this.value ? [...this.value] : [];
      this.columns?.forEach(({ source, sortOrder }) => {
        const field = source.replace(/^\$/, '');
        data.sort((a, b) => this.sorter(a, b, sortOrder, field));
      });

      return data;
    },
  },

  created() {
    this.loadMeta();
  },

  methods: {
    formatDateTime,
    formatDate,
    formatTime,

    async loadMeta() {
      const response = await XHR.get(`/forms/${this.config.conf?.subform}`);
      this.formMeta = parseJson(response);
    },

    sorter(a, b, direction, name) {
      if (direction === 'ascend') return a[name] - b[name];
      if (direction === 'descend') return b[name] - a[name];
      return 0;
    },
  },
};
</script>
