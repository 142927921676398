<template>
  <entity-modal
    v-if="lastOrderNum !== undefined"
    :id="id"
    :type="entityType"
    :fullscreen="fullscreen"
    :dataProcessor="dataProcessor"
    :contentVisible="contentVisible"
    @editFullEntity="reemit('switchFullscreenModal', arguments)"
    @editEntity="reemit('editEntity', arguments)"
    @close="reemit('closeModalEditing', arguments)"
  />
</template>

<script>
import moment from 'moment';
import store from '@/store';
import TABLE_QUERY from '@/queries/table';
import EntityModal from '@/components/edit-form/EntityModal.vue';

export default {
  name: 'SoB2bOrder',

  components: {
    EntityModal,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    fullscreen: {
      type: Boolean,
      required: true,
    },
    contentVisible: {
      type: Boolean,
      required: true,
    },
  },

  apollo: {
    lastOrderNum: {
      ...TABLE_QUERY,
      loadingKey: 'loadingQueries',
      debounce: 250,
      variables() {
        return {
          type: this.entityType,
          page: 0,
          pageSize: 1,
          orders: [
            {
              direction: 'DESC',
              field: 'orderNum',
            },
          ],
        };
      },
      update({ table }) {
        return table.documents[0]?.data.orderNum || 0;
      },
    },
  },

  computed: {
    entityType() {
      return store.state.activeSidebarItem.code;
    },
    isDraft() {
      return this.id.startsWith('_temp');
    },
  },

  methods: {
    /**
     * parseInt потому что иногда с бека прилетали строки. Не каст в Number потмоу что мало ли прилетит что-то ещё.
     */
    dataProcessor(data) {
      if (this.isDraft && data) {
        let orderNum = parseInt(data.orderNum);
        if (Number.isNaN(orderNum)) {
          orderNum = parseInt(this.lastOrderNum) + 1;
        }

        data.orderNum = orderNum;
        data.createdAt = data.createdAt || moment().valueOf();
        data.status = data.status || 'created';
      }

      return data;
    },
  },
};
</script>
