<template>
  <div class="publishable-history">
    <div class="publishable-history__wrapper">
      <div class="publishable-history__list">
        <div
          v-if="!history.length"
          class="publishable-history__entry publishable-history__empty-msg"
        >
          {{ $t('entity.emptyHistory') }}
        </div>

        <div
          v-for="entry of history"
          :key="entry.j_version"
          :class="[
            'publishable-history__entry',
            { 'publishable-history__entry--active': selectedVersion === entry.j_version },
          ]"
          @click="$emit('selectVersion', entry)"
        >
          <div>
            {{ formatDateTime(entry.j_created_at, 'DD.MM.yyyy hh:mm:ss') }}

            <a-tooltip
              v-if="entry.j_user"
              :title="entry.j_user"
              :getPopupContainer="getPopupContainer"
            >
              <a-icon
                class="publishable-history__icon"
                type="user"
              />
            </a-tooltip>

            <a-tooltip
              v-if="currentVersion === entry.j_version && published"
              :title="$t('history.currentVersion')"
              :getPopupContainer="getPopupContainer"
            >
              <a-icon
                class="publishable-history__icon"
                type="check-square"
              />
            </a-tooltip>

            <a-tooltip
              v-if="entry.j_needs_review"
              :title="$t('history.needsRevies')"
              :getPopupContainer="getPopupContainer"
            >
              <a-icon
                class="publishable-history__icon publishable-history__icon--review"
                type="exclamation-circle"
              />
            </a-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from '@/helpers';

export default {
  props: {
    selectedVersion: {
      type: String,
      default: null,
    },
    currentVersion: {
      type: String,
      default: null,
    },
    history: {
      type: Array,
      required: true,
    },
    published: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    formatDateTime,

    getPopupContainer() {
      return document.querySelector('.publishable');
    },
  },
};
</script>

<style lang="scss">
.publishable-history {
  position: relative;
  margin-top: -5px;
  margin-left: -5px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__list {
    @include scrollbars();
    overflow-y: scroll;
    height: 100%;
  }

  &__entry {
    z-index: 2;
    position: relative;
    margin-top: 0 !important;
    padding: 5px 5px;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-color: transparent #f9f9f9 transparent transparent;
    line-height: 16px;
    cursor: pointer;

    &--active {
      border-top-color: #e0e0e0;
      border-bottom-color: #e0e0e0;
      color: #40a9ff;
    }
  }

  &__empty-msg {
    color: #a0a0a0;
    cursor: initial;
  }

  &__icon {
    margin-left: 5px;
  }

  &__icon:not(.publishable-history__icon--review) {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  &__icon--review {
    color: #f08000 !important;
  }
}
</style>
