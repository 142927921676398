<template>
  <div class="kanban">
    <div class="kanban__scroll-wrap">
      <div class="kanban__container">
        <div
          v-for="(column, key) in columnsCards"
          :key="key"
          class="kanban__column"
        >
          <h3
            v-dragscroll.pass="{ container: '.kanban__scroll-wrap' }"
            class="kanban__column-title ellipsis unselectable"
          >
            {{ column.label }}
          </h3>

          <div class="kanban__column-items">
            <draggable-list
              v-model="column.cards"
              v-dragscroll:nochilddrag.pass="{ container: '.kanban__scroll-wrap' }"
              :group="draggableGroupUid"
              :disabled="!desktop && !disabled"
              @change="moveCard($event, key, column.label)"
            >
              <kanban-card
                v-for="card in column.cards"
                :key="card.id"
                :fieldsToDisplay="fieldsToDisplay"
                :card="card"
                :disabled="disabled"
                :canReadModel="canReadModel"
                :canUpdateModel="canUpdateModel"
                @editEntity="editEntity"
                @updateEntity="updateEntity"
              />
            </draggable-list>
          </div>
        </div>
      </div>
      <a-empty
        v-if="noData"
        :image="emptyImage"
      />
    </div>
  </div>
</template>

<script>
import { dragscroll } from 'vue-dragscroll';
import store from '@/store';
import { uniqueId } from '@/helpers';
import EntityService from '@/services/EntityService';
import DraggableList from '@/components/base/DraggableList.vue';
import KanbanCardContainer from './KanbanCardContainer.mixin.js';

export default {
  name: 'TheKanban',

  mixins: [KanbanCardContainer],

  components: {
    DraggableList,
  },

  directives: {
    dragscroll,
  },

  data() {
    return {
      draggableGroupUid: uniqueId(),
    };
  },

  computed: {
    desktop() {
      return store.state.isDesktop;
    },
    columnsField() {
      return this.config.displayType.options.columnsField;
    },
    columns() {
      return this.columnsField.options.columns.filter((col) => !col.hidden);
    },
    columnsCards() {
      if (this.columnsField === undefined) return {};

      const columnsField = this.columnsField.value;
      const columnsDict = {};
      const looseElements = this.cards.filter((item) => !item.data[columnsField]);
      if (looseElements.length) {
        columnsDict.__empty = {
          label: this.$t(`kanban.emptyColumn`),
          cards: looseElements,
        };
      }

      this.columns.reduce((sorted, column) => {
        sorted[column.field] = {
          label: column.label,
          cards: this.cards.filter((item) => item.data[columnsField]?.value === column.field),
        };
        return sorted;
      }, columnsDict);

      return columnsDict;
    },
  },

  methods: {
    moveCard({ added }, key, title) {
      if (added) {
        const { element: gqlDocument } = added;
        const entity = { ...gqlDocument.data };
        if (key === '__empty') {
          entity[this.columnsField.value] = null;
        } else {
          entity[this.columnsField.value] = {
            title,
            value: key,
          };
        }

        const rowIndex = this.cards.findIndex(({ id }) => id === gqlDocument.id);
        this.cards[rowIndex].data = entity;

        EntityService.update(this.entityFields, {
          id: gqlDocument.id,
          type: this.entityType,
          data: entity,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.kanban {
  position: relative;
  z-index: 1;

  &__scroll-wrap {
    margin: 0 -8px;
    padding-bottom: 32px;
    overflow-y: auto;
    @include scrollbars();
  }

  &:before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    height: 52px;
    background: #fafafa;
    border-bottom: 1px solid #e8e8e8;
    content: '';
  }

  &__container {
    display: flex;
  }

  &__column {
    width: 65%;
    max-width: 286px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  }

  &__column-title {
    font-weight: 500;
    font-size: 14px;
    padding: 16px 24px;
  }

  &__column-items {
    margin: 16px 8px 0;
    flex-grow: 1;
    display: flex;
    align-items: stretch;

    & > div {
      width: 100%;

      & > span {
        min-height: 100%;
        display: block;
      }
    }
  }
}
</style>
