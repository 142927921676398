<template>
  <div class="form-array-field">
    <div class="form-array-field__input">
      <a-input
        ref="focusOnMount"
        v-model="inputValue"
        autocomplete="off"
        :class="['textinput', { readOnly }]"
        :disabled="!readOnly && disabled"
        :readOnly="readOnly"
        :size="size"
        @keypress="onKeypress"
      />
    </div>
    <template v-for="tag in input">
      <a-tag
        :key="tag"
        class="ellipsis"
        :closable="removable"
        @close="handleClose(tag)"
      >
        {{ tag }}
      </a-tag>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      inputValue: '',
    };
  },
  computed: {
    input: {
      get() {
        return this.value || [];
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    removable() {
      return !this.readOnly && !this.disabled;
    },
  },
  methods: {
    handleClose(removedTag) {
      this.input = this.input.filter((tag) => tag !== removedTag);
    },

    onKeypress(event) {
      const charCode = event.which || event.keyCode;
      if (charCode === 13) {
        this.input = this.input.concat(this.inputValue);
        this.inputValue = '';
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
.form-array-field {
  &__input {
    margin-bottom: 6px;
  }

  .ant-tag {
    position: relative;
    max-width: 200px;
    margin-top: 4px;
    padding: 1px 22px 0px 4px;

    .anticon {
      position: absolute;
      top: 6px;
      right: 4px;

      &:hover {
        color: #1890ff;
      }
    }
  }
}
</style>
