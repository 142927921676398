<template>
  <div class="editor-json">
    <input-code-editor
      v-model="rows"
      :config="config"
      :disabled="disabled"
      :readOnly="readOnly"
      :focusOnMount="focusOnMount"
      language="sql"
    />
  </div>
</template>

<script>
import InputCodeEditor from '@/components/base/InputCodeEditor';

export default {
  name: 'EditFormSqlEditor',
  components: {
    InputCodeEditor,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    focusOnMount: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rows: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss">
.editor-json {
  width: 100%;
}
</style>
