<template>
  <error-output :errors="errors">
    <div ref="wrapper">
      <div
        v-if="displayMode === 'autocomplete'"
        class="input-enum"
      >
        <a-select
          ref="focusOnMount"
          v-model="input"
          :class="['input-enum__select', { readOnly }]"
          :placeholder="placeholder"
          :disabled="disabled || readOnly"
          :getPopupContainer="getPopupContainer"
          :size="size"
          dropdownClassName="input-enum__dropdown"
          allowClear
        >
          <a-select-option
            v-for="option of options"
            :key="option"
          >
            {{ option }}
          </a-select-option>
        </a-select>
      </div>
      <a-checkbox-group
        v-else-if="displayMode === 'checkboxes'"
        v-model="input"
        :disabled="disabled || readOnly"
        :class="['checkboxes readOnly', { readOnly }]"
        :options="options"
      />
    </div>
  </error-output>
</template>

<script>
import ENUMS_LIST_QUERY from '@/queries/enumsList';
import ErrorOutput from '@/components/base/ErrorOutput';
import FocusOnMountMixin from '@/components/base/focusOnMount.mixin';

export default {
  name: 'EditFormEnum',
  mixins: [FocusOnMountMixin],
  components: {
    ErrorOutput,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  apollo: {
    enumsList: {
      ...ENUMS_LIST_QUERY,
    },
  },
  data() {
    return {
      search: '',
      focused: false,
    };
  },
  computed: {
    input: {
      get() {
        let value = this.value;
        if (this.displayMode === 'checkboxes' && value) value = [value];
        return value || undefined;
      },
      set(value) {
        if (this.displayMode === 'checkboxes' && value) {
          value = value.filter((v) => !this.input.includes(v))[0] || null;
        }

        this.$emit('input', value);
      },
    },
    placeholder() {
      return this.disabled || this.readOnly ? '' : this.$t(`base.placeholder.select`);
    },
    enumTypes() {
      return this.config.types;
    },
    options() {
      let list = this.enumsList?.filter(({ name }) => this.enumTypes.includes(name));

      if (list) list = list.map(({ enumValues }) => enumValues).flat();
      return list?.map(({ name }) => name) || [];
    },
    displayMode() {
      return this.config.enumDisplay.key;
    },
  },
  methods: {
    async setFocus() {
      if (this.displayMode !== 'autocomplete') return;
      this.$refs.focusOnMount.$el.click();
    },

    getPopupContainer() {
      return this.$refs.wrapper;
    },
  },
};
</script>

<style lang="scss">
.input-enum {
  position: relative;

  &__dropdown.ant-select-dropdown {
    top: 32px !important;

    .ant-select-dropdown-menu {
      max-height: 5 * 32px;
    }
  }
}
</style>
