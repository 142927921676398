<template>
  <div class="entity-config-field">
    <div class="entity-config__line">
      <div class="entity-config__header top unselectable">
        <a-icon
          class="entity-config__icon drag"
          type="drag"
        />
        <h4 class="ellipsis">
          {{ label }}
        </h4>
        <a-icon
          v-if="hasVisibleParamsFields"
          class="entity-config__icon"
          type="setting"
          @click="$emit('editFieldParams', field)"
        />
        <a-icon
          class="entity-config__icon"
          :type="fieldStatus"
          @click.stop="toggleHidden"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EntityFormConfigField',

  props: {
    value: {
      type: [Object, Array, String],
      default: () => ({}),
    },
    label: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      field: { field: '' },
      hiddenParams: ['name', 'field', 'hidden', 'label'],
    };
  },

  computed: {
    fieldStatus() {
      return this.field.hidden ? 'eye-invisible' : 'eye';
    },

    hasVisibleParamsFields() {
      const paramsKeys = Object.keys(this.field);
      return paramsKeys.filter((key) => !this.hiddenParams.includes(key)).length > 0;
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value, oldValue) {
        this.field = value;
        if (oldValue) this.$emit('paramsUpdated');
      },
    },
  },

  methods: {
    isNameParam(fieldKey) {
      return fieldKey === 'name';
    },

    toggleHidden() {
      const field = {
        ...this.field,
        hidden: !this.field.hidden,
      };

      this.$emit('fieldUpdated', field);
    },
  },
};
</script>
