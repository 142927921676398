<template>
  <a-timeline
    v-if="users"
    class="auro-release-history"
  >
    <a-timeline-item
      v-for="(entry, index) in history"
      :key="index"
      class="auro-release-history__item"
    >
      <p
        v-if="isEntryChangeStatus(entry, index)"
        class="auro-release-history__status"
      >
        {{ $t('aurora.statusChanged', { status: entry.payload.status }) }}
      </p>
      <blockquote v-if="entry.payload.comment">{{ entry.payload.comment }}</blockquote>
      <p class="auro-release-history__user">
        <b>{{ users[entry.userId] || entry.userId || '' }}</b>
        {{ formatDateTime(entry.date) }}
      </p>
    </a-timeline-item>
    <a-timeline-item
      v-if="history && history.length > 0"
      key="'created'"
      class="auro-release-history__item"
    >
      {{ $t('aurora.historyCreated', { date: formatDateTime(history[0].payload.created) }) }}
    </a-timeline-item>
  </a-timeline>
</template>

<script>
import { formatDateTime } from '@/helpers';
import HISTORY_QUERY from '@/queries/historySOCourse';
import TABLE_QUERY from '@/queries/table';

export default {
  name: 'AuroReleaseHistory',
  components: {},
  props: {
    release: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    history: {
      ...HISTORY_QUERY,
      loadingKey: 'loading',
      fetchPolicy: 'network-only',
      variables() {
        return {
          entityName: 'Release',
          entityId: this.release.id,
        };
      },
      update({ history }) {
        return history && history.entries.sort((a, b) => b.date - a.date);
      },
      error(error) {
        this.emitError(this.$t('history.error.getList'), error.message);
      },
    },
    users: {
      ...TABLE_QUERY,
      loadingKey: 'loading',
      fetchPolicy: 'network-only',
      variables: {
        type: 'User',
        page: 0,
        pageSize: 999,
      },
      update({ table }) {
        return table.documents.reduce((users, el) => {
          users[el.id] = el.data.username;
          return users;
        }, {});
      },
      error(error) {
        this.emitError(this.$t('entity.error.getTable'), error.message);
      },
    },
  },
  watch: {
    release() {
      this.$apollo.queries.history.refetch();
    },
  },
  methods: {
    formatDateTime,

    isEntryChangeStatus(entry, index) {
      return (
        index < this.history.length - 1 &&
        entry.payload.status &&
        this.history[index + 1].payload.status !== entry.payload.status
      );
    },
  },
};
</script>

<style lang="scss">
.auro-release-history {
  margin-top: 25px;
  max-height: 400px;
  overflow-y: auto;
  @include scrollbars();

  &__h2 {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }

  &__item {
    & > * {
      margin-bottom: 0;
    }
  }

  &__status {
    margin-bottom: 5px;
  }

  &__user {
    color: black;
  }

  &__date-time {
    color: rgba(0, 0, 0, 0.45);
  }

  blockquote {
    margin-bottom: 0;

    &:before,
    &:after {
      content: '\0022';
    }
  }
}
</style>
