export class Cookies {
  static get(name) {
    const matches = document.cookie.match(
      new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`),
    );

    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  static set(name, days, value = null) {
    if (name && value !== null) {
      let expires = '';
      if (days) {
        expires = new Date();
        expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${expires.toGMTString()}`;
      }

      document.cookie = `${name}=${value}${expires}; path=/`;
    }
  }

  static delete(name) {
    Cookies.set(name, -1, '');
  }

  static deleteAllCookies() {
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
  }
}
