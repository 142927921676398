<template>
  <a-radio-group
    v-model="input"
    buttonStyle="solid"
    size="small"
  >
    <a-radio-button
      v-for="option of options"
      :key="option.value"
      :value="option.value"
    >
      {{ option.label }}
    </a-radio-button>
  </a-radio-group>
</template>

<script>
export default {
  name: 'FilterElementCheckbox',
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: undefined,
      options: [
        { label: this.$t(`filter.checkbox.yes`), value: 'true' },
        { label: this.$t(`filter.checkbox.no`), value: 'false' },
      ],
    };
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.value = value;
        this.$emit('input', value);
      },
    },
  },
};
</script>
