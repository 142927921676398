<template>
  <div class="entity-view isCompareView">
    <div class="entity-view__head">
      <a-icon
        v-if="showBackButton"
        class="entity-view__close"
        type="arrow-left"
        @click="$emit('goBack')"
      />
      <h1 class="entity-view__title ellipsis">
        <span>{{ title }}</span>
        <span>{{ title2 }}</span>
      </h1>
    </div>

    <div class="entity-view__forms">
      <slot name="form"></slot>
      <slot name="compareForm"></slot>
    </div>
  </div>
</template>

<script>
import store from '@/store';

export default {
  name: 'EntityViewCompare',

  props: {
    data: {
      type: Object,
      default: null,
    },
    data2: {
      type: Object,
      default: null,
    },
    formConfig: {
      type: Object,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    customTitle: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
  },

  computed: {
    title() {
      if (this.data && this.formConfig) {
        return (
          this.data[this.formConfig.params.titleField?.key] ||
          this.formConfig.locale[store.state.lang].entity ||
          this.customTitle ||
          this.type
        );
      }

      return this.customTitle || '';
    },
    title2() {
      if (this.data2 && this.formConfig) {
        return (
          this.data2[this.formConfig.params.titleField?.key] ||
          this.formConfig.locale[store.state.lang].entity ||
          this.customTitle ||
          this.type
        );
      }

      return this.customTitle || '';
    },
  },
};
</script>
