<template>
  <div class="entity-config-field">
    <div class="entity-config__params">
      <template v-for="(configValue, configKey, configIndex) in field">
        <div
          v-if="!isNameParam(configKey) && !isParamHidden(configKey)"
          :key="configIndex"
          class="entity-config__config entity-config__config--vertical unselectable"
        >
          <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
          <label v-if="!isBooleanParam(configKey)">
            {{ paramLabel(configKey) }}
          </label>
          <edit-form-boolean
            v-if="isBooleanParam(configKey)"
            v-model="field[configKey]"
            :config="{ label: paramLabel(configKey) }"
            @input="fieldUpdated(configKey)"
          />
          <a-input-number
            v-else-if="isNumberParam(configKey)"
            v-model="field[configKey]"
            :placeholder="$t('base.placeholder.value')"
            @change="fieldUpdated(configKey)"
          />
          <a-select
            v-else-if="isEnumParam(configKey)"
            v-model="field[configKey]"
            :defaultActiveFirstOption="false"
            :options="enumOptions(configKey)"
            labelInValue
            @change="fieldUpdated(configKey)"
          />
          <a-input
            v-else
            v-model="field[configKey]"
            :placeholder="$t('base.placeholder.value')"
            @change="fieldUpdated(configKey)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { FIELDS_CONFIG_RENDER_PARAMS } from '@/helpers';
import FormConfigService from '@/services/FormConfigService';
import EditFormBoolean from '@/components/edit-form/fields/EditFormBoolean';

export default {
  name: 'EntityFormConfigFieldConfig',

  components: {
    EditFormBoolean,
  },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    fieldsMeta: {
      type: Array,
      required: true,
    },
    isEmbedded: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      field: { field: '' },
      hiddenParams: ['field', 'hidden', 'label'],
    };
  },

  computed: {
    isCanToggle() {
      const params = Object.keys(this.field).filter((field) => !this.hiddenParams.includes(field));
      return params.length > 0;
    },

    fieldStatus() {
      return this.field.hidden ? 'eye-invisible' : 'eye';
    },

    params() {
      return FIELDS_CONFIG_RENDER_PARAMS[this.name];
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value, oldValue) {
        this.field = value;
        if (oldValue) this.$emit('paramsUpdated');
      },
    },
  },

  methods: {
    isNameParam(fieldKey) {
      return fieldKey === 'name';
    },

    isParamHidden(param) {
      return this.hiddenParams.includes(param);
    },

    paramLabel(paramKey) {
      return this.$t(`entity.config.tab.form.${paramKey}`);
    },

    isBooleanParam(paramKey) {
      return FIELDS_CONFIG_RENDER_PARAMS[paramKey].renderer === 'checkbox';
    },

    isNumberParam(paramKey) {
      return FIELDS_CONFIG_RENDER_PARAMS[paramKey].renderer === 'number';
    },

    isEnumParam(paramKey) {
      return FIELDS_CONFIG_RENDER_PARAMS[paramKey].renderer === 'enum';
    },

    enumOptions(paramKey) {
      let options = FIELDS_CONFIG_RENDER_PARAMS[paramKey].options;
      if (typeof options === 'function') {
        options = options(this.fieldsMeta, this.config.locale);
      } else {
        options.forEach((option) => {
          option.label = this.$t(`entity.config.tab.form.${paramKey}_${option.key}`);
        });
      }
      return options;
    },

    fieldUpdated(paramName) {
      const method = this.isEmbedded ? 'updateEmbedConfigField' : 'updateFormConfigField';

      FormConfigService[method](
        this.type,
        this.field.name,
        paramName,
        this.field[paramName],
        this.$t,
      );
    },
  },
};
</script>
