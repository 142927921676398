<template>
  <a-layout-sider
    v-model="collapsed"
    theme="light"
    :width="verticalWidth"
    class="nav-sider"
  >
    <div class="project-header--vertical">
      <img
        v-if="projectHeader?.projectLogo"
        class="project-image"
        :alt="projectHeader?.projectTitle"
        width="40"
        height="40"
        :src="projectHeader?.projectLogo"
      />
      <p
        v-if="projectHeader?.projectTitle"
        class="project-title"
      >
        {{ projectHeader?.projectTitle }}
      </p>
    </div>
    <a-menu
      v-show="!collapsed"
      class="nav"
      mode="inline"
      :selectedKeys="selectedKeys"
      :inlineIndent="inlineIndent"
      :defaultOpenKeys="openKeys"
      @openChange="openChange"
      @click="reemit('click', arguments)"
    >
      <a-sub-menu
        v-for="section of sidebar"
        :key="section.id"
      >
        <span slot="title">
          <a-icon
            v-if="section.icon"
            :type="section.icon"
          />
          <span class="nav__top-section">
            {{ sectionTitle(section) }}
          </span>
        </span>
        <a-menu-item-group>
          <sidebar-section
            v-for="child in section.children"
            :key="child.id"
            :menuItem="child"
          />
        </a-menu-item-group>
      </a-sub-menu>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import store from '@/store';
import { storage } from '@/helpers';
import SidebarSection from './SidebarSection.vue';

export default {
  name: 'SidebarVerticalRoot',
  components: {
    SidebarSection,
  },

  props: {
    sidebar: {
      type: Array,
      default: () => [],
    },
    projectHeader: {
      type: Object,
      default: () => {},
    },
    selectedKeys: {
      type: Array,
      required: true,
    },
    inlineIndent: {
      type: Number,
      required: true,
    },
    verticalWidth: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      collapsed: false,
      openKeys: storage.get('defaultOpenedKeys') || this.sidebar.map((section) => section.id),
    };
  },

  computed: {
    desktop() {
      return store.state.isDesktop;
    },

    collapseIcon() {
      return this.collapsed ? 'menu-unfold' : 'menu-fold';
    },
  },

  created() {
    this.collapsed = this.desktop && (storage.get('sidebarStatus') || false);
  },

  methods: {
    toggleMenu() {
      this.collapsed = !this.collapsed;
      storage.set('sidebarStatus', this.collapsed);
    },

    sectionTitle(section) {
      return typeof section.title === 'string'
        ? section.title
        : section.title[store.state.lang] || section.code;
    },

    openChange(keys) {
      this.openKeys = keys;
      storage.set('defaultOpenedKeys', keys);
    },
  },
};
</script>

<style lang="scss">
.menu--vertical {
  .project-header--vertical {
    width: 100%;
    height: fit-content;
    padding: 0px 34px 0px 36px;
    margin: 0;
    display: flex;
    gap: 6px;
    align-items: center;

    .project-title {
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .nav-sider {
    padding-top: 35px;
  }

  .nav-mode-switch {
    position: absolute;
    top: 77px;
    left: 50%;
    font-size: 18px;
    transform: translateX(-50%);
    &:link {
      color: #aaa;
    }
  }

  .nav {
    &.ant-menu {
      border-right: none;

      .ant-menu-item,
      .ant-menu-submenu-title {
        width: 100%;
      }
    }

    &__top-section {
      text-transform: uppercase;
    }

    &-trigger {
      margin-left: 27px;
      margin-top: 20px;
      font-size: 15px;
    }
  }

  .ant-menu,
  .ant-layout-sider {
    transition: none !important;
  }
  .ant-menu-inline .ant-menu-selected::after,
  .ant-menu-inline .ant-menu-item-selected::after {
    display: none;
  }

  .ant-layout-sider-collapsed {
    margin-right: -90px;
  }
}

@media (min-width: $desktopBreakpoint) {
  .menu--vertical {
    .nav {
      &.ant-menu {
        padding: 8px 0 20px;
      }

      .ant-menu-item-group-title {
        padding-left: 24px;
      }
    }
  }
}
</style>
