<template>
  <error-output :errors="errors">
    <div
      v-if="readOnly || disabled"
      :class="['readonly-wysiwyg tui-editor-contents', { disabled }]"
      v-html="readOnlyValue"
    ></div>
    <editor
      v-else
      ref="focusOnMount"
      :class="['wysiwyg', { disabled }]"
      :initialValue="value || ''"
      initialEditType="wysiwyg"
      :options="options"
      height="300px"
      @change="onChange"
    />
  </error-output>
</template>

<script>
import { Editor } from '@toast-ui/vue-editor';
import lockScroll from '@/components/base/lockScroll.mixin';
import ErrorOutput from '@/components/base/ErrorOutput';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';

export default {
  name: 'EditFormWysiwyg',

  mixins: [lockScroll],

  components: {
    ErrorOutput,
    Editor,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    focusOnMount: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      options: {
        language: 'ru-RU',
        hideModeSwitch: true,
        autofocus: false,
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['ul', 'ol', 'indent', 'outdent'],
          ['link', 'code', 'codeblock'],
        ],

        customHTMLRenderer: {
          emph(node, { origin }) {
            const result = origin();
            result.tagName = 'i';
            return result;
          },
          strong(node, { origin }) {
            const result = origin();
            result.tagName = 'b';
            return result;
          },
          strike(node, { origin }) {
            const result = origin();
            result.tagName = 's';
            return result;
          },
        },
      },
    };
  },

  computed: {
    readOnlyValue() {
      return this.value.replace(/(<a)( .*?>.*?<\/a>)/g, '$1 target="_blank"$2');
    },
  },

  watch: {
    value(value) {
      this.resetWysiwygValue(value);
    },
  },

  mounted() {
    if (!this.readOnly) {
      if (this.focusOnMount) {
        this.lockEntityFormScroll();
        this.$refs.focusOnMount.invoke('focus');
      }
    }
  },

  methods: {
    resetWysiwygValue(value) {
      value = value || '';
      const html = this.$refs.focusOnMount.invoke('getHTML');

      if (html !== value) {
        this.$refs.focusOnMount.invoke('setHTML', value, false);
      }
    },

    onChange() {
      let html = this.$refs.focusOnMount.invoke('getHTML');
      html = html.replace(/<span class="colour"[\s\S]*?>([\s\S]*?)<\/span>/g, '$1');

      if (html !== '<p><br class="ProseMirror-trailingBreak"></p>') {
        this.$emit('input', html);
      }
    },
  },
};
</script>

<style lang="scss">
.readonly-wysiwyg {
  @include scrollbars();
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  height: 130px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background: #fff;
  overflow-y: auto;

  &.disabled {
    background: #f5f5f5;
  }

  &:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }

  &:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  &:only-child {
    word-wrap: break-word;
  }
}
</style>
