<template>
  <draggable-list
    v-model="fileList"
    :options="{ draggable: '.drag' }"
    :disabled="!desktop || disabled || readOnly"
    class="gallery"
    @change="moveCard"
  >
    <input-uploader
      v-for="(file, index) in fileList"
      :key="file"
      :class="['gallery__item', { drag: index > 0 }]"
      :value="file"
      :config="config"
      :disabled="disabled"
      :readOnly="readOnly"
      :totalLength="fileList.length"
      :focusOnMount="focusOnMount"
      @input="imageAdded"
      @remove="imageRemoved"
    />
  </draggable-list>
</template>

<script>
import store from '@/store';
import DraggableList from './DraggableList.vue';
import InputUploader from './InputUploader.vue';

export default {
  name: 'InputUploaderGallery',
  components: {
    DraggableList,
    InputUploader,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    focusOnMount: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    desktop() {
      return store.state.isDesktop;
    },
    fileList: {
      get() {
        const list = [...this.value].reverse();
        if (!this.readOnly && !this.disabled) list.unshift(null);
        return list;
      },
      set(value) {
        this.$emit('input', value.reverse());
      },
    },
  },
  methods: {
    moveCard({ moved }) {
      if (moved) {
        const swap = this.fileList[moved.oldIndex];
        this.fileList.splice(moved.oldIndex, 1, this.fileList[moved.newIndex]);
        this.fileList.splice(moved.newIndex, 1, swap);
        this.fileList = this.fileList.slice(1);
      }
    },
    imageAdded(image) {
      if (image) {
        this.fileList.push(image);
        this.fileList = this.fileList.slice(1);
      }
    },
    imageRemoved(image) {
      const index = this.fileList.indexOf(image);
      this.fileList.splice(index, 1);
      this.fileList = this.fileList.slice(1);
    },
  },
};
</script>

<style lang="scss">
.gallery {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;

  &__item {
    margin-right: 8px;
    margin-bottom: 8px;

    &:not(:first-child) {
      margin-left: 0 !important;
    }
  }

  .empty .ant-upload-select-picture-card {
    width: 100%;
    height: auto;
    display: flex;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
