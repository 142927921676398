<template>
  <div class="so-promocodes">
    <so-promocode
      v-for="document of data.rows"
      :key="document.id"
      :data="document"
      class="so-promocodes__item"
    />
  </div>
</template>

<script>
import MetaService from '@/services/MetaService';
import SoPromocode from './SoPromocode';

export default {
  name: 'SoPromocodes',

  components: {
    SoPromocode,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      actionsMeta: {},
    };
  },

  computed: {
    actions() {
      const actions = ['generatePromocode_action'];
      return actions.map((action) => {
        action = typeof action === 'string' ? { action } : action;
        return {
          ...action,
        };
      });
    },
  },

  async created() {
    this.actionsMeta = await MetaService.getEntityActions('PromocodeParameters', this.actions);
  },
};
</script>

<style lang="scss">
.so-promocodes {
  &__item {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
</style>
