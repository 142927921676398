import lockr from 'lockr';

class Storage {
  constructor() {
    this.storage = lockr;
  }

  set(key, value) {
    return this.storage.set(key, value);
  }

  remove(key) {
    return this.storage.rm(key);
  }

  get(key) {
    return this.storage.get(key);
  }
}

export const storage = new Storage();
