<template>
  <div class="so-course-list">
    <so-course
      v-for="document of data.rows"
      :key="document.id"
      :data="document"
      :extActionsMeta="actionsMeta"
      class="so-course-list__item"
    />
  </div>
</template>

<script>
import store from '@/store';
import MetaService from '@/services/MetaService';
import SoCourse from './SoCourse';

export default {
  name: 'SoCourseList',

  components: {
    SoCourse,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      actionsMeta: {},
    };
  },
  computed: {
    actions() {
      const actions = store.state.activeSidebarItem.customprops.form.actions;
      return actions.map((action) => {
        action = typeof action === 'string' ? { action } : action;
        return {
          ...action,
          title: this.getActionTitle(action),
          hasForm: this.actionHasForm(action),
        };
      });
    },
  },
  async created() {
    this.actionsMeta = await MetaService.getEntityActions('SOCourse', this.actions);
  },
  methods: {
    getActionTitle({ action, title }) {
      return title || action;
    },
    actionHasForm({ action }) {
      const fields = this.actionsMeta[action]?.fieldsList;
      return fields?.filter((field) => field.renderer !== 'hidden').length > 0;
    },
  },
};
</script>

<style lang="scss">
.so-course-list {
  &__item {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
</style>
