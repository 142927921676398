export default {
  methods: {
    _lockScroll(element, state = true) {
      element.classList.toggle('noscroll', state);
    },

    lockScroll(target, state) {
      const element = typeof target === 'string' ? document.querySelector(target) : target;
      this._lockScroll(element, state);
    },

    lockEntityFormScroll(state) {
      const modal = document.querySelector('.entity-modal');
      const page = document.querySelector('.entity-page, .the-page');
      this._lockScroll(modal || page, state);
    },
  },
};
