<template>
  <span>{{ displayValue }}</span>
</template>

<script>
export default {
  name: 'EditFormStaticText',
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    displayValue() {
      return this.value || `<${this.$t(`entity.field.static_empty`)}>`;
    },
  },
};
</script>
