<template>
  <error-output :errors="errors">
    <a-checkbox-group
      v-model="items"
      :disabled="disabled || readOnly"
      :class="['checkboxes readOnly', { readOnly }]"
      :options="options"
    />
  </error-output>
</template>

<script>
import { appSettings } from '@/AppSettings';
import SEARCH_QUERY from '@/queries/search';
import ErrorOutput from '@/components/base/ErrorOutput';

export default {
  name: 'RefCheckboxes',
  components: {
    ErrorOutput,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, Array],
      default: undefined,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingKeys: 0,
    };
  },
  computed: {
    multiple() {
      return (
        this.config.renderer === 'refs' && (!this.config.config || this.config.config.multiple)
      );
    },
    items: {
      get() {
        let value = this.value;
        if (value && !this.multiple && !Array.isArray(value)) value = [value];
        return (value || []).map((item) => item.value);
      },
      set(value) {
        let emited = value;
        if (this.multiple) {
          emited = emited.map((option) => ({
            value: option,
            title: '',
          }));
        } else if (emited) {
          emited = emited.filter((v) => !this.items.includes(v))[0] || null;
          emited = emited && {
            value: emited,
            title: '',
          };
        }

        this.$emit('input', emited);
      },
    },
    options() {
      if (this.loadingKeys) {
        return [];
      }

      return this.config.types
        .reduce(
          (acc, type) =>
            acc.concat(
              this.$data[`autocomplete${type}`].map((option) => ({
                label: option.title,
                value: `${type}:${option.value}`,
              })),
            ),
          [],
        )
        .slice(0, this.config.pageSize);
    },
  },
  created() {
    this.config.types
      .filter((type) => !type.startsWith(appSettings.get('systemFieldsPrefix')))
      .forEach((type) => {
        this.$apollo.addSmartQuery(`autocomplete${type}`, {
          ...SEARCH_QUERY,
          loadingKey: 'loadingKeys',
          fetchPolicy: 'network-only',
          variables: {
            type,
            value: '',
            first: this.config.pageSize,
          },
          error() {
            this.emitError(this.$t(`base.autocompleteErrorOptions`, { field: this.config.field }));
          },
        });
      });
  },
};
</script>

<style lang="scss">
.checkboxes {
  &.ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }
  .ant-checkbox-group-item {
    flex: 0 0 100%;
    margin-right: 0;
    &:nth-child(n + 2) {
      margin-top: 8px;
    }
  }
  &:not(.readOnly) {
    .ant-checkbox-wrapper-disabled span:nth-child(2) {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

@media (min-width: $tabletBreakpoint) {
  .checkboxes {
    .ant-checkbox-group-item {
      flex: 0 0 48%;
      &:nth-child(2) {
        margin-top: 0;
      }
      &:nth-child(2n - 1) {
        margin-right: 4%;
      }
      &:nth-child(n + 3) {
        margin-top: 8px;
      }
    }
    &.readOnly {
      .ant-checkbox-wrapper-disabled,
      .ant-checkbox-input,
      .ant-checkbox-disabled,
      .ant-checkbox-disabled + span {
        cursor: default;
      }

      .ant-checkbox-disabled + span {
        cursor: text;
        color: inherit;
      }
    }
  }
}
</style>
