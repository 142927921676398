let store = null;
let router = null;

export class XHR {
  static initialize(storeRef, routerRef) {
    store = storeRef;
    router = routerRef;
  }

  static query(method, sourceUrl, params = {}, attempt = 0) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const headers = this._composeHeaders(params);
      const payload = this._preparePayload(params);
      const url = this._composeUrl(sourceUrl, params);
      const maxAttempts = params.maxAttempts || 3;
      attempt++;

      if (attempt > maxAttempts) {
        console.error('Max request attempts', method, sourceUrl, params);
        throw new Error('Max request attempts');
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            resolve(xhr.response, xhr);
          } else if (xhr.status === 401) {
            if (params.isRefreshTokenRequest) {
              this._logout();
            } else {
              this._updateToken().then(
                () => {
                  this.query(method, sourceUrl, params, attempt).then(resolve, reject);
                },
                () => {
                  this._logout();
                },
              );
            }
          } else if (xhr.status === 403) {
            this._logout();
          } else {
            reject(new Error(`${xhr.status} ${xhr.statusText}`));
          }
        }
      };

      xhr.open(method, url, true);

      Object.entries(headers).forEach(([name, value]) => {
        xhr.setRequestHeader(name, value);
      });

      xhr.send(payload);
    });
  }

  static _updateToken() {
    return store.mutate.updateToken();
  }

  static async _logout() {
    let path = router.getRoutes().find((route) => route.name === 'AuthPage').path;
    if (window.location.pathname !== '/') {
      path += `?backUrl=${window.location.pathname}`;
    }

    await store.mutate.logout(path);
  }

  static _composeHeaders(params) {
    const dataType = params.dataType || 'json';
    const headers = {};

    if (params.data && dataType === 'json') {
      headers['Content-Type'] = 'application/json;charset=UTF-8';
    }

    return {
      ...headers,
      ...(params.headers || {}),
    };
  }

  static _preparePayload(params) {
    const dataType = params.dataType || 'json';

    if (params.data && dataType === 'json') {
      return JSON.stringify(params.data);
    }

    return params.data;
  }

  static _composeUrl(url, params) {
    // Regexp: url с протоколом либо protocol-relative URL
    if (!params.absoluteUrl && !url.match(/^(?:[a-z]+:)?\/\//)) {
      return process.env.VUE_APP_CMS_URL + url;
    }

    return url;
  }

  static get(...params) {
    return this.query('get', ...params);
  }

  static post(...params) {
    return this.query('post', ...params);
  }

  static put(...params) {
    return this.query('put', ...params);
  }

  static patch(...params) {
    return this.query('patch', ...params);
  }

  static delete(...params) {
    return this.query('get', ...params);
  }
}
