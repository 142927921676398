<template>
  <div>
    <template v-if="!isPublishable">
      <entity-form-config-params
        v-if="!embedded"
        v-model="formParams"
        :fieldsMeta="descriptors"
        :config="localConfig"
      />
    </template>

    <entity-form-config-form
      v-for="(pane, index) of tabs"
      :key="index"
      :value="pane"
      :descriptors="descriptors"
      :oneColumn="embedded"
      :formParams="formParams"
      :tabIndex="index"
      @input="configUpdated($event, index)"
      @editFieldParams="reemit('editFieldParams', arguments)"
    />

    <div class="entity-config">
      <h3 style="margin-bottom: 20px">{{ $t(`entity.config.tab.localization.title`) }}</h3>
      <entity-form-config-locale
        v-model="locale"
        :descriptors="descriptors"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import store from '@/store';
import { bus } from '@/helpers';
import FormConfigService from '@/services/FormConfigService';
import EntityFormConfigForm from './EntityFormConfigForm';
import EntityFormConfigParams from './EntityFormConfigParams';
import EntityFormConfigLocale from './EntityFormConfigLocale';

export default {
  name: 'EntityFormConfigModal',
  components: {
    EntityFormConfigForm,
    EntityFormConfigParams,
    EntityFormConfigLocale,
  },
  props: {
    localConfig: {
      type: Object,
      default: null,
    },
    descriptors: {
      type: Array,
      default: null,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
  },
  inject: ['isPublishable'],
  computed: {
    tabs: {
      get() {
        if (this.embedded) {
          return [{ columns: [this.localConfig.columns] }];
        }

        return this.localConfig.tabs;
      },
      set(tabs) {
        if (this.embedded) {
          this.$set(this.localConfig, 'columns', tabs[0].columns[0]);
        } else {
          this.$set(this.localConfig, 'tabs', tabs);
        }

        bus.$emit('monaco.updatelayout');
        this.debouncedUpdate();
      },
    },
    formParams: {
      get() {
        return this.localConfig.params;
      },
      set(value) {
        this.$set(this.localConfig, 'params', value);
        this.debouncedUpdate();
      },
    },
    locale: {
      get() {
        return this.localConfig.locale[store.state.lang];
      },
      set(value) {
        this.$set(this.localConfig.locale, store.state.lang, value);
        this.debouncedUpdate();
      },
    },
  },
  methods: {
    configUpdated(changedTab, changedIndex) {
      const tabs = [];
      const configTabs = this.tabs;

      configTabs[changedIndex] = changedTab;
      configTabs.forEach((tab, tabIndex) => {
        if (tabIndex === changedIndex) {
          tabs.push(changedTab);
          return;
        }

        tabs.push(tab);
      });

      this.tabs = tabs;
    },

    debouncedUpdate: debounce(() => {
      FormConfigService.updateFormConfig(this);
    }, 200),
  },
};
</script>
