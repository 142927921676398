export default {
  methods: {
    _getElemHeightWithMargins(selector) {
      const element = document.querySelector(selector);
      if (!element) return 0;

      return (
        parseFloat(getComputedStyle(element).height) +
        parseFloat(getComputedStyle(element).marginTop) +
        Math.max(0, parseFloat(getComputedStyle(element).marginBottom)) // max to not count neative margin on form footer
      );
    },

    getMargins(isModal, isEmbed, margins = {}) {
      let { top, right, bottom, left } = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        ...margins,
      };

      if (isModal) {
        top += this._getElemHeightWithMargins('.entity-view__head') + 20; // 20 for label
        bottom += this._getElemHeightWithMargins('.entity-form__footer');
      } else {
        right += 20;
        left += 13;
      }

      if (isEmbed) {
        top += 13; // half of embedtablecell.height - tablecell.height (@1)
        right += 208; // `default` field width inside edit popover without paddings
        bottom += 48 - 13; // editable cell popover height - @1
      }

      return { top, right, bottom, left };
    },

    getMarginsByRenderer(renderer) {
      const dropdownScrollMargin = { bottom: 100 };
      const dateScrollMargin = { bottom: 100 };

      const additionalScrollMargin =
        {
          'date-time': dateScrollMargin,
          date: dateScrollMargin,
          time: dateScrollMargin,
          enum: dropdownScrollMargin,
          ref: dropdownScrollMargin,
          refs: dropdownScrollMargin,
          ref2: dropdownScrollMargin,
          'ref-like': dropdownScrollMargin,
          form_ref: { bottom: 140 },
        }[renderer] || {};

      return additionalScrollMargin;
    },

    // ! Works only on entity-page, data-page with entity modal opened and data-page in inline-table mode
    async scrollElementInView(
      target,
      limits,
      waitingTime = 0,
      vScrollParent = null,
      hScrollParent = null,
    ) {
      let vContainerSelector;
      let hContainerSelector;

      if (!vScrollParent) {
        vScrollParent =
          document.querySelector('.entity-modal') ||
          document.querySelector('.entity-page, .the-page');

        vContainerSelector = vScrollParent.classList.contains('entity-modal')
          ? '.entity-modal'
          : '.entity-page, .the-page';
      } else if (typeof vScrollParent === 'string') {
        vContainerSelector = vScrollParent;
        vScrollParent = document.querySelector(vScrollParent);
      }

      if (!hScrollParent) {
        if (vContainerSelector === '.entity-modal') {
          hScrollParent = vScrollParent;
          hContainerSelector = vContainerSelector;
        } else {
          hScrollParent =
            target.closest('.embed-table__scroll-container') || document.querySelector('.table');

          hContainerSelector = hScrollParent.classList.contains('embed-table__scroll-container')
            ? '.embed-table__scroll-container'
            : '.table';
        }
      } else if (typeof hScrollParent === 'string') {
        hContainerSelector = hScrollParent;
        hScrollParent = document.querySelector(hScrollParent);
      }

      return new Promise((resolve) => {
        if (!target) {
          if (waitingTime > 2000) {
            resolve(null);
          } else {
            setTimeout(() => {
              this.scrollElementInView(
                target,
                limits,
                waitingTime + 50,
                vScrollParent,
                hScrollParent,
              );
            }, 50);
          }
        }

        if (target) {
          let y = vScrollParent.scrollTop;
          let x = hScrollParent.scrollLeft;

          const margins = this.getMargins(
            vScrollParent.classList.contains('entity-modal'),
            !hScrollParent.classList.contains('entity-modal'),
            limits,
          );

          // VERTICAL

          const vContainerHeight = parseFloat(getComputedStyle(vScrollParent).height);
          const vScrollOffset = this.getScrollTopOffset(target, vContainerSelector);
          const vMinScrollOffset = vScrollOffset - margins.top;
          const vMaxScrollOffset = vScrollOffset - vContainerHeight + margins.bottom;

          if (y > vMinScrollOffset) {
            y = vMinScrollOffset;
          } else if (y < vMaxScrollOffset) {
            y = vMaxScrollOffset;
          }

          // HORIZONTAL

          const hContainerWidth = parseFloat(getComputedStyle(hScrollParent).width);
          const hElemScrollOffset = this.getScrollLeftOffset(target, hContainerSelector);
          const hMinScrollOffset = hElemScrollOffset - margins.left;
          const hMaxScrollOffset = hElemScrollOffset - hContainerWidth + margins.right;

          if (x > hMinScrollOffset) {
            x = hMinScrollOffset;
          } else if (x < hMaxScrollOffset) {
            x = hMaxScrollOffset;
          }

          // DO SCROLL

          vScrollParent.scrollTop = y;
          hScrollParent.scrollLeft = x;
          resolve([x, y]);
        }
      });
    },

    getScrollTopOffset(target, finalSelector) {
      const offsetParent = target.offsetParent;

      if (!offsetParent.closest(finalSelector)) {
        return target.offsetTop - document.querySelector(finalSelector).offsetTop;
      }

      return target.offsetTop + this.getScrollTopOffset(offsetParent, finalSelector);
    },

    getScrollLeftOffset(target, finalSelector) {
      const offsetParent = target.offsetParent;

      if (!offsetParent.closest(finalSelector)) {
        return target.offsetLeft - document.querySelector(finalSelector).offsetLeft;
      }

      return target.offsetLeft + this.getScrollLeftOffset(offsetParent, finalSelector);
    },
  },
};
