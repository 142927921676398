var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showEditor)?_c('MonacoEditor',{ref:"editor",class:['editor', { disabled: _vm.disabled }, `compare-${_vm.comparePane}`],style:(_vm.editorStyle),attrs:{"diffEditor":!!_vm.diffValue,"original":_vm.diffInput,"options":{
    BuiltinTheme: 'vs-dark',
    automaticLayout: true,
    readOnly: _vm.disabled || _vm.readOnly,
    language: _vm.language,
    scrollbar: {
      alwaysConsumeMouseWheel: false,
    },
    suggest: {
      showKeywords: false,
      showModules: false,
    },
    splitViewDefaultRatio: _vm.splitViewDefaultRatio,
  }},on:{"editorWillMount":_vm.registerLang,"editorDidMount":_vm.onEditorMounted},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }