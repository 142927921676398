<template>
  <div class="filter-element">
    <div
      v-if="title"
      class="filter-element__title"
    >
      {{ title }}
    </div>
    <div class="filter-element__content">
      <a-select
        v-model="operatorKey"
        class="filter-element__operator"
        size="small"
        :defaultValue="operatorKey"
        :disabled="!operators.length"
      >
        <a-select-option
          v-for="operator in operators"
          :key="operator.key"
        >
          {{ $t(`filter.operator.${operator.key}`) }}
        </a-select-option>
      </a-select>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterElement',
  props: {
    title: {
      type: String,
      default: '',
    },
    element: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      value: null,
    };
  },
  computed: {
    operators() {
      return this.element.operators;
    },

    operatorKey: {
      get() {
        return this.value || this.operators[0]?.key;
      },
      set(value) {
        this.value = value;
        this.$emit('input', value);
      },
    },
  },
  created() {
    this.$emit('input', this.operatorKey);
  },
};
</script>

<style lang="scss">
.filter-element {
  padding: 10px 0px;
  width: 100%;
  box-sizing: border-box;

  &__title {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 14px;
    color: $darkTextColor;
  }

  &:last-child {
    flex-grow: 1;
    align-self: flex-end;
    text-align: right;
    min-width: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__operator {
    margin-bottom: 8px;
  }

  .ant-select-disabled {
    color: rgba(0, 0, 0, 0.65);
    .ant-select-selection {
      background-color: #fff;
      cursor: auto;
    }
    .ant-select-arrow {
      display: none;
    }
  }
}

.filter-radio + .filter-radio {
  margin-left: 40px;
}
</style>
