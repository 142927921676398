<template>
  <div class="entity-view__head">
    <a-icon
      v-if="showBackButton"
      class="entity-view__close"
      type="arrow-left"
      @click="$emit('goBack')"
    />
    <h1 class="entity-view__title ellipsis">
      <span>{{ title }}</span>
    </h1>
    <div
      v-if="!hideHeadControls"
      class="entity-view__buttons"
    >
      <slot name="additionalHeadControls"></slot>

      <a-tooltip
        v-if="showConfigButton"
        :title="$t('entity.mode.config')"
      >
        <a-icon
          class="entity-view__head-button"
          type="setting"
          @click="$emit('openConfig')"
        />
      </a-tooltip>

      <a-tooltip
        v-if="showHistoryModeButton"
        :title="$t('entity.mode.history')"
      >
        <a-icon
          class="entity-view__head-button"
          type="history"
          @click="$emit('switchMode', 'history')"
        />
      </a-tooltip>

      <a-tooltip
        v-if="showEditModeButton"
        :title="$t('entity.mode.form')"
      >
        <a-icon
          class="entity-view__head-button"
          type="form"
          @click="$emit('switchMode', 'edit')"
        />
      </a-tooltip>

      <a-tooltip
        v-if="showExportButton"
        :title="$t('base.export')"
      >
        <a
          class="entity-view__head-export"
          :href="exportLink"
          aria-label="export"
        >
          <a-icon
            class="entity-view__head-button"
            type="export"
          />
        </a>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import store from '@/store';

export default {
  name: 'EntityViewHead',

  props: {
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    hideHeadControls: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    formMode: {
      type: String,
      default: null,
    },
  },

  computed: {
    desktop() {
      return store.state.isDesktop;
    },
    showConfigButton() {
      return store.state.user.isConstructor;
    },
    showHistoryModeButton() {
      return this.desktop && this.formMode !== 'history';
    },
    showEditModeButton() {
      return this.formMode !== 'edit';
    },
    showExportButton() {
      return store.state.meta.entities.find((e) => e.name === this.type);
    },
    exportLink() {
      return `/transfer/download/${this.type}/${this.id}`;
    },
  },
};
</script>

<style lang="scss">
.entity-view {
  display: flex;
  width: 100%;
  flex-direction: column;

  &__close {
    position: relative;
    top: 2px;
    width: 30px;
    margin-right: 15px;
    font-size: 18px;
    flex: 0 0 30px;
  }

  &__head {
    position: relative;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    margin: 0 -10px 15px;
    padding: 0 10px;
    min-height: 42px;
    flex-wrap: wrap;
  }

  &__buttons {
    position: absolute !important;
    right: 2px;
    display: flex;
    flex-direction: row-reverse;
  }

  &__head-button {
    cursor: pointer;
    &:hover {
      opacity: 0.65;
    }
  }
}

@media (min-width: $desktopBreakpoint) {
  .entity-view {
    max-width: 1180px;
    padding: 0 40px 40px;

    &__close {
      margin-left: -33px;
      margin-right: 2px;
    }

    &__title {
      display: inline-block;
      margin-right: 30px;
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 0;
      flex-shrink: 0;
    }

    &__buttons {
      top: 5px !important;
    }

    &__head-button {
      padding: 10px;
    }

    &__head-export,
    &__head-export:hover {
      color: inherit;
    }

    &__no-form-msg {
      margin-top: 43px;
      button {
        margin-top: 20px;
      }
    }

    &.isCompareView {
      max-width: 2320px;

      .entity-view__title {
        width: 100%;
      }

      .entity-view__title,
      .entity-view__forms {
        display: flex;
        justify-content: space-between;
        & > * {
          width: 48.25%;
          flex: 0 0 48.25%;
        }
      }
    }
  }
}
</style>
