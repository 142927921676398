<template>
  <div
    class="table-actions"
    @click.stop
  >
    <template v-for="action of actionsToDisplay">
      <a-button
        v-if="!actionsMeta"
        :key="action.action"
        type="primary"
        class="table-actions__btn"
        disabled
      >
        {{ action.title }}
      </a-button>
      <a-button
        v-else-if="action.hasForm"
        :key="action.action"
        type="primary"
        class="table-actions__btn"
        @click="showActionForm(action)"
      >
        {{ action.title }}
      </a-button>
      <a-popconfirm
        v-else
        :key="action.action"
        :okText="$t('customForm.actionConfirm')"
        :cancelText="$t('customForm.actionCancel')"
        :title="$t('customForm.actionTitle')"
        @confirm="performFormlessAction(action)"
      >
        <a-button
          type="primary"
          class="table-actions__btn"
        >
          {{ action.title }}
        </a-button>
      </a-popconfirm>
    </template>
  </div>
</template>

<script>
import MetaService from '@/services/MetaService';
import customMutation from '@/queries/mutations/customMutation';
import { bus, preparePayload } from '@/helpers';

export default {
  name: 'TableCustomActions',
  props: {
    actions: {
      type: Array,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      actionsMeta: [],
    };
  },
  computed: {
    actionsToDisplay() {
      return this.actions.map((action) => {
        action = typeof action === 'string' ? { action } : action;
        return {
          ...action,
          title: this.getActionTitle(action),
          hasForm: this.actionHasForm(action),
        };
      });
    },
  },
  async created() {
    this.actionsMeta = await MetaService.getEntityActions(this.entity.type, this.actions);
  },
  methods: {
    getActionTitle({ action, title }) {
      return title || action;
    },
    actionHasForm({ action }) {
      const fields = this.actionsMeta[action]?.fieldsList;
      return fields?.filter((field) => field.renderer !== 'hidden').length > 0;
    },
    showActionForm({ action }) {
      this.$router
        .push({
          name: 'DataPage',
          params: {
            type: this.$route.params.type,
            id: this.entity.id,
            action,
          },
        })
        .catch(() => {});
    },
    async performFormlessAction({ action }) {
      const actionMeta = this.actionsMeta[action].fieldsList;
      const data = { id: this.entity.id };

      actionMeta
        .filter((field) => field.name !== 'id')
        .reduce((acc, { name }) => {
          acc[name] = this.entity.data[name];
          return acc;
        }, data);

      this.$apollo
        .mutate({
          mutation: customMutation(action, this.entity.type, actionMeta),
          variables: preparePayload(data, actionMeta),
        })
        .then(() => {
          bus.$emit('refetchTable');
          this.$notification.success({
            message: this.$t('customForm.saveSuccess'),
          });
        })
        .catch((error) => {
          this.$notification.error({
            message: this.$t('customForm.saveError'),
            description: error.message,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.table-actions {
  &__btn {
    & + & {
      margin-left: 10px;
    }
  }
}
</style>
