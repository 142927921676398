<template>
  <div
    ref="container"
    class="calendar"
  >
    <a-calendar v-model="time">
      <template #dateCellRender="day">
        <template v-if="events.days[day.unix()]">
          <draggable-list
            v-model="events.days[day.unix()]"
            class="calendar__cell"
            :group="draggableGroupUid"
            :disabled="!dragEnabled"
            @startDrag="onStartDrag"
            @stopDrag="onStopDrag"
            @change="changeEventDate($event, day)"
          >
            <!-- eslint-disable-next-line vuejs-accessibility/mouse-events-have-key-events -->
            <div
              v-for="(event, index) in events.days[day.unix()]"
              :key="event.id"
              class="calendar__item"
              :class="cellItemClasses(day, event)"
              :style="cellItemStyle(event, events.days[day.unix()][index - 1])"
              @click.prevent="openItem($event, event.id)"
              @mouseenter="mouseenterEvent($event, event)"
              @mousedown="mousedownEvent"
            >
              <span class="calendar__item-text ellipsis">
                {{ event.displayField }}
              </span>
            </div>
          </draggable-list>
        </template>
        <template v-else>
          <draggable-list
            class="calendar__cell empty"
            :group="draggableGroupUid"
            :disabled="!dragEnabled"
            @change="changeEventDate($event, day)"
          />
        </template>
      </template>
      <template #monthCellRender="value">
        <div
          v-if="getMonthData(value)"
          class="calendar__month"
        >
          <a-badge
            status="success"
            :text="monthElemsCountText(value)"
          />
        </div>
      </template>
    </a-calendar>

    <a-popover
      v-if="showCardsPreview && hoveredEvent"
      :visible="!draggingSomething"
      placement="bottomRight"
      overlayClassName="calendar__event-preview"
      :align="{ target: hoveredNode }"
    >
      <template #content>
        <kanban-card
          :fieldsToDisplay="previewFields"
          :card="hoveredEvent"
          :style="previewStyle"
          readOnly
        />
      </template>
    </a-popover>
  </div>
</template>

<script>
import moment from 'moment';
import store from '@/store';
import {
  uniqueId,
  declension,
  sortDataEntriesByField,
  prepareKanbanCardLayout,
  formatDateTime,
  formatDate,
  formatTime,
} from '@/helpers';
import EntityService from '@/services/EntityService';
import DraggableList from '@/components/base/DraggableList.vue';
import KanbanCard from '@/components/page/kanban/KanbanCard.vue';

export default {
  name: 'TheCalendar',
  components: {
    DraggableList,
    KanbanCard,
  },
  props: {
    entityType: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    entityFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userClickDate: null,
      hoveredEvent: null,
      hoveredNode: null,
      draggableGroupUid: uniqueId(),
      draggingSomething: false,
      draggedElementClone: null,
      windowHeight: window.innerHeight,
    };
  },

  computed: {
    dragEnabled() {
      return store.state.isDesktop;
    },
    displayOptions() {
      return this.config.displayType.options;
    },
    previewFields() {
      return prepareKanbanCardLayout(this.entityFields, this.config.displayFields);
    },
    showCardsPreview() {
      return !!this.previewFields.length;
    },
    displayField() {
      const column = this.showCardsPreview ? this.previewFields[0] : this.config.displayFields[0];
      return column.name;
    },
    displayFieldMeta() {
      return this.entityFields.find((field) => field.name === this.displayField);
    },
    events() {
      const events = sortDataEntriesByField(this.data.rows.slice(), this.displayOptions.startDate);
      return events.reduce(this.spreadEventsByDays, {
        days: {},
        maxDate: null,
      });
    },
    time: {
      get() {
        return this.userClickDate || this.events.maxDate || moment();
      },
      set(value) {
        this.userClickDate = value;
      },
    },
    previewStyle() {
      const height = Math.min(
        parseInt(getComputedStyle(this.$refs.container).height) - 80,
        this.windowHeight - 120,
      );

      return {
        maxHeight: `${height}px`,
      };
    },
  },

  created() {
    document.addEventListener('mousemove', this.mousemoveDocumentEvent);
    window.addEventListener('resize', this.resize);
  },

  beforeDestroy() {
    document.removeEventListener('mousemove', this.mousemoveDocumentEvent);
    window.removeEventListener('resize', this.resize);
  },

  methods: {
    declension,

    resize() {
      this.windowHeight = window.innerHeight;
    },

    // Размазываем каждое события по всем дням на всей его протяжённости
    spreadEventsByDays({ days, maxDate }, event) {
      event = this.getEventWrap(event);
      if (event.momentFrom) {
        this.calcEventDisplayRow(event, days);
        if (event.momentTo > maxDate) maxDate = event.momentTo;
      }

      return { days, maxDate };
    },

    getFormattedDisplayValue(value) {
      const emptyString = this.$t('app.calendar.emptyField');
      const meta = this.displayFieldMeta;
      if (meta.renderer === 'gallery') {
        value = (value || []).join(', ') || emptyString;
      } else if (meta.renderer === 'boolean') {
        value = Boolean(value);
      } else if (meta.renderer === 'date-time') {
        value = formatDateTime(value);
      } else if (meta.renderer === 'date') {
        value = formatDate(value);
      } else if (meta.renderer === 'time') {
        value = formatTime(value);
      } else if (meta.renderer === 'array') {
        value = value || [];
      } else if (meta.renderer === 'refs') {
        value = (value || []).map((item) => item.title || item.value).join(', ') || emptyString;
      } else if (['ref-like', 'ref', 'ref2'].includes(meta.renderer)) {
        value = value?.title || value?.value || emptyString;
      } else if (['component', 'embed'].includes(meta.renderer)) {
        value = `${meta.name}: ${value?.length || 0}`;
      } else {
        value = value || emptyString;
      }

      return value;
    },

    getEventWrap(event) {
      return {
        id: event.id,
        data: event.data,
        displayField: this.getFormattedDisplayValue(event.data[this.displayField]),
        row: 0,
        ...this.getEventTimeSpan(event.data),
      };
    },

    getEventTimeSpan(event) {
      let dateFrom = event[this.displayOptions.startDate];
      let dateTo = event[this.displayOptions.endDate];

      if (dateFrom || dateTo) {
        if (!dateFrom) dateFrom = dateTo;
        else if (!dateTo) dateTo = dateFrom;

        dateFrom = moment(dateFrom).startOf('day');
        dateTo = moment(dateTo).startOf('day');
      }

      return {
        momentFrom: dateFrom,
        momentTo: dateTo,
      };
    },

    calcEventDisplayRow(event, days) {
      const cursor = moment(event.momentFrom);
      while (cursor.isSameOrBefore(event.momentTo)) {
        const stamp = cursor.unix();
        days[stamp] = days[stamp] || [];

        let insertIndex = -1;
        if (days[stamp].length) {
          const row = days[stamp].findIndex((elem, index) => elem.row !== index);
          event.row = Math.max(row < 0 ? days[stamp].slice(-1)[0].row + 1 : row, event.row);
          insertIndex = days[stamp].findIndex((elem) => elem.row > event.row);
        }

        if (insertIndex > -1) days[stamp].splice(insertIndex, 0, event);
        else days[stamp].push(event);
        cursor.add(1, 'day');
      }
    },

    hideEventTitleForDay(dayMoment, event) {
      return store.state.isDesktop && dayMoment.day() !== 0 && !dayMoment.isSame(event.momentFrom);
    },

    cellItemClasses(dayMoment, event) {
      return {
        hovered: event === this.hoveredEvent,
        hideTitle: this.hideEventTitleForDay(dayMoment, event),
        first: dayMoment.isSame(event.momentFrom),
        last: dayMoment.isSame(event.momentTo),
        weekStart: dayMoment.day() === 0,
        weekEnd: dayMoment.day() === 6,
      };
    },

    cellItemStyle(event, prevEvent) {
      prevEvent = prevEvent || { row: -1 };
      return store.state.isDesktop ? { height: `${22 * (event.row - prevEvent.row)}px` } : '';
    },

    getDayEvents(dayMoment) {
      return this.events.days[dayMoment.unix()] || [];
    },

    getMonthData(monthMoment) {
      const start = monthMoment.startOf('month').unix();
      const end = monthMoment.endOf('month').unix();

      return Object.entries(this.events.days).filter(
        ([timestamp]) => timestamp >= start && timestamp <= end,
      ).length;
    },

    monthElemsCountText(value) {
      value = this.getMonthData(value);
      return this.$tc('calendar.monthElementCount', value);
    },

    openItem($event, id) {
      if (!this.draggingSomething) {
        this.hoveredEvent = null;
        this.$emit('entityClick', id);
      }
    },

    mouseenterEvent(jsEvent, element) {
      if (!this.draggingSomething) {
        this.hoveredEvent = element;
        this.hoveredNode = jsEvent.currentTarget;
      }
    },

    mousemoveDocumentEvent(event) {
      if (
        !this.draggingSomething &&
        !event.path.some(
          (p) => p.classList?.contains('kanban-card') || p.classList?.contains('calendar__item'),
        )
      ) {
        this.hoveredEvent = null;
      }
    },

    mousedownEvent() {
      this.hoveredEvent = null;
    },

    onStartDrag({ item, from, oldIndex }) {
      this.draggingSomething = true;
      this.draggedElementClone = from.insertBefore(item.cloneNode(true), from.children[oldIndex]);
      this.draggedElementClone.classList.add('sortable-placeholder');
      this.draggedElementClone.classList.remove('my-draggable');
    },

    onStopDrag() {
      this.draggedElementClone.remove();
      this.draggedElementClone = null;

      // Prevent firefox from registring click event
      setTimeout(() => {
        this.draggingSomething = false;
      }, 50);
    },

    changeEventDate({ added }, newStartMoment) {
      if (added) {
        const { element } = added;
        const entity = element.data;

        const daysDiff = element.momentFrom.diff(newStartMoment, 'days');
        entity[this.displayOptions.startDate] = element.momentFrom
          .subtract(daysDiff, 'days')
          .valueOf();
        entity[this.displayOptions.endDate] = element.momentTo.subtract(daysDiff, 'days').valueOf();

        EntityService.update(this.entityFields, {
          id: element.id,
          type: this.entityType,
          data: entity,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.calendar {
  $columnWidth: percentage(1/7);
  $eventBorderRadius: 2px;

  .sortable-fallback {
    height: 22px !important;
  }

  .sortable-ghost {
    z-index: 2;
    &:not(.sortable-placeholder) {
      position: absolute !important;
      width: 100% !important;
      height: 100% !important;
      background: #fff;
      box-shadow: 0 0 4px 2px rgba(28, 139, 236, 0.842);
      z-index: 1;
      > * {
        display: none !important;
      }
    }
  }

  .ant-fullcalendar-fullscreen {
    .ant-fullcalendar-table {
      display: flex;
      flex-direction: column;
      height: auto;
    }

    thead {
      display: block;

      tr {
        display: flex;
      }

      th {
        display: block;
        flex: 0 0 $columnWidth;
      }
    }

    tbody {
      display: block;

      tr {
        display: flex;
      }

      td {
        display: flex;
        flex-direction: column;
        flex: 0 0 $columnWidth;
        min-width: 0;
      }
    }

    .ant-fullcalendar-date {
      height: auto;
      min-height: 96px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0;
      border-left: solid 4px #fff;
      border-right: solid 4px #fff;
    }

    .ant-fullcalendar-content {
      height: auto;
      flex-grow: 1;
      overflow-y: visible;
      display: flex;
      flex-direction: column;
    }
  }

  .ant-fullcalendar-value {
    width: 100%;
  }

  &__cell {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    &.empty {
      flex-grow: 1;
    }

    &:not(.empty) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      line-height: 18px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;

    &-text {
      display: block;
      width: 100%;
      height: 18px;
      padding: 0 3px;
      font-weight: 500;
      background-color: #f2f2f2;

      &:before,
      &:after {
        position: absolute;
        display: block;
        bottom: 0;
        height: 18px;
        width: 13px;
        background-color: inherit;
        content: '';
      }

      &:before {
        right: 100%;
      }

      &:after {
        left: 100%;
      }
    }

    &.first &-text {
      border-top-left-radius: $eventBorderRadius;
      border-bottom-left-radius: $eventBorderRadius;

      &:before {
        display: none;
      }
    }

    &.last &-text {
      border-top-right-radius: $eventBorderRadius;
      border-bottom-right-radius: $eventBorderRadius;

      &:after {
        display: none;
      }
    }

    &.hideTitle:not(.sortable-drag) &-text {
      font-size: 0;
    }

    &.weekStart.first &-text {
      &:before {
        border-radius: $eventBorderRadius 0 0 $eventBorderRadius;
      }
    }

    &.weekEnd:not(.last) &-text {
      &:after {
        border-radius: 0 $eventBorderRadius $eventBorderRadius 0;
      }
    }

    &.hovered &-text {
      opacity: 0.5;
    }
  }

  &__month {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 20px;
  }

  &__event-preview {
    width: 270px;

    .kanban-card {
      overflow-y: auto;
      @include scrollbars();
    }

    .ant-popover-inner-content {
      padding: 0;
    }
  }
}

@media (max-width: $desktopBreakpoint - 1) {
  .calendar {
    .ant-fullcalendar-month-panel-tbody {
      width: 100%;
    }

    .ant-fullcalendar-calendar-body {
      position: relative;
      table {
        display: flex;
        flex-wrap: wrap;
      }
      thead {
        display: none;
      }
      tr {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
      }
      th,
      td {
        width: 100%;
      }
    }
  }
}

@media (min-width: $desktopBreakpoint) {
  .calendar {
    .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-tbody td {
      flex: 0 0 percentage(1/3);
    }
  }
}
</style>
