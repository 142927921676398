import { prepareConfig } from './configFormLayout';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    configLayout: {
      type: Object,
      required: true,
    },
    entityType: {
      type: String,
      default: null,
    },
    entityFields: {
      type: Array,
      required: true,
    },
  },

  methods: {
    getSubFormLayoutFromList(list, value) {
      if (typeof list === 'function') {
        return list(this.entityFields).find((listItem) => listItem.value === value).options;
      }
      return list.find((listItem) => listItem.value === value).options;
    },
    async getConditionalFormDefaults(key, value) {
      const options =
        typeof this.configLayout[key].options === 'function'
          ? await this.configLayout[key].options(this.entityFields)
          : this.configLayout[key].options;

      return prepareConfig({}, this.entityFields, this.getSubFormLayoutFromList(options, value));
    },
    async updateConditionalFormDefaults(key, value) {
      this.value[key].options = await this.getConditionalFormDefaults(key, value);
      this.configUpdated();
    },
    getEnumFieldOptions(field, entityType) {
      return typeof field.options === 'function'
        ? field.options(this.entityFields, entityType)
        : field.options;
    },
    configUpdated() {
      this.$emit('configUpdated');
    },
  },
};
