import LocaleService from '@/services/LocaleService';
import { isSystemField } from './formFields';

const REF_DISPLAY_OPTIONS = [{ key: 'autocomplete' }, { key: 'checkboxes' }];

const ENUM_DISPLAY_OPTIONS = [{ key: 'autocomplete' }, { key: 'checkboxes' }];

const DISABLED_DISPLAY_OPTIONS = [
  { key: 'global', label: 'global' },
  { key: 'disabled', label: 'disabled' },
  { key: 'readOnly', label: 'readOnly' },
  { key: 'hidden', label: 'hidden' },
];

export const EMBED_PARAMS = {};

export const FORM_PARAMS_RENDER_PARAMS = {
  titleField: {
    renderer: 'enum',
    allowClear: true,
    options(fieldsMeta, locale) {
      return [
        ...fieldsMeta
          .filter((field) => field.renderer === 'string')
          .map(({ name }) => ({
            key: name,
            label: locale[name]?.label || name,
          })),
      ];
    },
    getValue(field, fieldsMeta) {
      const options = fieldsMeta
        .filter(({ renderer }) => renderer === 'string')
        .map(({ name }) => name);

      return field?.key && options.includes(field.key) ? field : undefined;
    },
  },
  previewModeEnabled: {
    renderer: 'checkbox',
    getValue(value) {
      return value ?? false;
    },
  },
  renderDisabledFields: {
    renderer: 'enum',
    options: DISABLED_DISPLAY_OPTIONS,
    getValue(field) {
      return DISABLED_DISPLAY_OPTIONS.find((option) => option.key === field?.key)
        ? field
        : DISABLED_DISPLAY_OPTIONS[0];
    },
  },
};

export const FIELDS_CONFIG_RENDER_PARAMS = {
  multiple: {
    renderer: 'checkbox',
    applicableFieldRenderers: ['component', 'embed'],
    getValue(field) {
      return field.multiple ?? true;
    },
  },
  columnWidth: {
    renderer: 'string',
    applicableFieldRenderers: ['component', 'embed'],
    getValue(field) {
      return field.columnWidth ? String(field.columnWidth) : '250';
    },
  },
  refDisplay: {
    renderer: 'enum',
    applicableFieldRenderers: ['ref', 'refs', 'ref2', 'ref-like'],
    ignoreSystemFields: true,
    options: REF_DISPLAY_OPTIONS,
    getValue(field) {
      return field.refDisplay || REF_DISPLAY_OPTIONS[0];
    },
  },
  enumDisplay: {
    renderer: 'enum',
    applicableFieldRenderers: ['enum'],
    ignoreSystemFields: true,
    options: ENUM_DISPLAY_OPTIONS,
    getValue(field) {
      return field.enumDisplay || ENUM_DISPLAY_OPTIONS[0];
    },
  },
  pageSize: {
    renderer: 'number',
    applicableFieldRenderers: ['component', 'embed'],
    getValue(field) {
      return field.pageSize || 12;
    },
  },
  width: {
    renderer: 'number',
    applicableFieldRenderers: ['image', 'gallery'],
    getValue(field) {
      return field.width || '';
    },
  },
  height: {
    renderer: 'number',
    applicableFieldRenderers: ['image', 'gallery', 'string-list'],
    getValue(field) {
      return field.height || '';
    },
  },
  mapWidth: {
    renderer: 'string',
    applicableFieldRenderers: ['gpoint'],
    getValue(field) {
      return field.mapWidth || '100%';
    },
  },
  mapHeight: {
    renderer: 'string',
    applicableFieldRenderers: ['gpoint'],
    getValue(field) {
      return field.mapHeight || '300px';
    },
  },
  textHeight: {
    renderer: 'number',
    applicableFieldRenderers: ['text'],
    getValue(field) {
      return field.textHeight || 130;
    },
  },
};

function prepareFormParameters(params, fieldsMeta, paramsDict = FORM_PARAMS_RENDER_PARAMS) {
  params = params || {};

  return Object.entries(paramsDict).reduce((acc, [key, param]) => {
    acc[key] = param.getValue(params[key], fieldsMeta);
    return acc;
  }, {});
}

function prepareFormLocalizationLanguage(langLocale, fieldsMeta) {
  const lang = {
    entity: langLocale?.entity || '',
    fields: langLocale?.fields || {},
  };

  lang.fields = fieldsMeta.reduce((acc, { name, types }) => {
    if (isSystemField({ types })) return acc;

    acc[name] = {
      label: lang.fields[name]?.label || '',
      info: lang.fields[name]?.info || '',
    };

    return acc;
  }, {});

  return lang;
}

function prepareFormLocalization(locale, fieldsMeta) {
  locale = locale || {};

  return (LocaleService.languages || [{ code: 'ru' }]).reduce((acc, { code }) => {
    acc[code] = prepareFormLocalizationLanguage(locale[code], fieldsMeta);
    return acc;
  }, {});
}

function createMissingFieldDefaultParams(field, { renderer, types }) {
  field.hidden = field.hidden ?? isSystemField({ types });
  const resParams = ['name', 'hidden', 'renderer'];

  Object.entries(FIELDS_CONFIG_RENDER_PARAMS).forEach(([paramName, param]) => {
    if (
      param.applicableFieldRenderers.includes(renderer) &&
      (!param.ignoreSystemFields || !isSystemField({ types }))
    ) {
      field[paramName] = param.getValue(field);
      resParams.push(paramName);
    }
  });

  Object.keys(field).forEach((key) => {
    if (!resParams.includes(key)) delete field[key];
  });

  return field;
}

function createMissingLayoutFieldsParamsForColumn(column, fieldsMeta) {
  column.forEach((fieldParams) => {
    const descriptor = fieldsMeta.find((desc) => desc.name === fieldParams.name);
    createMissingFieldDefaultParams(fieldParams, descriptor);
  });

  return column;
}

function createMissingLayoutFieldsParams(tabs, fieldsMeta) {
  return tabs.map((tab) => {
    tab.columns = tab.columns.map((column) =>
      createMissingLayoutFieldsParamsForColumn(column, fieldsMeta),
    );

    return tab;
  });
}

function cleanDeadFieldsFromLayoutColumn(column, fieldsMeta) {
  return column.filter((fieldParams) =>
    fieldsMeta.find((descriptor) => descriptor.name === fieldParams.name),
  );
}

function createMissingLayoutFieldsForColumn(column, fieldsMeta, existentFields) {
  column = [
    ...column,
    ...fieldsMeta
      .filter((descriptor) => !existentFields.includes(descriptor.name))
      .map(({ name }) => ({ name })),
  ];

  return column;
}

function createMissingLayoutFields(tabs, fieldsMeta, existentFields) {
  tabs[0].columns[0] = createMissingLayoutFieldsForColumn(
    tabs[0].columns[0],
    fieldsMeta,
    existentFields,
  );

  return tabs;
}

export function prepareFormLayout(tabs, fieldsMeta) {
  const existentFields = [];

  tabs = tabs || [];
  tabs = [1, 2].map((tabName, tabIndex) => {
    const tab = tabs[tabIndex] || { columns: [[], []] };

    return {
      columns: tab.columns.map((column) => {
        column = cleanDeadFieldsFromLayoutColumn(column, fieldsMeta);
        existentFields.push(...column.map(({ name }) => name));
        return column;
      }),
    };
  });

  tabs = createMissingLayoutFields(tabs, fieldsMeta, existentFields);
  tabs = createMissingLayoutFieldsParams(tabs, fieldsMeta);
  return tabs;
}

export function prepareFormConfig(entityMeta, config = {}) {
  config.params = prepareFormParameters(config.params, entityMeta.fields);
  config.locale = prepareFormLocalization(config.locale, entityMeta.fields);
  config.tabs = prepareFormLayout(config.tabs, entityMeta.fields);

  return config;
}

export function prepareEmbedColumns(tableColumns, fieldsMeta) {
  const existentFields = [];
  tableColumns = tableColumns || [];
  tableColumns = cleanDeadFieldsFromLayoutColumn(tableColumns, fieldsMeta);
  existentFields.push(...tableColumns.map(({ name }) => name));
  tableColumns = createMissingLayoutFieldsForColumn(tableColumns, fieldsMeta, existentFields);
  tableColumns = createMissingLayoutFieldsParamsForColumn(tableColumns, fieldsMeta);

  return tableColumns;
}

export function prepareEmbedConfig(entityMeta, config = {}) {
  config.params = prepareFormParameters(config.params, entityMeta.fields, EMBED_PARAMS);
  config.locale = prepareFormLocalization(config.locale, entityMeta.fields);
  config.columns = prepareEmbedColumns(config.columns, entityMeta.fields);

  return config;
}
