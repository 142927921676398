<template>
  <div class="line-wrapper">
    <div class="line-number">{{ source.offset }}</div>
    <div class="line-content">{{ source.text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
.line-wrapper {
  display: flex;
  color: #f1f1f1;
  line-height: 20px;
  min-height: 20px;
  white-space: pre;
  box-sizing: border-box;
  padding-left: 16px;

  &:hover {
    background-color: #444;
  }

  .line-number {
    min-width: 40px;
    text-align: right;
    color: #666;
    padding-right: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
</style>
