import Keycloak from 'keycloak-js';
import { Cookies } from '@/helpers/cookies';

class AuthService {
  constructor() {
    this.keycloakParams = null;
    this.keycloak = null;
  }

  setKeycloakKeys() {
    localStorage.setItem('KK_AUTH', '1');

    localStorage.setItem('J_SESSION', this.keycloak.token || '');
    localStorage.setItem('J_REFRESH', this.keycloak.refreshToken || '');
    Cookies.set('J_SESSION', 1, this.keycloak.token || '');
    Cookies.set('J_REFRESH', 1, this.keycloak.refreshToken || '');
  }

  async initKeycloak(store) {
    this.keycloakParams = store.state.authConfig.providers.find(
      (provider) => !!provider.keycloak,
    )?.keycloak;
    if (!this.keycloakParams) return;

    // console.log('auth kk params', this.keycloakParams);
    this.keycloak = new Keycloak({
      realm: this.keycloakParams.realm,
      url: this.keycloakParams.url,
      clientId: this.keycloakParams.clientID,
    });

    // console.log('auth kk created', this.keycloak);
    store.mutate.setKeycloak(this.keycloak);

    this.keycloak.onTokenExpired = async () => {
      // console.log('auth kk token expired');
      try {
        await this.keycloak.updateToken(70);
        this.setKeycloakKeys();
      } catch (e) {
        console.error(`Failed to refresh token: ${e.message}`);
        return false;
      }
    };

    try {
      const authenticated = await this.keycloak.init({
        token: localStorage.getItem('J_SESSION') || undefined,
        refreshToken: localStorage.getItem('J_REFRESH') || undefined,
        redirectUri: process.env.VUE_APP_KK_LOGIN_REDIRECT || this.keycloakParams.redirectUri,
        checkLoginIframe: false,
        scope: this.keycloakParams.scope,
        pkce: true,
        pkceMethod: 'S256',
      });

      // console.log('auth kk authenticated: ', authenticated);
      if (authenticated) {
        this.setKeycloakKeys();

        // console.log('auth kk set update interval');
        setInterval(() => {
          this.keycloak.updateToken(70);
        }, 15e3);

        return true;
      }
    } catch (e) {
      console.error(`Failed to initialize Keycloak: ${e.message}`);
      this.requestError = `Failed to initialize Keycloak: ${e.message}`;
      return false;
    }

    return false;
  }

  updateToken() {
    // console.log('auth kk update token');
    return new Promise((resolve, reject) => {
      this.keycloak.updateToken(0).then(
        () => {
          // console.log('auth kk update token : success');
          this.setKeycloakKeys();
          if (!this.keycloak.authenticated || !this.keycloak.token || !this.keycloak.refreshToken)
            reject(new Error('Keykloack token update failed'));
          resolve();
        },
        () => {
          // console.log('auth kk update token : error');
          this.setKeycloakKeys();
          if (!this.keycloak.authenticated || !this.keycloak.token || !this.keycloak.refreshToken)
            reject(new Error('Keykloack token update failed'));
        },
      );
    });
  }
}

export default new AuthService();
