import gql from 'graphql-tag';

function getConfigFromReponse({ table }) {
  if (table) {
    if (table.totalCount > 0) {
      const data = table.documents[0].data;
      return {
        id: table.documents[0].id,
        title: data.title,
        data,
        form: typeof data.config === 'string' ? JSON.parse(data.config) : data.config,
      };
    }

    return null;
  }
}

export default {
  fetchPolicy: 'network-only',
  query: gql`
    query Config($name: Json) {
      table(
        pageSize: 1
        page: 0
        type: "Config"
        orders: []
        filters: [{ field: "title", value: $name, operator: EQUALS }]
      ) {
        totalCount
        documents {
          id
          data
        }
      }
    }
  `,
  update: getConfigFromReponse,
};
