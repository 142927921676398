export default {
  props: {
    focusOnMount: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.focusOnMount) {
      this.setFocus();
    }
  },
  methods: {
    getMountFocusElement() {
      return this.$refs.focusOnMount._isVue ? this.$refs.focusOnMount.$el : this.$refs.focusOnMount;
    },

    async setFocus() {
      const element = this.getMountFocusElement();
      element?.focus();
    },
  },
};
