<template>
  <div
    v-if="!loading"
    class="forms-list"
  >
    <div
      v-for="row in data.rows"
      :key="row.id"
      class="forms-list__form"
      :style="rowStyle"
    >
      <action-form
        :id="row.id"
        :data="row"
        :type="entityType"
        :extActionsMeta="actionsMeta"
      />
    </div>
  </div>
</template>

<script>
import store from '@/store';
import MetaService from '@/services/MetaService';
import ActionForm from '@/components/action-form/ActionForm.vue';

export default {
  name: 'FormsList',
  components: {
    ActionForm,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      actionsMeta: [],
    };
  },
  computed: {
    entityType() {
      return store.state.activeSidebarItem.code;
    },
    actions() {
      const actions = store.state.activeSidebarItem.customprops.form.actions;
      return actions.map((action) => {
        action = typeof action === 'string' ? { action } : action;
        return {
          ...action,
          title: this.getActionTitle(action),
          hasForm: this.actionHasForm(action),
        };
      });
    },
    height() {
      return store.state.activeSidebarItem.customprops?.form?.height || '300px';
    },
    rowStyle() {
      const height = typeof this.height === 'string' ? this.height : `${this.height}px`;
      return {
        height: `${height}`,
      };
    },
  },
  async created() {
    this.actionsMeta = await MetaService.getEntityActions(this.entityType, this.actions);
    this.loading = false;
  },
  methods: {
    getActionTitle({ action, title }) {
      return title || action;
    },
    actionHasForm({ action }) {
      const fields = this.actionsMeta[action]?.fieldsList;
      return fields?.filter((field) => field.renderer !== 'hidden').length > 0;
    },
  },
};
</script>

<style lang="scss">
.forms-list {
  &__form {
    @include scrollbars();
    background: #f9f9f9;
    overflow-y: auto;
    &:not(:first-child) {
      margin-top: 25px;
    }
  }

  .action-form-modal > :nth-child(2) {
    overflow-y: auto;
  }
}
</style>
