<template>
  <div class="page-count-config">
    {{ $t(`filter.pageSize`) }}
    <a-input-number v-model="pageSizeLocal" />
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'PageCountConfig',

  props: {
    value: {
      type: [Number, String],
      default: 10,
    },
  },

  computed: {
    pageSizeLocal: {
      get() {
        return this.value;
      },
      set(val) {
        if (!val) {
          this.emitValue(10);
          return;
        }

        this.emitValue(val);
      },
    },
  },

  methods: {
    emitValue: debounce(function emitValue(val) {
      this.$emit('input', val);
    }, 150),
  },
};
</script>

<style lang="scss">
.page-count-config {
  display: flex;
  align-items: center;
  padding-left: 20px;
  .ant-input-number {
    width: 56px;
    margin-left: 10px;
    height: 100%;
    margin-right: 8px;
    height: 24px;
    padding: 0 6px;
    text-align: center;
    input {
      height: 22px;
      text-align: center;
    }
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
}
</style>
