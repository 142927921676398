<template>
  <div :class="['entity-view', { 'entity-view--history': formMode === 'history' }]">
    <entity-view-head
      :id="id"
      :title="title"
      :type="type"
      :data="entityData"
      :showBackButton="!isModal"
      :hideHeadControls="isActionForm || isProcess"
      :formMode="formMode"
      @switchMode="switchMode"
      @openConfig="switchDrawer"
      @goBack="reemit('goBack', arguments)"
      @showHistoryView="reemit('showHistoryView', arguments)"
    >
      <template #additionalHeadControls>
        <slot name="additionalHeadControls"></slot>
      </template>
    </entity-view-head>

    <div class="entity-view__forms">
      <slot name="form1"></slot>
      <slot name="form2">
        <form-layout-provider
          v-if="formConfig"
          #default="{ formLayout }"
          :formConfig="formConfig"
          :fieldsMeta="fieldsMeta"
        >
          <div>
            <a-spin
              :spinning="!isRouteRefreshedAfterCreation && (!formLayout || !entityData)"
              class="entity-modal__spin"
            />
            <entity-form
              v-if="formLayout && entityData"
              :id="id"
              :type="type"
              :draftType="draftType || type"
              :formLayout="formLayout"
              :sourceData="entityData"
              :formConfig="formConfig"
              :descriptors="fieldsMeta"
              :readOnly="isFormReadOnly"
              :isModal="isModal"
              :canReadModel="canReadModel"
              :canCreateModel="canCreateModel"
              @close="reemit('goBack', arguments)"
              @duplicateEntity="duplicateEntity"
            >
              <template
                v-if="formMode === 'history'"
                #content
              >
                <div class="entity-view__compare">
                  <div class="entity-view__history">
                    <div
                      v-if="!hasHistory"
                      :class="[
                        'entity-view__history-item',
                        { 'entity-view__history-item--active': version === 'current' },
                      ]"
                      @click="selectVersion('current')"
                    >
                      <div>{{ $t('entity.currentVersion') }}</div>
                      <div>{{ getUserName(entityData.createdBy) }}</div>
                    </div>
                    <div
                      v-for="entry of history"
                      v-else
                      :key="entry.id"
                      :class="[
                        'entity-view__history-item',
                        { 'entity-view__history-item--active': version === entry.id },
                      ]"
                      @click="selectVersion(entry.id)"
                    >
                      <div>{{ formatDateTime(entry.date) }}</div>
                      <div>{{ getUserName(entry.userId) }}</div>
                    </div>
                  </div>
                  <div class="entity-view__compare-form">
                    <entity-form-json-compare
                      :loading="isHistoryDataLoading"
                      :rawSavedData="compareSavedData"
                      :rawCurrentData="compareCurrentData"
                      :meta="componentMeta"
                      @dataUpdated="dataUpdated"
                    />
                  </div>
                </div>
              </template>
              <template
                #bottomControls="{
                  save: updateSelf,
                  modelIntact,
                  hasVisibleFields,
                  duplicate,
                  deleteSelf,
                  requestError,
                }"
              >
                <template v-if="isProcess">
                  <a-button
                    v-if="hasVisibleFields"
                    :disabled="modelIntact"
                    type="primary"
                  >
                    {{ $t('jprocess.execute') }}
                  </a-button>
                </template>
                <template v-else-if="isActionForm">
                  <a-button
                    :disabled="modelIntact"
                    type="primary"
                    @click="$emit('save', entityData)"
                  >
                    {{ $t('entity.customFormSave') }}
                  </a-button>
                  <a-button
                    v-if="hasVisibleFields"
                    :disabled="modelIntact"
                    type="primary"
                    @click="resetForm"
                  >
                    {{ $t('entity.reset') }}
                  </a-button>
                </template>
                <div
                  v-else
                  class="entity-form__footer"
                >
                  <a-button
                    :disabled="modelIntact"
                    type="primary"
                    @click="updateSelf"
                  >
                    {{ $t('entity.save') }}
                  </a-button>
                  <a-button
                    v-if="hasVisibleFields"
                    :disabled="modelIntact"
                    type="primary"
                    @click="resetForm"
                  >
                    {{ $t('entity.reset') }}
                  </a-button>
                  <a-button
                    v-if="canDuplicateModel"
                    type="default"
                    @click="duplicate"
                  >
                    {{ $t('entity.duplicate') }}
                  </a-button>
                  <a-popconfirm
                    :disabled="!canDeleteModel && !isDraft"
                    :okText="$t('entity.deletionConfirm')"
                    :cancelText="$t('entity.deletionCancel')"
                    :title="$t('entity.deletionTitle')"
                    @confirm="deleteSelf"
                  >
                    <a-button
                      :disabled="!canDeleteModel && !isDraft"
                      type="primary"
                      ghost
                    >
                      {{ $t('entity.delete') }}
                    </a-button>
                  </a-popconfirm>
                  <a-alert
                    v-if="requestError"
                    class="entity-form__error"
                    :message="requestError"
                    type="error"
                    showIcon
                  />
                </div>
              </template>
            </entity-form>
          </div>
        </form-layout-provider>
      </slot>
    </div>

    <a-drawer
      class="settings-drawer"
      :visible="drawerVisible"
      :title="drawerTitle"
      :closable="false"
      :width="960"
      placement="right"
      @close="switchDrawer(false)"
    >
      <entity-form-config-modal
        :localConfig="embedConfigInEdit ? embedConfigInEdit.localConfig : formConfig"
        :descriptors="embedConfigInEdit ? embedConfigInEdit.descriptors : fieldsMeta"
        :embedded="!!embedConfigInEdit"
        @editFieldParams="switchFieldConfigDrawer"
      />
      <a-drawer
        class="settings-drawer"
        :title="fieldInConfig.name"
        :width="530"
        :closable="false"
        :visible="childrenDrawerVisble"
        @close="switchFieldConfigDrawer()"
      >
        <entity-form-config-field-config
          :isEmbedded="!!embedConfigInEdit"
          :type="embedConfigInEdit ? embedConfigInEdit.type : type"
          :value="fieldInConfig"
          :fieldsMeta="embedConfigInEdit ? embedConfigInEdit.descriptors : fieldsMeta"
        />
      </a-drawer>
    </a-drawer>
  </div>
</template>

<script>
import store from '@/store';
import GET_QUERY from '@/queries/get';
import TABLE_QUERY from '@/queries/table';
import HISTORY_QUERY from '@/queries/history';
import HISTORY_ENTRY_QUERY from '@/queries/historyEntry';
import FormConfigService from '@/services/FormConfigService';
import { appSettings } from '@/AppSettings';
import { bus, formatDateTime, escapeWysiwygHtmlBlocks } from '@/helpers';
import EntityViewHead from './EntityViewHead';
import EntityForm from './EntityForm';
import EntityFormConfigModal from './config/EntityFormConfigModal';
import EntityFormJsonCompare from './EntityFormJsonCompare';
import FormLayoutProvider from './FormLayoutProvider';
import EntityFormConfigFieldConfig from './config/EntityFormConfigFieldConfig';

export default {
  name: 'EntityView',

  components: {
    EntityViewHead,
    EntityForm,
    FormLayoutProvider,
    EntityFormConfigModal,
    EntityFormJsonCompare,
    EntityFormConfigFieldConfig,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    draftType: {
      type: String,
      default: '',
    },
    customTitle: {
      type: String,
      default: '',
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    isActionForm: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
    extFormConfig: {
      type: Object,
      default: null,
    },
    extMeta: {
      type: Array,
      default: null,
    },
    dataProcessor: {
      type: Function,
      default: null,
    },
    // для PublishableEntityModal
    noHistory: {
      type: Boolean,
      default: false,
    },
  },

  apollo: {
    history: {
      ...HISTORY_QUERY,
      variables() {
        return {
          entityName: this.type,
          entityId: this.id,
        };
      },
      update({ history }) {
        return history.entries.sort((a, b) => b.date - a.date);
      },
      skip() {
        return this.noHistory || this.isDraft || this.isProcess;
      },
      error(error) {
        this.emitError(this.$t('entity.error.getHistory'), error.message);
      },
    },
    usersNames: {
      ...TABLE_QUERY,
      errorPolicy: 'ignore',
      variables() {
        const pkField = store.state.meta.components.User.pk;

        return {
          type: 'User',
          page: 0,
          pageSize: 999,
          filters: [
            {
              field: pkField,
              operator: 'OR',
              filters: this.getHistoryUsersList().map((userId) => ({
                field: pkField,
                operator: 'EQUALS',
                value: userId,
              })),
            },
          ],
        };
      },
      update: ({ table }) =>
        table?.documents?.reduce((acc, user) => {
          acc[user.id] = user.data.username;
          return acc;
        }, {}) ?? [],
      skip() {
        return !this.entityData || !this.getHistoryUsersList().length || this.isProcess;
      },
      context: {
        customError(errors) {
          console.log('Custom error handling', errors);
          return [];
        },
      },
    },
    sourceData: {
      ...GET_QUERY,
      variables() {
        return {
          id: this.id,
          type: this.type,
        };
      },
      skip() {
        return this.isDraft || this.data || this.isProcess;
      },
      error(error) {
        this.emitError(this.$t('entity.error.get'), error.message);
      },
    },
    historyData: {
      ...HISTORY_ENTRY_QUERY,
      variables() {
        return {
          id: this.version,
        };
      },
      update: ({ historyEntry }) => historyEntry.payload,
      skip() {
        return !this.version || ['draft', 'current'].includes(this.version) || this.isProcess;
      },
      error(error) {
        this.emitError(this.$t('history.error.get'), error.message);
      },
    },
  },

  inject: ['isPublishable'],

  data() {
    return {
      embedConfigInEdit: null,
      isRouteRefreshedAfterCreation: false,
      formMode: 'edit',
      drawerVisible: false,
      childrenDrawerVisble: false,
      fieldInConfig: {},
      version: null,
    };
  },

  computed: {
    title() {
      let title = '';

      if (this.customTitle) {
        title = this.customTitle;
      } else if (this.entityData && this.formConfig) {
        title =
          this.entityData[this.formConfig.params.titleField?.key] ||
          this.formConfig.locale[store.state.lang].entity ||
          this.type;

        if (this.formMode === 'history') {
          title = this.$t('entity.title.historyMode', { title });
        }
      }

      return title || '';
    },
    drawerTitle() {
      if (this.embedConfigInEdit) {
        return this.embedConfigInEdit.type;
      }

      return this.type;
    },
    isProcess() {
      return !!store.state.activeSidebarItem?.isProcess;
    },
    componentMeta() {
      return this.isProcess
        ? store.state.meta.processes[this.type]
        : store.state.meta.components[this.type];
    },
    fieldsMeta() {
      return this.extMeta || this.componentMeta?.fields;
    },
    formConfig() {
      if (!store.state.activeSidebarItem) return null;
      if (this.extFormConfig) return this.extFormConfig;

      if (store.state.activeSidebarItem.isProcess) {
        return FormConfigService.getProcessFormConfig(this.type);
      }

      if (this.isPublishable) {
        return FormConfigService.getPublishableFormConfig(this.type, { fields: this.fieldsMeta });
      }

      return FormConfigService.getFormConfig(this.type, { fields: this.fieldsMeta });
    },
    operations() {
      return (!this.isActionForm && this.componentMeta.operations) || {};
    },
    canReadModel() {
      return this.operations.READ;
    },
    canCreateModel() {
      return this.operations.CREATE;
    },
    canUpdateModel() {
      return this.operations.UPDATE || this.isActionForm;
    },
    canDeleteModel() {
      return this.operations.DELETE;
    },
    canDuplicateModel() {
      return appSettings.get('entityDuplication') && this.canCreateModel;
    },
    isDraft() {
      return this.id.startsWith('_temp') || !!this.isProcess;
    },
    isFormReadOnly() {
      return !this.isDraft && !this.canUpdateModel;
    },
    draft() {
      return store.mutate.getFormDraft(this.type, this.id)?.data;
    },
    entityData() {
      let data = null;

      if (this.isDraft) {
        data = {};
      } else if (this.data) {
        data = this.data;
      } else if (this.sourceData?.document.data) {
        data = this.wysiwygHTMLBlocksPrepare(
          { ...this.sourceData.document.data },
          this.componentMeta,
        );
      }

      const dataProcessor =
        this.dataProcessor ||
        function dataProcessor(x) {
          return x;
        };

      return dataProcessor(
        data && {
          ...data,
          ...(this.draft || {}),
        },
      );
    },
    locale: {
      get() {
        const config = this.embedConfigInEdit
          ? this.embedConfigInEdit.localConfig
          : this.formConfig;
        return config.locale[store.state.lang];
      },
      set(value) {
        if (this.embedConfigInEdit) {
          this.$set(this.embedConfigInEdit.localConfig, store.state.lang, value);
        } else {
          this.$set(this.formConfig.locale, store.state.lang, value);
        }

        this.debouncedUpdate();
      },
    },
    hasHistory() {
      return this.history?.length > 0;
    },
    isHistoryDataLoading() {
      return this.$apollo.queries.historyData.loading;
    },
  },

  watch: {
    $route(to, from) {
      this.isRouteRefreshedAfterCreation =
        to.name === from.name &&
        to.params.type === from.params.type &&
        to.params.id &&
        from.params.id.startsWith('_temp');
    },
    historyData() {
      this.compareSavedData = { ...this.getCompareDataHistory() };
    },
  },

  created() {
    bus.$on('editEmbedConfig', (params) => {
      this.embedConfigInEdit = params;
      this.switchDrawer(true);
    });
  },

  methods: {
    formatDateTime,

    wysiwygHTMLBlocksPrepare(data, meta) {
      // На одном из проектов есть кривые эмбеды, игнорируем их
      if (!meta) return data;

      meta.fields.forEach((field) => {
        if (field.renderer === 'wysiwyg') {
          data[field.name] = data[field.name] && escapeWysiwygHtmlBlocks(data[field.name]);
        } else if (data[field.name] && ['embed', 'component'].includes(field.renderer)) {
          data[field.name] = data[field.name].map((dataItem) => ({
            type: dataItem.type,
            data: this.wysiwygHTMLBlocksPrepare(
              { ...dataItem.data },
              store.state.meta.components[dataItem.type],
            ),
          }));
        }
      });

      return data;
    },

    getUserName(id) {
      if (id === 'anonymous') return id;
      return (id && this.usersNames?.[id]) || '';
    },

    resetForm() {
      store.mutate.deleteFormDraft(this.draftType || this.type, this.id);

      bus.$emit('resetForm');
      if (this.formMode === 'history') {
        this.compareCurrentData = {};
        this.$nextTick(() => {
          this.openHistory();
        });
      }
    },

    duplicateEntity(type, id) {
      this.$emit('editEntity', type, id);
    },

    dataUpdated(data) {
      store.mutate.storeFormDraft(this.draftType || this.type, this.id, data);
    },

    switchMode(mode) {
      if (this.isPublishable) {
        this.$emit('switchMode', mode);
      }

      if (mode === 'history') {
        this.openHistory();
      }

      this.formMode = mode;
    },

    getCompareDataHistory() {
      if (this.isDraft) {
        return this.draft;
      }

      return this.hasHistory ? this.historyData : this.sourceData?.document.data;
    },

    getCompareDataCurrent() {
      if (this.isDraft) {
        return this.draft;
      }

      return (
        this.sourceData && {
          ...(this.sourceData?.document.data || {}),
          ...(this.draft || {}),
        }
      );
    },

    openHistory() {
      this.compareCurrentData = { ...this.getCompareDataCurrent() };
      this.compareSavedData = { ...this.getCompareDataHistory() };

      this.selectVersion(this.version || this.history?.[0]?.id || 'current');
    },

    switchDrawer(value = null) {
      this.drawerVisible = value ?? !this.drawerVisible;

      if (!this.drawerVisible) {
        this.embedConfigInEdit = null;
      }
    },

    switchFieldConfigDrawer(field = null) {
      this.childrenDrawerVisble = !!field;
      if (field) this.fieldInConfig = field;
    },

    selectVersion(entryId) {
      this.version = entryId;
    },

    getHistoryUsersList() {
      if (!this.entityData) return [];

      const list = (this.history || []).map((e) => e.userId);
      if (this.entityData.createdBy) {
        const id = this.entityData.createdBy?.value || this.entityData.createdBy;
        list.push(id.split(':')[1]);
      }

      return [...new Set(list)].filter((id) => id && id !== 'anonymous');
    },
  },
};
</script>

<style lang="scss">
.entity-view {
  &--history {
    height: 100%;
    .entity-view {
      &__head {
        flex: 0 0 auto;
      }

      &__forms {
        flex: 1 1 100%;
        min-height: 0;

        & > div {
          height: 100%;
        }
      }
    }

    .entity-form,
    .entity-form__content {
      height: 100%;
    }
  }

  &__compare {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
  }

  &__history {
    flex: 0 0 18%;
    overflow-y: auto;
    @include scrollbars();
  }

  &__history-item {
    line-height: 16px;
    cursor: pointer;

    :nth-child(2) {
      font-size: 90%;
    }

    &:not(:first-child) {
      margin-top: 10px;
    }

    &:hover {
      opacity: 0.75;
    }

    &--active {
      color: $antBlue;
    }
  }

  &__compare-form {
    flex: 0 0 80%;
    height: 100%;

    .json-compare,
    .json-compare > .ant-spin-nested-loading,
    .json-compare > .ant-spin-nested-loading > .ant-spin-container,
    .editor {
      height: 100% !important;
    }
  }
}

.settings-drawer {
  .ant-drawer-title {
    font-size: 21px;
  }

  .ant-drawer-wrapper-body {
    @include scrollbars();
    overflow-y: auto;
  }

  .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }

  .ant-drawer-header {
    padding: 40px 40px 0;
    border-bottom: none;
  }

  .ant-drawer-body {
    padding: 32px 40px 40px 40px;
  }

  .ant-drawer-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  .ant-form-item-control {
    line-height: initial;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
}
</style>
