export class Colors {
  static hexAToRGBAmap(hex) {
    const [r, g, b, a] = hex.slice(1).match(/.{1,2}/g);
    return {
      r: +`0x${r}`,
      g: +`0x${g}`,
      b: +`0x${b}`,
      a: +(`0x${a}` / 255).toFixed(3),
    };
  }

  static contrast(hex) {
    const { r, g, b } = Colors.hexAToRGBAmap(hex);
    const contrast = (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;
    return contrast >= 128 ? '#000000' : '#ffffff';
  }
}
