export function parseJson(value) {
  if (value && typeof value === 'string') {
    try {
      value = JSON.parse(value);
    } catch (e) {
      console.error(`Invalid JSON:\n${value}`);
      throw e;
    }
  }

  return value;
}
