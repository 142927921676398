<template>
  <section
    v-if="isItemNotExists"
    class="lada-item-card"
  >
    <header class="lada-item-card__header">
      <a-row
        type="flex"
        justify="space-between"
      >
        <a-col>
          {{ release.title }}
        </a-col>
      </a-row>
    </header>

    <section class="lada-item-card__section">
      <a-table
        class="table"
        :data-source="dataSource"
        :pagination="false"
        :showHeader="false"
      >
        <a-table-column
          v-for="column in columns"
          :key="column.key"
          :title="column.title"
          :dataIndex="column.dataIndex"
        >
          <template #default="value, entity">
            <template v-if="column.key === 'field'">
              <field-display
                :field="{ name: entity.field, renderer: 'string' }"
                :value="getLabel('Item', entity.field)"
              />
            </template>
            <template v-else-if="column.key === 'newValue'">
              <div
                v-if="value"
                @click="editCell($event.target, data, entity)"
              >
                <field-display
                  :field="getFieldConfig('Item', entity.field)"
                  :value="value"
                />
              </div>
            </template>
          </template>
        </a-table-column>
      </a-table>
      <div class="lada-item-card__buttons">
        <a-button
          type="primary"
          :disabled="release.status !== statuses['new']"
          @click="$emit('updateStatus', data.id, statuses['accepted'], filteredData)"
        >
          {{ $t('entity.save') }}
        </a-button>
        <a-button
          type="danger"
          :disabled="release.status !== statuses['new']"
          @click="$emit('updateStatus', data.id, statuses['rejected'], filteredData)"
        >
          {{ $t('entity.reject') }}
        </a-button>
      </div>
    </section>
    <a-popover
      :visible="editingCell.show"
      trigger="click"
      placement="bottomLeft"
      overlayClassName="table-edit-popover"
      :align="{
        target: editingCell.container,
        offset: editPopupOffset,
      }"
      @visibleChange="onEditPopoverVisibilityChange"
    >
      <template
        #content
        v-if="editingCell.show"
      >
        <table-cell-editable
          v-if="editingCell.show"
          v-model="editingCell.value"
          :column="editingCell.column"
          :entity="editingCell.entity"
          @stopEdit="stopEditCell"
        />
      </template>
    </a-popover>
  </section>
</template>

<script>
import TABLE_QUERY from '@/queries/table';
import store from '@/store';
import FormConfigService from '@/services/FormConfigService';
import lockScroll from '@/components/base/lockScroll.mixin';
import scrollElementInView from '@/components/base/scrollElementInView.mixin';
import FieldDisplay from '../FieldDisplay';
import TableCellEditable from '../../page/table/TableCellEditable.vue';

const statuses = {
  accepted: 'ACCEPTED',
  rejected: 'REJECTED',
  new: 'NEW',
};

export default {
  name: 'LadaItemCard',
  mixins: [lockScroll, scrollElementInView],

  components: {
    FieldDisplay,
    TableCellEditable,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    entityFields: {
      type: Array,
      default: () => [],
    },
  },

  apollo: {
    isItemNotExists: {
      ...TABLE_QUERY,
      loadingKey: 'loading',
      fetchPolicy: 'network-only',
      variables() {
        return {
          type: 'Item',
          page: 0,
          pageSize: 1,
          filters: [
            {
              field: 'partnumber',
              operator: 'EQUALS',
              value: this.release.partnumber,
            },
          ],
        };
      },
      skip() {
        return !this.release.partnumber;
      },
      update({ table }) {
        return !table.documents.length;
      },
      error(error) {
        this.emitError(this.$t('entity.error.getTable'), error.message);
      },
    },
  },
  data() {
    return {
      isLoading: false,
      release: this.data.data,
      statuses,
      tableConfig: this.getTableConfig(),

      editingCell: {
        show: false,
        entity: null,
        column: null,
        value: null,
        container: null,
      },
    };
  },

  computed: {
    fieldKeys() {
      const removedKeys = ['id', 'status'];
      return Object.keys(this.release).filter((key) => !removedKeys.includes(key));
    },
    filteredData() {
      const { id: _, ...rest } = this.release;
      return rest;
    },

    dataSource() {
      return this.fieldKeys.map((key, idx) => ({
        ...this.entityFields.find((el) => el.name === key),
        key: idx,
        field: key,
        newValue: this.release[key],
      }));
    },
    editPopupOffset() {
      const renderer = this.editingCell.column?.renderer;
      const rowHeight = 46;
      const padd = 10;
      let offset = [-28, -rowHeight + padd];
      if (renderer === 'color') offset = [-16, -rowHeight + padd];
      else if (['ref', 'refs'].includes(renderer)) offset = [-10, -rowHeight - 3 + padd];

      return offset;
    },
    columns() {
      return [
        {
          title: '',
          dataIndex: 'field',
          key: 'field',
        },
        {
          title: this.$t('lada.newData'),
          dataIndex: 'newValue',
          key: 'newValue',
          scopedSlots: { customRender: 'newValue' },
        },
      ];
    },
  },

  watch: {
    data() {
      this.release = { ...this.data.data };
    },
  },

  methods: {
    canEditColumn(column) {
      return !['id', 'partnumber', 'wh'].includes(column);
    },
    getFieldConfig(type, name) {
      const field = store.state.meta.entities
        .find((e) => e.name === type)
        .fields.find((f) => f.name === name);

      return field || { name, renderer: 'string' };
    },
    getLabel(entity, name) {
      return (
        FormConfigService.getFormConfig(entity).locale[store.state.lang].fields[name]?.label || name
      );
    },
    getTableConfig() {
      return {
        displayType: {
          value: 'inline-table',
        },
        displayFields: [
          {
            name: 'field',
            label: '',
            index: 'field',
            renderer: 'string',
            sortable: false,
          },
          {
            name: 'newValue',
            label: this.$t('lada.newData'),
            index: 'newValue',
            renderer: 'string',
            sortable: false,
          },
        ],
      };
    },

    async editCell(container, entity, column) {
      if (this.canEditColumn(column.field)) {
        if (this.editingCell.show) {
          this.stopEditCell();
        }
        const column1 = {
          ...column,
          name: column.field,
          label: this.getLabel('Item', column.field),
          index: column.field,
          sortable: false,
          defaultSortOrder: null,
        };

        await this.scrollElementInView(container, this.getMarginsByRenderer(column.renderer));
        this.lockEntityFormScroll();

        this.editingCell = {
          show: true,
          entity: { ...entity, type: 'NewItem' },
          column: column1,
          container,
          value: column.newValue,
        };
      }
    },
    onEditPopoverVisibilityChange(visible) {
      if (!visible && this.editingCell.show) {
        this.stopEditCell();
      }
    },

    stopEditCell() {
      this.lockEntityFormScroll(false);
      this.release[this.editingCell.column.name] = this.editingCell.value;

      this.editingCell = {
        show: false,
        entity: null,
        column: null,
        value: null,
        container: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.lada-item-card {
  border: 1px solid #e8e8e8;
  width: 100%;

  .ant-form-item-control {
    line-height: 32px;
  }
  &__header {
    color: $darkTextColor;
    padding: 16px 22px;
    font-weight: 500;
    background: #fafafa;
    border-bottom: 1px solid #e8e8e8;
  }

  &__buttons {
    margin: 16px 22px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
  }

  // .ant-collapse-borderless >
  .ant-collapse-item:last-child {
    border-bottom: none;
  }

  .custom-entity-field__string,
  .custom-entity-field > .tags {
    margin-bottom: 0;
  }
}

.table {
  color: $darkTextColor;

  .ant-table-row {
    height: 55px;
  }

  td {
    white-space: nowrap;
  }

  &--can-read {
    tbody tr {
      cursor: pointer;
    }
  }

  .ant-table-column-title {
    white-space: nowrap;
  }

  &__text {
    max-width: 300px;
    min-height: 1.6em;

    &--short {
      max-width: 50px;
    }
  }

  &__color-field {
    width: 122px;
    margin: -1px 0;
  }

  &__actions {
    visibility: hidden;
    text-align: right;
  }

  &__gallery {
    margin: -20px 0px;
    max-width: 300px;

    img {
      & + img {
        margin-left: 5px;
      }
    }
  }

  tr:hover > td > .table__actions {
    visibility: visible;
  }

  .ant-table-placeholder {
    display: none;
  }

  &.ant-table-wrapper {
    @include scrollbars();
    overflow-x: auto;
  }

  // For editable cells
  .ant-form-item {
    margin: 0;
  }
  .ant-form-item-control {
    line-height: 24px;
  }
}

.table-edit-popover {
  padding-top: 0;

  &:not(.table-edit-popover__no-icon) {
    .ant-popover-inner-content {
      padding-right: 2px;
    }
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-control {
    line-height: 32px;
  }
}
</style>
