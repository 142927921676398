<template>
  <div class="so-course-preview">
    <h2 class="so-course-preview__h2">{{ $t('SOCourse.title') }}</h2>
    <div class="so-course-preview__title">{{ name }}</div>

    <div class="so-course-preview__fields">
      <template v-if="data.id">
        <h3>{{ getFieldsLabel('id') }}</h3>
        <p>{{ data.id }}</p>
      </template>
      <template v-if="data.description">
        <h3>{{ getFieldsLabel('description') }}</h3>
        <p>{{ data.description }}</p>
      </template>
      <template v-if="data.about">
        <h3>{{ getFieldsLabel('about') }}</h3>
        <p>{{ data.about }}</p>
      </template>
      <template v-if="data.schoolId">
        <h3>{{ getFieldsLabel('schoolId') }}</h3>
        <p>{{ data.schoolId.title }}</p>
      </template>
      <template v-if="data.created_at">
        <h3>{{ getFieldsLabel('created_at') }}</h3>
        <p>{{ formatDateTime(data.created_at) }}</p>
      </template>
      <template v-if="data.updated_at">
        <h3>{{ getFieldsLabel('updated_at') }}</h3>
        <p>{{ formatDateTime(data.updated_at) }}</p>
      </template>
      <template v-if="data.deleted_at">
        <h3>{{ getFieldsLabel('deleted_at') }}</h3>
        <p>{{ formatDateTime(data.deleted_at) }}</p>
      </template>
      <template v-if="data.deleted_at">
        <h3>{{ getFieldsLabel('deleted_at') }}</h3>
        <p>{{ formatDateTime(data.deleted_at) }}</p>
      </template>
      <template v-if="data.video_url">
        <h3>{{ getFieldsLabel('video_url') }}</h3>
        <base-video :path="data.video_url" />
      </template>
      <template v-if="data.image">
        <h3>{{ getFieldsLabel('image') }}</h3>
        <base-image :path="data.image" />
      </template>
      <template v-if="data.has_certificate !== null || data.has_certificate !== undefined">
        <a-checkbox :checked="data.has_certificate">
          {{ getFieldsLabel('has_certificate') }}
        </a-checkbox>
      </template>
      <template v-if="data.duration">
        <h3>{{ getFieldsLabel('duration') }}</h3>
        <p>{{ data.duration }}</p>
      </template>
      <template v-if="data.rating">
        <h3>{{ getFieldsLabel('rating') }}</h3>
        <p>{{ data.rating }}</p>
      </template>
      <template v-if="data.popularity">
        <h3>{{ getFieldsLabel('popularity') }}</h3>
        <p>{{ data.popularity }}</p>
      </template>
      <template v-if="data.formats">
        <h3>{{ getFieldsLabel('formats') }}</h3>
        {{ data.formats }}
      </template>
      <template v-if="data.intensity">
        <h3>{{ getFieldsLabel('intensity') }}</h3>
        <p>{{ data.intensity }}</p>
      </template>
      <template v-if="data.start_date">
        <h3>{{ getFieldsLabel('start_date') }}</h3>
        <p>{{ formatDateTime(data.start_date) }}</p>
      </template>
      <template v-if="data.end_date">
        <h3>{{ getFieldsLabel('end_date') }}</h3>
        <p>{{ formatDateTime(data.end_date) }}</p>
      </template>
      <template v-if="data.skills">
        <h3>{{ getFieldsLabel('skills') }}</h3>
        <pre>{{ JSON.stringify(parseJson(data.skills), null, 2) }}</pre>
      </template>
      <template v-if="data.targets">
        <h3>{{ getFieldsLabel('targets') }}</h3>
        <pre>{{ JSON.stringify(parseJson(data.targets), null, 2) }}</pre>
      </template>
      <template v-if="definedTermPrice">
        <h3>{{ getFieldsLabel('priceProductsProductId') }}</h3>
        <p>{{ definedTermPrice.toLocaleString() }}</p>
      </template>
    </div>

    <div v-if="sections.length">
      <div
        v-for="section in sections"
        :key="section.id"
        class="so-course-preview__lesson so-course-preview-lesson"
      >
        <h3 class="so-course-preview__lesson-name">{{ section.name }}</h3>
        <p class="so-course-preview__lesson-description">{{ section.description }}</p>
        <template v-for="lesson in section.lessons">
          <h3
            :key="'h3' + lesson.id"
            class="so-course-preview__lesson-name"
          >
            {{ lesson.name }}
          </h3>
          <p
            :key="'p' + lesson.id"
            class="so-course-preview__lesson-description"
          >
            {{ lesson.description }}
          </p>
          <template v-for="item of lesson.data">
            <h3
              v-if="item.type === 'header'"
              :key="item.type + item.id"
            >
              {{ item.data.text }}
            </h3>
            <base-video
              v-else-if="item.type === 'video'"
              :key="item.type + item.id"
              :path="item.data.url"
            />
            <base-audio
              v-else-if="item.type === 'audio'"
              :key="item.type + item.id"
              :path="item.data.url"
            />
            <so-course-html
              v-else-if="item.type === 'paragraph'"
              :key="item.type + item.id"
              :element="item.data.htmlTree || item.data.text"
            />
            <div
              v-else-if="item.type === 'test'"
              :key="item.type + item.id"
              class="so-course-preview__question"
            >
              <h4 class="so-course-preview__question-title">{{ item.data.question }}</h4>
              <p class="so-course-preview__question-description">{{ item.data.description }}</p>
              <div>
                <a-radio-group v-if="item.data.test_type === 'single'">
                  <a-radio
                    v-for="answer in item.data.variants_answers"
                    :key="answer.id"
                    :style="radioStyle"
                    :value="answer.id"
                  >
                    {{ answer.text }}
                  </a-radio>
                </a-radio-group>
                <a-checkbox-group v-else-if="item.data.test_type === 'multiple'">
                  <a-checkbox
                    v-for="answer in item.data.variants_answers"
                    :key="answer.id"
                    :style="radioStyle"
                    :value="answer.id"
                  >
                    {{ answer.text }}
                  </a-checkbox>
                </a-checkbox-group>
              </div>
            </div>
            <a-button
              v-else-if="item.type === 'file'"
              :key="item.type + item.id"
              type="primary"
              :href="item.data.url"
              target="_blank"
              style="
                max-width: 100%;
                margin: 15px 0;
                padding: 5px 20px;
                height: auto;
                white-space: pre-line;
                line-height: 20px;
              "
            >
              {{ $t('SOCourse.title') }}Скачать файл "{{ item.data.text }}"
            </a-button>
            <div
              v-else-if="item.type === 'image'"
              :key="item.type + item.id"
            >
              <img
                alt="image"
                loading="lazy"
                :src="item.data.file.url"
                style="max-width: 100%; margin: 15px 0"
              />
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import TABLE_QUERY from '@/queries/table';
import { formatDateTime, parseJson } from '@/helpers';
import FormConfigService from '@/services/FormConfigService';
import BaseAudio from '@/components/base/BaseAudio';
import BaseImage from '@/components/base/BaseImage';
import BaseVideo from '@/components/base/BaseVideo';
import SoCourseHtml from './SoCourseHtml';

export default {
  name: 'SoCoursePreview',

  components: {
    BaseAudio,
    BaseImage,
    BaseVideo,
    SoCourseHtml,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  apollo: {
    definedTermPrice: {
      ...TABLE_QUERY,
      variables() {
        return {
          page: 0,
          pageSize: 1,
          type: 'PriceProducts',
          filters: [
            {
              field: 'platformId',
              operator: 'EQUALS',
              value: `SOCourse:${this.data.id}`,
            },
          ],
        };
      },
      update({ table }) {
        return table.documents[0]?.data.definedTermPrice;
      },
    },
  },

  data() {
    return {
      radioStyle: {
        display: 'block',
        lineHeight: '22px',
        marginTop: '8px',
        leftTop: '0px',
        width: '100%',
        maxWidth: '100%',
        whiteSpace: 'pre-line',
      },
    };
  },

  computed: {
    formConfig() {
      return FormConfigService.getFormConfig('SOCourse');
    },
    name() {
      return this.data?.content?.course?.name || '';
    },
    course() {
      return this.data?.content?.course || {};
    },
    sections() {
      const course = this.course;
      const sections = course?.course_tree?.sections || [];
      const lessons_content = course?.lessons_content || [];
      if (sections.length) {
        return (
          sections.map((section) => ({
            ...section,
            lessons: section.lessons.map((lesson) => ({
              ...lesson,
              data:
                lessons_content.find((lc) => lc.lesson_id === lesson.id)?.lesson_attributes || '',
            })),
          })) || []
        );
      }
      return [
        {
          name: this.name,
          lessons:
            course?.course_tree?.lessons.map((lesson) => ({
              ...lesson,
              data:
                course?.lessons_content.find((lc) => lc.lesson_id === lesson.id)
                  ?.lesson_attributes || '',
            })) || [],
        },
      ];
    },
  },
  methods: {
    formatDateTime,
    parseJson,

    downloadFile(url) {
      window.location.href = url;
    },

    getFieldsLabel(field) {
      return this.formConfig.locale[store.state.lang].fields[field.label] || field;
    },
  },
};
</script>

<style lang="scss">
.so-course-preview {
  &__h2 {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }

  &__title {
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
  }

  &__fields {
    margin-top: 16px;

    h3 {
      margin-bottom: 0;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }

    pre {
      font-size: 12px;
    }

    .ant-checkbox-wrapper,
    .ant-checkbox-input {
      cursor: default;
    }

    .ant-checkbox-inner {
      border-color: #d9d9d9 !important;
    }
  }

  &__lessons-list {
    counter-reset: lesson;
  }

  &__lesson {
    margin-top: 36px;
  }

  &__question {
    margin-top: 24px;

    &-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }
    &-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.45);
    }
    .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
}

.so-course-preview-lesson {
  position: relative;
  padding-left: 48px;
  counter-increment: lesson;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #1890ff;
    line-height: 32px;
    text-align: center;
    content: counter(lesson);
    color: #fff;
  }

  &::after {
    position: absolute;
    top: 38px;
    bottom: 0;
    left: 15.5px;
    border-right: 1px solid #1890ff;
    content: '';
  }

  h3 {
    margin-bottom: 4px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 700;
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  &__lesson-description {
    margin-top: 24px;
    margin-bottom: 0;
    line-height: 22px;
    font-size: 14px;
    color: #8c8c8c;
  }
}
</style>
