<template>
  <error-output
    class="gpoint"
    :style="wrapperStyle"
    :errors="errors"
  >
    <a-input
      ref="focusOnMount"
      class="gpoint__input"
      :value="input"
      :size="size"
      @input="inputChanged"
    />
    <div
      ref="map"
      :style="containerStyle"
    ></div>
  </error-output>
</template>

<script>
import FocusOnMountMixin from '@/components/base/focusOnMount.mixin';
// import { Loader } from '@googlemaps/js-api-loader';
import { uniqueId } from '@/helpers';
import ErrorOutput from '@/components/base/ErrorOutput';
import { appSettings } from '@/AppSettings';

// const gmapsLoader = new Loader({
//   apiKey: 'AIzaSyC7kOw5QUPzAN7A3rsdQgtMq262idxyUvs',
//   // version: "weekly",
//   // ...additionalOptions,
// });

export default {
  name: 'EditFormGpoint',
  mixins: [FocusOnMountMixin],
  components: {
    ErrorOutput,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Array],
      default: undefined,
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'default',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mapId: uniqueId('map'),
      map: null,
      defaultMapCenterCoords: {
        lat: 55.75368949910336,
        lng: 37.619910616697794,
      },
    };
  },
  computed: {
    input: {
      get() {
        return (this.value || '').replace(/[()]/g, '').replace(/,/, ', ');
      },
      set(value) {
        value = typeof value === 'string' ? value : `${value.lat()},${value.lng()}`;
        this.$emit('input', value);
      },
    },
    markerCoords() {
      const coords = this.input.replace(/\s/g, '').split(',');
      if (coords.length < 2) return null;
      return {
        lat: Number(coords[0]),
        lng: Number(coords[1]),
      };
    },
    wrapperStyle() {
      return {
        width: this.width || this.config.mapWidth,
      };
    },
    containerStyle() {
      return {
        height: this.height || this.config.mapHeight,
      };
    },
  },
  watch: {
    markerCoords() {
      this.setMarkerPosition();
    },
  },
  mounted() {
    // gmapsLoader.load().then(() => {
    this.loadApi();
    // });
  },
  methods: {
    loadApi() {
      if (!window.google?.maps) {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${appSettings.get(
          'gmapsApiKey',
        )}&callback=initMap`;

        window.initMap = () => {
          this.initMap();
        };

        document.head.appendChild(script);
      } else {
        this.initMap();
      }
    },
    initMap() {
      this.map = new window.google.maps.Map(this.$refs.map, {
        center: this.markerCoords || this.defaultMapCenterCoords,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        zoom: 8,
      });

      this.map.addListener('click', this.mapClicked);

      if (this.markerCoords) {
        this.createMarker();
      }
    },
    inputChanged({ target }) {
      this.input = target.value;
      this.$nextTick(() => {
        this.setMapCenter();
      });
    },
    mapClicked({ latLng }) {
      this.input = latLng;
    },
    setMarkerPosition() {
      if (!this.map) return;
      if (this.markerCoords) {
        if (!this.marker) {
          this.createMarker(this.markerCoords);
        } else {
          this.marker.setMap(this.map);
          this.marker.setPosition(this.markerCoords);
        }
      } else {
        this.marker.setMap(null);
      }
    },
    setMapCenter() {
      if (this.map && this.markerCoords) this.map.setCenter(this.markerCoords);
    },
    createMarker() {
      this.marker = new window.google.maps.Marker({
        position: this.markerCoords,
        map: this.map,
      });
    },
  },
};
</script>

<style lang="scss">
.gpoint {
  &__input.ant-input {
    margin-bottom: 15px;
  }
}
</style>
