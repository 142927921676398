import gql from 'graphql-tag';

export default {
  fetchPolicy: 'network-only',
  query: gql`
    query Table(
      $type: String
      $page: Int! = 0
      $pageSize: Int! = 1
      $orders: [InputOrder]
      $filters: [InputFilter]
    ) {
      table(pageSize: $pageSize, page: $page, type: $type, orders: $orders, filters: $filters) {
        totalCount
        hasMore
        documents {
          id
          deleted
          data
          snapshot
        }
      }
    }
  `,
  error(error) {
    this.emitError(this.$t('entity.error.get'), error.message);
  },
};
