<template>
  <div class="fields-config">
    <a-popover
      v-model="showConfig"
      trigger="click"
      placement="bottomLeft"
    >
      <a-button
        class="type-config__button"
        :size="controlsSize"
      >
        <a-icon :type="icon" />
      </a-button>
      <template #content>
        <div class="fields-config__popover">
          <slot name="header"></slot>
          <div class="filter__popover">
            <page-config-fields-list
              v-model="list"
              class="fields-list"
              :entityType="entityType"
              :entityFields="entityFields"
              @configUpdated="configUpdated"
            />
          </div>
        </div>
      </template>
    </a-popover>
  </div>
</template>

<script>
import configFormMixin from './configFormMixin';
import PageConfigFieldsList from './PageConfigFieldsList.vue';

export default {
  name: 'FieldsConfig',
  mixins: [configFormMixin],
  components: {
    PageConfigFieldsList,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    controlsSize: {
      type: String,
      default: 'large',
    },
    icon: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showConfig: false,
    };
  },
  computed: {
    list: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss">
.fields-config {
  height: 100%;

  &__popover-footer {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid #222;
  }
}
</style>
