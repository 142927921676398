<template>
  <vuedraggable
    v-model="model"
    v-bind="options"
    :group="group"
    :disabled="disabled"
    @change="$emit('change', $event)"
    @start="$emit('startDrag', $event)"
    @end="$emit('stopDrag', $event)"
    @move="$emit('move', $event)"
  >
    <slot></slot>
  </vuedraggable>
</template>

<script>
import vuedraggable from 'vuedraggable';

export default {
  name: 'DraggableList',
  components: {
    vuedraggable,
  },

  props: {
    group: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update', value);
      },
    },
  },
};
</script>
