import store from '@/store';
import { isSystemField, getFieldLabel } from '@/helpers';
import FormConfigService from '@/services/FormConfigService';
import { kanbanDisplay, KANBAN_FIELD_RENDERERS } from './configFormLayoutKanbanDisplay';

function getFieldsEnumOptionsByRenderers(fieldsMeta, targetRenderers, entityType) {
  return fieldsMeta
    .filter((field) => field.config?.searchable || targetRenderers.includes(field.renderer))
    .map((field) => ({
      value: field.name,
      label: getFieldLabel(
        FormConfigService.getFormConfig(entityType).locale[store.state.lang].fields,
        field,
        store.state.lang,
      ),
    }));
}

function getFieldsEnumDefaultByRenderers(fieldsMeta, targetRenderers) {
  return fieldsMeta.filter((field) => targetRenderers.includes(field.renderer));
}

const SEARCH_ELIGIBLE_FIELDS = ['string', 'text', 'wysiwyg', 'integer'];
const DATE_FIELD_RENDERERS = ['date', 'date-time'];

export const CONFIG_LAYOUT = {
  searchField: {
    renderer: 'enum',
    options(fieldsMeta, entityType) {
      return getFieldsEnumOptionsByRenderers(fieldsMeta, SEARCH_ELIGIBLE_FIELDS, entityType).filter(
        (column) => column.value !== 'id',
      );
    },
    default(fieldsMeta) {
      const defParam = getFieldsEnumDefaultByRenderers(fieldsMeta, SEARCH_ELIGIBLE_FIELDS)[0];
      return defParam?.name !== 'id' ? defParam?.name : '';
    },
  },
  pageSize: {
    renderer: 'number',
    default: 10,
  },
  columnWidth: {
    renderer: 'string',
    default: '250',
  },
  displayFields: {
    renderer: 'fields-list',
    default(fieldsMeta, _entityType, tableConfig) {
      const meta = !tableConfig
        ? fieldsMeta
        : fieldsMeta.filter(({ name }) => !tableConfig.columns[name]?.hidden);

      let list = meta.map(({ name }) => ({
        name,
        hidden: false,
      }));

      if (tableConfig) {
        list = list.concat(
          Object.keys(tableConfig.columns)
            .filter(({ hidden }) => !hidden)
            .map((name) => ({
              name,
              hidden: false,
            })),
        );
      }

      return [...new Set(list)];
    },
  },
  displayType: {
    renderer: 'conditional-form',
    options(fieldsMeta) {
      const displayTypes = [
        {
          value: 'table',
          options: {},
        },
        {
          value: 'inline-table',
          options: {},
        },
        {
          value: 'tiles',
          options: {},
        },
      ];

      const fieldTypesCount = fieldsMeta.reduce((acc, field) => {
        // if (!isSystemField(field)) {
        acc[field.renderer] = (acc[field.renderer] || 0) + 1;
        // }

        return acc;
      }, {});

      if (
        KANBAN_FIELD_RENDERERS.reduce((acc, renderer) => acc + (fieldTypesCount[renderer] || 0), 0)
      ) {
        displayTypes.push(kanbanDisplay);
      }

      if (
        DATE_FIELD_RENDERERS.reduce((acc, renderer) => acc + (fieldTypesCount[renderer] || 0), 0)
      ) {
        const dateViewsDisplayOptions = {
          startDate: {
            renderer: 'enum',
            localizationKey: 'page.displayOptions.startDate',
            nonEmpty: true,
            options(entityFields, entityType) {
              entityFields = entityFields.filter((field) => !isSystemField(field));
              return getFieldsEnumOptionsByRenderers(
                entityFields,
                ['date', 'date-time'],
                entityType,
              );
            },
            default(entityFields) {
              entityFields = entityFields.filter((field) => !isSystemField(field));
              return getFieldsEnumDefaultByRenderers(entityFields, ['date', 'date-time'])[0]?.name;
            },
          },
          endDate: {
            renderer: 'enum',
            localizationKey: 'page.displayOptions.endDate',
            options(entityFields, entityType) {
              entityFields = entityFields.filter((field) => !isSystemField(field));
              return getFieldsEnumOptionsByRenderers(
                entityFields,
                ['date', 'date-time'],
                entityType,
              );
            },
            default(entityFields) {
              entityFields = entityFields.filter((field) => !isSystemField(field));
              return getFieldsEnumDefaultByRenderers(entityFields, ['date', 'date-time']).slice(
                -1,
              )[0]?.name;
            },
          },
        };

        displayTypes.push({
          value: 'calendar',
          options: dateViewsDisplayOptions,
        });

        displayTypes.push({
          value: 'gantt',
          options: dateViewsDisplayOptions,
        });
      }

      if (fieldTypesCount.gpoint) {
        const displayOptions = {
          coordsField: {
            renderer: 'enum',
            localizationKey: 'page.displayOptions.coords',
            nonEmpty: true,
            options(entityFields, entityType) {
              entityFields = entityFields.filter((field) => !isSystemField(field));
              return getFieldsEnumOptionsByRenderers(entityFields, ['gpoint'], entityType);
            },
            default(entityFields) {
              entityFields = entityFields.filter((field) => !isSystemField(field));
              return getFieldsEnumDefaultByRenderers(entityFields, ['gpoint'])[0]?.name;
            },
          },
        };

        displayTypes.push({
          value: 'map',
          options: displayOptions,
        });
      }

      return displayTypes;
    },
  },
};

async function getDefaultParamValue(param, fieldsMeta, entityType, tableConfig) {
  return typeof param.default === 'function'
    ? param.default(fieldsMeta, entityType, tableConfig)
    : param.default;
}

async function getParamOptions(param, fieldsMeta) {
  return typeof param.options === 'function' ? param.options(fieldsMeta) : param.options;
}

export async function prepareConfig(config, fieldsMeta, configLayout, entityType, tableConfig) {
  config = config || {};

  // Clean outdated
  Object.keys(config).forEach((key) => {
    if (configLayout[key] === undefined) delete config[key];
  });

  // Fill missing/default params
  const promises = Object.entries(configLayout).map(async ([key, param]) => {
    if (param.renderer === 'form') {
      config[key] = prepareConfig(config[key], param.options, null, entityType, tableConfig);
    } else if (param.renderer === 'conditional-form') {
      const storedValue = config[key]?.value;
      const paramOptions = await getParamOptions(param, fieldsMeta);
      const configuredValue = paramOptions.find((opt) => opt.value === storedValue)
        ? storedValue
        : paramOptions.length > 0 && paramOptions[0].value;
      const configuredOptions = await prepareConfig(
        storedValue && paramOptions.find((option) => option.value === storedValue)
          ? config[key]?.options
          : {},
        fieldsMeta,
        paramOptions.find((option) => option.value === configuredValue)?.options,
        entityType,
        tableConfig,
      );
      config[key] = {
        value: configuredValue,
        options: configuredOptions,
      };
    } else if (config[key] === undefined) {
      config[key] = await getDefaultParamValue(param, fieldsMeta, entityType);
    } else if (param.renderer === 'fields-list') {
      const defaultFields = await getDefaultParamValue(param, fieldsMeta, entityType, tableConfig);

      const defaultFieldsNames = defaultFields.map((def) => def.name);
      const storedFieldsNames = config[key]
        .filter((conf) => defaultFieldsNames.includes(conf.name))
        .map((conf) => conf.name);

      const orderedFieldsNames = [...new Set([...storedFieldsNames, ...defaultFieldsNames])];
      config[key] = orderedFieldsNames.map((name) => ({
        ...defaultFields.find((def) => def.name === name),
        ...config[key].find((conf) => conf.name === name),
      }));
    }
  });

  await Promise.all(promises);
  return config;
}
