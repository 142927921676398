<template>
  <error-output :errors="errors">
    <a-checkbox-group
      v-model="items"
      :disabled="disabled || readOnly"
      :class="['checkboxes readOnly', { readOnly }]"
      :options="selectOptions"
    />
  </error-output>
</template>

<script>
import { XHR, parseJson } from '@/helpers';
import ErrorOutput from '@/components/base/ErrorOutput';

export default {
  name: 'RefCheckboxes2',
  components: {
    ErrorOutput,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, Array, String],
      default: undefined,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    parentType: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      loadingKeys: 0,
      options: [],
    };
  },
  computed: {
    metaConfig() {
      // в publishanble формах вместо config -- conf
      return this.config.config || this.config.conf;
    },
    multiple() {
      const multiple = this.metaConfig.multiple;
      // в publishanble формах multiple лежит не в config/conf а на уровень выше
      return multiple !== undefined ? multiple : this.config.multiple;
    },
    items: {
      get() {
        let value = this.value;
        if (value && !this.multiple) value = [value];
        return value || [];
      },
      set(value) {
        let emited = value;
        if (!this.multiple && emited) {
          emited = emited.filter((v) => !this.items.includes(v))[0] || null;
        }

        this.$emit('input', emited);
      },
    },
    selectOptions() {
      return this.options.map(this.getOptionKeyAndTitleForSelect);
    },
  },
  created() {
    let urls = this.metaConfig.urls;
    this.loadingKeys++;
    this.options = [];

    if (this.metaConfig.handbooks) {
      this.options = this.formState.handbooks[this.metaConfig.handbooks];
      this.loadingKeys--;
      return;
    }

    if (!urls) urls = [this.metaConfig.url];
    if (this.search) urls = urls.map((url) => `${url}?search=${this.search}`);

    Promise.all(
      urls.map(
        (url) =>
          new Promise((resolve) => {
            XHR.get(url, { absoluteUrl: true }).then(
              (response) => {
                let options = parseJson(response);
                if (this.parentType === 'User' && this.config.name === 'roles') {
                  options = options.filter((item) => item !== 'ROLE_AUTHOR');
                }

                resolve(options);
              },
              () => {
                this.emitError(
                  this.$t(`base.autocompleteErrorOptions`, { field: this.config.field }),
                );

                resolve([]);
              },
            );
          }),
      ),
    ).then((results) => {
      this.options = results.flat();
      this.loadingKeys--;
    });
  },
  methods: {
    getOptionKeyForSelect(rawValue) {
      if (typeof rawValue === 'string') {
        return rawValue;
      }

      if (
        Object.keys(rawValue).length === 2 &&
        'title' in rawValue &&
        'value' in rawValue &&
        typeof rawValue.value === 'string'
      ) {
        return rawValue.value;
      }

      return rawValue.title;
    },
    getOptionKeyAndTitleForSelect(rawValue) {
      return {
        x: 6,
        label: typeof rawValue === 'string' ? rawValue : rawValue.title,
        value: this.getOptionKeyForSelect(rawValue),
      };
    },
  },
};
</script>
