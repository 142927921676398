import gql from 'graphql-tag';
import { kebabize } from '@/helpers/format';

function prepareFieldsMeta(fields, config) {
  const fieldsDict = fields.reduce((dict, field) => {
    let type = field.type;
    let required = false;
    let multiple = false;
    let enumOptions = null;
    let renderer;
    let types;

    if (type.kind === 'NON_NULL') {
      required = true;
      type = type.ofType;
    }

    if (type.kind === 'LIST') {
      multiple = true;
      type = type.ofType;
    }

    if (type.kind === 'ENUM') {
      enumOptions = type.enumOptions;
      renderer = 'handbook';
    } else if (type.kind === 'SCALAR') {
      if (type.name.startsWith('Ref_')) {
        renderer = 'ref';
        types = type.name.slice(4).split('_');
      } else if (type.name.startsWith('Refs_')) {
        renderer = 'refs';
        types = type.name.slice(5).split('_');
      } else {
        renderer = kebabize(type.name);
      }
    } else {
      return dict;
    }

    if (field.name === 'id') {
      renderer = 'hidden';
    }

    dict[field.name] = {
      label: config?.fields?.[field.name]?.title || field.name,
      name: field.name,
      required,
      renderer,
      multiple,
      enumOptions,
      types: types || [renderer],
      gqlType: type.name,
      // typesDict: Object
    };

    return dict;
  }, {});

  return {
    fieldsDict,
    fieldsList: Object.values(fieldsDict),
  };
}

export default {
  query: gql`
    query Type($mutationName: String!) {
      __type(name: $mutationName) {
        fields {
          name
          args {
            name
            description
            type {
              name
              kind
              ofType {
                ...TypeRef
              }
            }
          }
        }
      }
    }

    fragment TypeRef on __Type {
      kind
      name
      ofType {
        kind
        name
        ofType {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                  ofType {
                    kind
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  update({ __type }, config) {
    return __type.fields.reduce((dict, action) => {
      dict[action.name] = prepareFieldsMeta(
        action.args,
        config.find((form) => form.action === action.name),
      );
      return dict;
    }, {});
  },
};
