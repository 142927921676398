<template>
  <div class="log">
    <div class="page-block__title">
      <h1>
        {{ $t('log.title') }}
        <span
          :class="['log__connection-status', 'log__connection-status--' + connectionStatus]"
          :title="connectionStatusMessage[connectionStatus]"
        ></span>
      </h1>
      <a-button
        class="log__clear"
        type="link"
        @click="clear"
      >
        {{ $t('log.clear') }}
      </a-button>
    </div>

    <div class="log-viewer">
      <virtual-list
        ref="virtualList"
        class="log-viewer__list"
        :keeps="100"
        :data-key="'offset'"
        :data-sources="log"
        :data-component="LogItem"
        @mousewheel.native="onScroll"
      />
      <div
        v-if="!autoscrollEnabled"
        class="log-viewer__autoscroll"
        @click="enableAutoscroll"
      ></div>
    </div>
  </div>
</template>

<script>
import VirtualList from 'vue-virtual-scroll-list';
import store from '@/store';
import { XHR } from '@/helpers';
import LogItem from './LogItem';

export default {
  name: 'TheLogs',
  components: {
    VirtualList,
  },

  data() {
    return {
      LogItem,
      offset: 0,
      connectionStatus: 'waiting',
      connectionStatusMessage: {
        connected: this.$t('log.status.connected'),
        waiting: this.$t('log.status.waiting'),
        closed: this.$t('log.status.closed'),
        error: this.$t('log.status.error'),
      },
      isLoading: false,
      log: [],
      autoscrollEnabled: true,
    };
  },

  computed: {
    endpoint() {
      return store.state.activeSidebarItem.customprops?.endpoint;
    },

    rowsLimit() {
      const limit = store.state.activeSidebarItem.customprops?.rowsLimit || 4000;
      return limit < 0 ? 0 : limit;
    },

    logText() {
      return this.log.join('\n');
    },
  },

  created() {
    this.pollLogs();
  },

  beforeDestroy() {
    this.connectionStatus = 'closed';
  },

  methods: {
    async pollLogs() {
      XHR.get(`${this.endpoint}?offset=${this.offset}`, {
        absoluteUrl: true,
      }).then(
        (response) => {
          if (this.connectionStatus === 'closed') return;

          this.connectionStatus = 'connected';
          response = JSON.parse(response);
          response.data.forEach((text, index) =>
            this.appendRow(response.requestedOffset + index, text),
          );
          this.offset = response.requestedOffset + response.data.length;

          this.$nextTick(() => {
            if (this.autoscrollEnabled) {
              this.$refs.virtualList.scrollToBottom();
            }
          });

          this.pollLogs();
        },
        () => {
          if (this.connectionStatus === 'closed') return;

          this.connectionStatus = 'error';
          setTimeout(this.pollLogs, 4000);
        },
      );
    },

    appendRow(offset, text) {
      this.log = this.log
        .concat({
          offset,
          text,
        })
        .slice(-this.rowsLimit);
    },

    clear() {
      this.log = [];
    },

    onScroll() {
      this.autoscrollEnabled = false;
    },

    enableAutoscroll() {
      this.autoscrollEnabled = true;
      this.$nextTick(() => {
        this.$refs.virtualList.scrollToBottom();
      });
    },
  },
};
</script>

<style lang="scss">
.log {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__clear {
    position: relative;
    right: -15px;
    top: 10px !important;
  }

  .page-block__title {
    justify-content: space-between;
    flex: 1;
  }

  .log-viewer {
    position: relative;
    flex: 1 1 100%;
    min-height: 0;

    &__list {
      @include scrollbars();
      height: 100%;
      font-size: 12px;
      background-color: #222;
      overflow-y: scroll;
      overflow-x: scroll;
      padding: 20px 0;
    }

    &__autoscroll {
      position: absolute;
      right: 8px;
      bottom: 8px;
      left: 0;
      background-color: #fff;
      opacity: 0.5;
      padding: 20px 20px;

      &:hover {
        opacity: 0.8;
      }

      &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 2px;
        background-color: #777;
        transform: translateX(-50%) skewY(30deg);
        width: 20px;
        content: '';
      }

      &:after {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 2px;
        background-color: #777;
        transform: translateX(50%) skewY(-30deg);
        width: 20px;
        content: '';
      }
    }
  }

  .log__connection-status {
    position: relative;
    top: 11px;
    display: inline-block;
    vertical-align: top;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    border: 1px solid #666;
    background: #888;

    &--connected {
      display: none;
      background: #080;
    }

    &--waiting {
      background: #ee0;
    }

    &--closed,
    &--error {
      background: #e00;
    }
  }
}
</style>
