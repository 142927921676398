<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->
  <section class="so-promocode">
    <header
      class="so-promocode__header"
      @click="toggleCard"
    >
      <a-row :gutter="30">
        <a-col :span="16">
          <a-tag
            class="so-promocode__order-status"
            color="blue"
          >
            {{ order.status }}
          </a-tag>
          Заказ №{{ order.orderNum }} от {{ formatDateTime(order.createdAt) }}
        </a-col>
        <a-col :span="8">
          Тип:
          {{ order.type }}
        </a-col>
      </a-row>
    </header>

    <a-spin
      v-if="expanded"
      :spinning="isLoading"
    >
      <section class="so-promocode__section">
        <h2>Заказ</h2>

        <a-row :gutter="30">
          <a-col :span="8">
            <label>{{ getLabel('B2bOrderType', 'sort') }}</label>
            <ref-autocomplete2
              v-model="orderType.sort"
              :config="getFieldConfig('B2bOrderType', 'sort')"
              :disabled="order.status === 'completed'"
            />
          </a-col>
          <a-col :span="8">
            <label>{{ getLabel('B2bOrderType', 'purpose') }}</label>
            <ref-autocomplete2
              v-model="orderType.purpose"
              :config="getFieldConfig('B2bOrderType', 'purpose')"
              :disabled="order.status === 'completed'"
            />
          </a-col>
        </a-row>

        <a-row :gutter="30">
          <a-col :span="8">
            <h3>Заказчик</h3>
            <a-row :gutter="30">
              <a-col>
                <label>{{ getLabel('B2bOrders', 'userId') }}</label>
                <ref-autocomplete2
                  v-model="userIdValue"
                  :config="getFieldConfig('B2bOrders', 'userId')"
                  :disabled="isExternal"
                />
              </a-col>
            </a-row>
            <a-row :gutter="30">
              <a-col :span="12">
                <label>{{ getLabel('B2bInfo', 'lastName') }}</label>
                <edit-form-string
                  v-model="b2binfo.lastName"
                  :disabled="isExternal || noUserId"
                />
              </a-col>
              <a-col :span="12">
                <label>{{ getLabel('B2bInfo', 'firstName') }}</label>
                <edit-form-string
                  v-model="b2binfo.firstName"
                  :disabled="isExternal || noUserId"
                />
              </a-col>
            </a-row>
            <a-row :gutter="30">
              <a-col :span="12">
                <label>{{ getLabel('B2bInfo', 'middleName') }}</label>
                <edit-form-string
                  v-model="b2binfo.middleName"
                  :disabled="isExternal || noUserId"
                />
              </a-col>
              <a-col :span="12">
                <label>{{ getLabel('B2BContact', 'phone') }}</label>
                <edit-form-string
                  v-model="contact.phone"
                  :disabled="isExternal || noUserId"
                />
              </a-col>
            </a-row>
            <label>{{ getLabel('B2bInfo', 'companyName') }}</label>
            <edit-form-string
              v-model="b2binfo.companyName"
              :disabled="isExternal || noUserId"
            />
            <label>{{ getLabel('B2BContact', 'email') }}</label>
            <edit-form-string
              v-model="contact.email"
              :disabled="isExternal || noUserId"
            />
          </a-col>

          <a-col :span="8">
            <h3>Данные о продукте</h3>
            <label>{{ getLabel('SOCourse', 'id') }}</label>
            <edit-form-string
              v-if="isPartner"
              value="Партнерская программа"
              disabled
            />
            <ref-autocomplete2
              v-else
              v-model="courseValue"
              :config="getFieldConfig('B2bOrders', 'courses')"
              idAsLabel
              :disabled="isExternal || isPartner"
            />
            <label>{{ getLabel('SOCourse', 'title') }}</label>
            <edit-form-string
              v-if="isPartner"
              value="Партнерская программа"
              disabled
            />
            <ref-autocomplete2
              v-else
              v-model="courseValue"
              :placeholder="isPartner ? 'Партнерская программа' : ''"
              :config="getFieldConfig('B2bOrders', 'courses')"
              :disabled="isExternal || isPartner"
            />
            <label>{{ getLabel('SOCourse', 'schoolId') }}</label>
            <edit-form-string
              v-if="isPartner"
              value="Партнерская программа"
              disabled
            />
            <edit-form-string
              v-else
              :value="(course.schoolId && course.schoolId.title) || ''"
              disabled
            />
          </a-col>

          <a-col :span="8">
            <h3>Данные об оплате</h3>

            <label>{{ getLabel('B2bOrders', 'preSum') }}</label>
            <edit-form-string
              v-if="isPartner"
              value="Не требуется"
              disabled
            />
            <input-number
              v-else
              v-model="order.preSum"
            />

            <a-row :gutter="30">
              <a-col :span="12">
                <label>{{ getLabel('B2bOrderPayments', 'paymentDate') }}</label>
                <edit-form-string
                  v-if="isPartner"
                  value="Не требуется"
                  disabled
                />
                <edit-form-date
                  v-else
                  v-model="payment.paymentDate"
                  :config="{ ...getPaymentField('paymentDate'), renderer: 'date' }"
                  fullWidth
                />
              </a-col>
              <a-col :span="12">
                <label>&nbsp;</label>
                <edit-form-boolean
                  :value="hasPayment"
                  :config="{ ...getOrderField('preSum'), label: 'Курс оплачен' }"
                  labelTag="h4"
                  disabled
                />
              </a-col>
            </a-row>

            <label>{{ getLabel('B2bOrderPayments', 'sum') }}</label>
            <edit-form-string
              v-if="isPartner"
              value="Не требуется"
              disabled
            />
            <input-number
              v-else
              v-model="payment.sum"
            />
            <label>{{ getLabel('B2bOrders', 'status') }}</label>
            <ref-autocomplete2
              v-model="order.status"
              :config="getFieldConfig('B2bOrders', 'status')"
              :disabled="order.status === 'completed'"
            />
            <a-button
              type="primary"
              @click="saveData"
            >
              Сохранить
            </a-button>
          </a-col>
        </a-row>
      </section>

      <section class="so-promocode__section">
        <h2>Промокоды</h2>
        <a-row :gutter="30">
          <a-col :span="8">
            <a-row :gutter="30">
              <a-col :span="12">
                <label>{{ getLabel('B2bOrders', 'startActivationDate') }}</label>
                <edit-form-date
                  v-model="order.startActivationDate"
                  :config="{ ...getOrderField('startActivationDate'), renderer: 'date' }"
                  :disabled="!isPaid"
                  fullWidth
                />
              </a-col>
              <a-col :span="12">
                <label>{{ getLabel('B2bOrders', 'endActivationDate') }}</label>
                <edit-form-date
                  v-model="order.endActivationDate"
                  :config="{ ...getOrderField('endActivationDate'), renderer: 'date' }"
                  :disabled="!isPaid"
                  fullWidth
                />
              </a-col>
            </a-row>

            <label>{{ $t('SOPromocodes.placeholders.promoMask') }}</label>
            <ref-autocomplete2
              v-model="promoParams.mask"
              :config="getFieldConfig('PromocodeParameters', 'mask')"
              :disabled="!isPaid || hasPromocodes"
            />
            <label>{{ $t('SOPromocodes.placeholders.promoType') }}</label>
            <ref-autocomplete2
              v-model="promoParams.type"
              :config="getFieldConfig('PromocodeParameters', 'type')"
              :disabled="!isPaid || hasPromocodes"
            />
          </a-col>
          <a-col :span="8">
            <label>{{ getLabel('B2bOrders', 'constCode') }}</label>
            <edit-form-string
              v-model="order.constCode"
              textTransform="uppercase"
              :disabled="!isPaid || hasPromocodes || promoParams.mask !== 'Word'"
            />
            <label>{{ getLabel('PromocodeParameters', 'prefix') }}</label>
            <edit-form-string
              v-model="promoParams.prefix"
              textTransform="uppercase"
              :disabled="!isPaid || hasPromocodes || promoParams.mask !== 'Prefix'"
            />
            <label>{{ getLabel('B2bOrders', 'maxCount') }}</label>
            <input-number
              v-model="order.maxCount"
              :disabled="!isPaid || hasPromocodes || promoParams.type !== 'Reusable'"
            />
            <label>{{ getLabel('B2bOrders', 'codesAmount') }}</label>
            <input-number
              v-model="order.codesAmount"
              :disabled="!isPaid || hasPromocodes || promoParams.type !== 'Disposable'"
            />
          </a-col>
          <a-col :span="8">
            <edit-form-text
              class="so-promocode__codes"
              :placeholder="$t('SOPromocodes.placeholders.codes')"
              :config="{ textHeight: 111 }"
              :disabled="!hasPromocodes"
              :readOnly="hasPromocodes"
              :value="promocodesList"
            />
            <a-button
              class="so-promocode__generate-btn"
              type="primary"
              :disabled="hasPromocodes || !isPaid"
              @click="generateCodes"
            >
              {{ $t('SOPromocodes.generateCodes') }}
            </a-button>
            <export-button
              class="so-promocode__generate-btn"
              :disabled="!hasPromocodes"
              @click="exportCodes"
            >
              {{ $t('SOPromocodes.exportCodes') }}
            </export-button>
          </a-col>
        </a-row>
      </section>

      <footer class="so-promocode__footer">
        <a-popconfirm
          :okText="$t('entity.deletionConfirm')"
          :cancelText="$t('entity.deletionCancel')"
          :title="$t('entity.deletionTitle')"
          @confirm="deleteOrder"
        >
          <a-button
            type="danger"
            :disabled="isExternal || isPaid || hasPayment"
          >
            {{ $t('SOPromocodes.deleteOrder') }}
          </a-button>
        </a-popconfirm>
      </footer>
    </a-spin>
  </section>
</template>

<script>
import store from '@/store';
import FormConfigService from '@/services/FormConfigService';
import { bus, formatDateTime } from '@/helpers';
import InputNumber from '@/components/base/InputNumber';
import ExportButton from '@/components/base/ExportButton';
import EditFormString from '@/components/edit-form/fields/EditFormString';
import EditFormDate from '@/components/edit-form/fields/EditFormDate';
import EditFormText from '@/components/edit-form/fields/EditFormText';
import EditFormBoolean from '@/components/edit-form/fields/EditFormBoolean';
import RefAutocomplete2 from '@/components/edit-form/fields/ref/RefAutocomplete2';

export default {
  name: 'SoPromocodeCard',

  components: {
    InputNumber,
    ExportButton,
    EditFormString,
    EditFormDate,
    EditFormText,
    EditFormBoolean,
    RefAutocomplete2,
  },

  props: {
    orderData: {
      type: Object,
      required: true,
    },
    paymentData: {
      type: Object,
      required: true,
    },
    promoParamsData: {
      type: Object,
      required: true,
    },
    courseData: {
      type: Object,
      default: null,
    },
    b2binfoData: {
      type: Object,
      required: true,
    },
    contactData: {
      type: Object,
      required: true,
    },
    orderTypeData: {
      type: Object,
      default: null,
    },
    hasPayment: {
      type: Boolean,
      required: true,
    },
    hasPromocodes: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    promocodes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      expanded: false,
      course: null,
    };
  },

  computed: {
    isExternal() {
      return this.order.type === 'external';
    },

    isPaid() {
      return ['paid', 'approved'].includes(this.order.status);
    },

    isPartner() {
      return this.orderType.sort === 'partner';
    },

    noUserId() {
      return !this.userIdValue;
    },

    paymentFields() {
      return FormConfigService.getFormConfig('B2bOrderPayments')
        .tabs.map((tab) => tab.columns.flat())
        .flat();
    },

    orderFields() {
      return FormConfigService.getFormConfig('B2bOrders')
        .tabs.map((tab) => tab.columns.flat())
        .flat();
    },

    courseValue: {
      get() {
        return this.order.courses || undefined;
      },
      set(value) {
        this.$set(this.order, 'courses', value);
        this.$emit('setCourse', value.length ? value : null);
      },
    },

    userIdValue: {
      get() {
        return this.order.userId;
      },
      set(value) {
        this.$set(this.order, 'userId', value);
        this.$emit('setUserId', value);
      },
    },

    promocodesList() {
      return this.promocodes?.join('\n') || '';
    },

    order: {
      get() {
        return this.orderData;
      },
      set(value) {
        this.$emit('setValue', 'order', value);
      },
    },

    b2binfo: {
      get() {
        return this.b2binfoData;
      },
      set(value) {
        this.$emit('setValue', 'b2binfo', value);
      },
    },

    contact: {
      get() {
        return this.contactData;
      },
      set(value) {
        this.$emit('setValue', 'contact', value);
      },
    },

    orderType: {
      get() {
        return this.orderTypeData;
      },
      set(value) {
        this.$emit('setValue', 'orderType', value);
      },
    },

    payment: {
      get() {
        return this.paymentData;
      },
      set(value) {
        this.$emit('setValue', 'payment', value);
      },
    },

    promoParams: {
      get() {
        return this.promoParamsData;
      },
      set(value) {
        this.$emit('setValue', 'promoParams', value);
      },
    },
  },

  watch: {
    courseData: {
      immediate: true,
      handler() {
        this.course = { ...this.courseData };
      },
    },
  },

  created() {
    bus.$on('soPromoExpand', this.onCardExpand);
  },

  beforeDestroy() {
    bus.$off('soPromoExpand', this.onCardExpand);
  },

  methods: {
    formatDateTime,

    toggleCard() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        bus.$emit('soPromoExpand', this._uid);
      }
    },

    onCardExpand(x) {
      if (this._uid !== x) this.expanded = false;
    },

    getPaymentField(name) {
      return this.paymentFields.find((field) => field.name === name);
    },

    getOrderField(name) {
      return this.orderFields.find((field) => field.name === name);
    },

    getFieldConfig(entity, field) {
      return store.state.meta.entities
        .find((e) => e.name === entity)
        .fields.find((f) => f.name === field);
    },

    getLabel(entity, name) {
      return (
        FormConfigService.getFormConfig(entity).locale[store.state.lang].fields[name]?.label || name
      );
    },

    deleteOrder() {
      this.$emit('delete');
    },

    saveData() {
      this.$emit('save', {
        order: this.order,
      });
    },

    generateCodes() {
      this.$emit('generate', {
        order: this.order,
      });
    },

    exportCodes(exportMethod) {
      const filename = `order-${this.order.orderNum}-promocodes-${+new Date()}.csv`;
      const data = `Заказ №${this.order.orderNum}\n${this.promocodesList}`;
      exportMethod(filename, data, 'csv');
    },
  },
};
</script>

<style lang="scss">
.so-promocode {
  background-color: #f9f9f9;
  width: 100%;

  .ant-form-item-control {
    line-height: 32px;
  }

  &__header {
    padding: 20px 22px;
    font-weight: 500;
    background: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }

  &__footer {
    padding: 20px 22px;
  }

  &__order-status {
    text-transform: uppercase;
    cursor: pointer;
  }

  &__section {
    margin-top: 25px;
    padding: 0 22px 0;
  }

  &__codes {
    margin-top: 21px;
  }

  &__generate-btn {
    &:not(:last-child) {
      margin-right: 26px;
    }
  }
}
</style>
