<template>
  <div class="action-form-modal">
    <slot name="main">
      <action-form-preview
        :id="id"
        :type="entityType"
        :formConfig="formConfig"
        :data="data"
        :width="customFormPreviewWidth"
      />
    </slot>
    <div>
      <slot name="side">
        <action-form-actions
          v-for="(actionMeta, name) of actionsMeta"
          :id="id"
          :key="name"
          :type="entityType"
          :action="name"
          :title="customFormTitles[name]"
          :fieldsMeta="actionMeta.fieldsList"
          @close="$emit('close')"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import MetaService from '@/services/MetaService';
import FormConfigService from '@/services/FormConfigService';
import ActionFormActions from '@/components/action-form/ActionFormActions.vue';
import ActionFormPreview from '@/components/action-form/ActionFormPreview.vue';

export default {
  name: 'ActionForm',
  components: { ActionFormActions, ActionFormPreview },

  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    extActionsMeta: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      actionsMeta: null,
      formConfig: null,
    };
  },

  computed: {
    customFormPreviewWidth() {
      return store.state.activeSidebarItem.customprops?.form?.previewWidth;
    },
    customFormTitles() {
      return store.state.activeSidebarItem.customprops?.form?.actions?.reduce((acc, form) => {
        let action = form;
        let title = form;
        if (typeof action !== 'string') {
          action = form.action;
          title = form.title || form.action;
        }

        acc[action] = title;
        return acc;
      }, {});
    },
    entityType() {
      return store.state.activeSidebarItem.code;
    },
  },

  async created() {
    this.formConfig = FormConfigService.getFormConfig(this.entityType);
    this.actionsMeta =
      this.extActionsMeta ||
      (await MetaService.getEntityActions(
        this.entityType,
        store.state.activeSidebarItem.customprops.form.actions,
      ));
  },
};
</script>

<style lang="scss">
.action-form-modal {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  display: flex;

  & > :nth-child(1) {
    flex: 0 0 400px;
    padding: 29px 22px;
    border-right: 1px solid #ccc;
    margin-right: 38px;
    height: 100%;
    @include scrollbars();
    overflow-y: auto;
  }

  & > :nth-child(2) {
    flex: 1;
    padding-right: 20px;
    height: 100%;
    @include scrollbars();
    overflow-y: scroll;
    & > .entity-modal {
      & + .entity-modal {
        border-top: 1px solid #ccc;
      }
      & > .entity-modal__wrap > .entity-view {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .entity-view__title {
      font-size: 18px;
      margin-bottom: -40px;
    }
    .entity-form__footer {
      margin-top: 0;
      padding-top: 0;
    }
    .entity-modal__wrap {
      position: relative;
    }
    .entity-view__head {
      position: static;
    }
    .entity-modal--action-form,
    .entity-modal__spin {
      min-height: 100px;
    }
  }
}
</style>
