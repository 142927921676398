import moment from 'moment';
import store from '@/store';

export function formatDateTime(timestamp, format = 'DD MMMM YYYY HH:mm:ss') {
  return timestamp && moment(timestamp).format(format);
}

export function formatDate(timestamp) {
  return timestamp && moment(timestamp).format('DD MMMM YYYY');
}

export function formatTime(timestamp) {
  return timestamp && moment(timestamp).format('HH:mm:ss');
}

export function formatDay(timestamp) {
  let display = timestamp || '';

  if (display) {
    const date = moment(timestamp);
    const today = moment();

    if (date.isSame(today, 'day')) {
      display = store.state.transProvider.$t('base.today');
    } else if (date.isSame(today.subtract(1, 'day'), 'day')) {
      display = store.state.transProvider.$t('base.yesterday');
    } else {
      display = date.format('D MMMM');
    }
  }

  return display;
}

export function snakeCase(value) {
  return value.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export function camelCase(value) {
  return value
    .split('-')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join('');
}

export function firstUpperCase(value) {
  return value[0].toUpperCase() + value.slice(1).toLowerCase();
}

/**
 * Склонение существительных
 * Правильная форма cуществительного рядом с числом (счетная форма).
 *
 * @example declension("файл", "файла", "файлов", 1); // returns "файл"
 * @example declension("файл", "файла", "файлов", 3); // returns "файла"
 * @example declension("файл", "файла", "файлов", 5); // returns "файлов"
 *
 * @param {string} oneNominative единственное число (именительный падеж),
 * @param {string} severalGenitive множественное число (родительный падеж)
 * @param {string} severalNominative множественное число (именительный падеж)
 * @param {(string|number)} number количество
 * @returns {string}
 */
export function declension(oneNominative, severalNominative, severalGenitive, number) {
  number %= 100;
  if (number <= 14 && number >= 11) {
    number = severalGenitive;
  } else {
    number %= 10;
    if (number < 5) {
      if (number > 2) {
        number = severalNominative;
      } else if (number === 1) {
        number = oneNominative;
      } else if (number === 0) {
        number = severalGenitive;
      } else {
        number = severalNominative;
      }
    } else {
      number = severalGenitive;
    }
  }

  return number;
}

export function decodeHtml(input) {
  if (!input) return '';
  const text = document.createElement('textarea');
  text.innerHTML = input;
  return (text.childNodes[0]?.nodeValue || '').replace(/(<([^>]+)>)/gi, '');
}

export function kebabize(str) {
  return str
    .split('')
    .map((letter, idx) =>
      letter.toUpperCase() === letter ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}` : letter,
    )
    .join('');
}
