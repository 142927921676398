import { bus } from '@/helpers';
import store from '@/store';
import { appSettings } from '@/AppSettings';
import { apolloProvider } from '@/apolloProvider';
import TABLE_QUERY from '@/queries/table';

export const KANBAN_FIELD_RENDERERS = ['ref'];

function createReferredTableQuery(type) {
  return apolloProvider.defaultClient
    .query({
      ...TABLE_QUERY,
      variables: {
        type,
        page: 0,
        pageSize: 999,
      },
    })
    .catch((error) => {
      bus.$emit('error', {
        message: store.state.transProvider('entity.error.getTable'),
        description: error.message,
      });

      throw error;
    });
}

function extractColumnsFromResponse({ data }, type) {
  return data.table.documents.map((row) => {
    const label = row.data[row.snapshot];
    if (!row.snapshot) {
      bus.$emit('error', {
        message: store.state.transProvider('page.error.noSnapshotSpecified.message'),
        description: store.state.transProvider('page.error.noSnapshotSpecified.description', {
          type,
        }),
      });
    } else if (!label) {
      bus.$emit('error', {
        message: store.state.transProvider('page.error.noSnapshotFound.message'),
        description: store.state.transProvider('page.error.noSnapshotFound.description', {
          type,
          snapshot: row.snapshot,
        }),
      });
    }

    const id = `${type}:${row.id}`;
    return {
      label: label || id,
      field: id,
      hidden: false,
    };
  });
}

export const kanbanDisplay = {
  value: 'kanban',
  options: {
    columnsField: {
      renderer: 'conditional-form',
      options(entityFields) {
        const enumFields = entityFields.filter((field) =>
          KANBAN_FIELD_RENDERERS.includes(field.renderer),
        );

        return enumFields.map((enumField) => ({
          label: enumField.name,
          value: enumField.name,
          options: {
            columns: {
              renderer: 'fields-list',
              default() {
                return Promise.all(
                  enumField.types
                    .filter((type) => !type.startsWith(appSettings.get('systemFieldsPrefix')))
                    .map(createReferredTableQuery),
                ).then((responsesArray) =>
                  responsesArray
                    .map((response, ind) =>
                      extractColumnsFromResponse(response, enumField.types[ind]),
                    )
                    .flat(),
                );
              },
            },
          },
        }));
      },
    },
  },
};
