<template>
  <a-dropdown
    :trigger="['click']"
    placement="bottomRight"
  >
    <a class="ant-dropdown-link locale-switcher">
      {{ currentLang.toUpperCase() }}
    </a>
    <a-menu
      slot="overlay"
      class="app-header__menu"
    >
      <a-menu-item
        v-for="lang in languages"
        :key="lang.code"
        @click="currentLang = lang.code"
      >
        {{ lang.name || lang.code.toUpperCase() }}
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import store from '@/store';
import LocaleService from '@/services/LocaleService';

export default {
  name: 'LocaleSwitcher',
  computed: {
    currentLang: {
      get() {
        return store.state.lang;
      },
      set(code) {
        LocaleService.switchLang(code);
      },
    },
    languages() {
      return LocaleService.languages;
    },
  },
};
</script>

<style lang="scss">
.locale-switcher {
  width: 22px;
}
</style>
