import gql from 'graphql-tag';
import { parseJson } from './json';
import { GqlObject } from './GqlObject';

/**
 * Для запроса /meta
 */
class MetaQueryParser {
  prepareMeta(meta, user) {
    Object.values(meta.components).forEach((component) =>
      this.prepareMetaObject(component, meta, user),
    );

    Object.values(meta.embeds).forEach((embed) => this.prepareMetaObject(embed, meta, user));
    Object.values(meta.processes).forEach((process) => this.prepareMetaObject(process, meta, user));
    return meta;
  }

  prepareMetaObject(object, meta, user) {
    object.fields.forEach((field) => {
      if (!['component', 'embed'].includes(field.renderer)) return;

      const fieldIsEmbed = field.renderer === 'embed';
      const childDict = fieldIsEmbed ? 'embeds' : 'components';

      field.typesDict = field.types.reduce((dict, type) => {
        dict[type] = meta[childDict][type];
        return dict;
      }, {});
    });

    this.updateObjectOperations(object, user);
    return object;
  }

  updateObjectOperations(object, user) {
    object.operations = {
      TABLE: true,
      READ: true,
    };

    object.actions.forEach((a) => {
      object.operations[a.name.toUpperCase()] = true;
    });
    console.log('user', user); // lint error: user is defined but never used
  }

  updateOperations(meta, user) {
    Object.values(meta.components).forEach((comp) => this.updateObjectOperations(comp, user));
    Object.values(meta.embeds).forEach((embed) => this.updateObjectOperations(embed, user));
    Object.values(meta.processes).forEach((process) => this.prepareMetaObject(process, meta, user));
  }
}

export const metaQueryParser = new MetaQueryParser();

function getFieldValueForGraph({
  value,
  fieldsMeta = null,
  fieldName = null,
  gqlObject = false,
  mapper = null,
  doNotStringify = false,
}) {
  const fieldMeta = fieldsMeta?.find((field) => field.name === fieldName);
  if (fieldMeta?.renderer === 'embed' && doNotStringify) {
    if (Array.isArray(value)) return value.map((e) => e.data);
  }

  if (fieldMeta?.renderer === 'boolean') {
    value = value ?? false;
    if (doNotStringify) return value;
  }

  if (fieldMeta?.renderer === 'text' || fieldMeta?.renderer === 'wysiwyg') {
    if (doNotStringify) return value;
  }

  if (value === undefined) return value;

  if (fieldMeta?.renderer === 'hidden' || fieldMeta?.renderer === 'json') {
    value = parseJson(value);
    if (value === undefined) return value;
    if (doNotStringify && fieldMeta?.renderer === 'json') return parseJson(value) || null;
    if (doNotStringify && typeof value === 'string') return value;
  }

  if (fieldMeta?.renderer === 'image') {
    try {
      value = parseJson(value);
    } catch (e) {
      // ...
    }
    if (doNotStringify) return value;
  }

  if (fieldMeta?.renderer === 'gallery') {
    if (!Array.isArray(value)) return [];
    try {
      value = value.map((v) => parseJson(v));
    } catch (e) {
      // ...
    }
  }

  if (fieldMeta?.renderer === 'string') {
    value = value || '';
    if (doNotStringify) return value;
  } else if (doNotStringify && !fieldMeta && typeof value === 'string') {
    return value;
  }

  if (fieldMeta?.conf?.handbooks) {
    return fieldMeta.multiple ? `[${value.join(', ')}]` : value;
  }

  if (value instanceof GqlObject) {
    mapper = value.mapper;
    value = value.value;
    gqlObject = true;
  }

  if (gqlObject) {
    if (Array.isArray(value)) {
      const list = value
        .map((item) =>
          getFieldValueForGraph({
            value: item,
            fieldsMeta: null,
            fieldName: null,
            gqlObject: true,
            mapper,
          }),
        )
        .filter((v) => v !== undefined);
      return `[${list.join(', ')}]`;
    }

    if (value && typeof value === 'object') {
      if (mapper) {
        return mapper(value);
      }

      const list = Object.keys(value).reduce((acc, key) => {
        const itemValue = getFieldValueForGraph({
          value: value[key],
          fieldsMeta,
          fieldName: null,
          gqlObject: null,
          mapper: true,
        });

        if (itemValue !== undefined) {
          acc.push(`${key}: ${itemValue}`);
        }

        return acc;
      }, []);

      return `{ ${list.join(', ')} }`;
    }

    if (value === undefined) return value;
    return JSON.stringify(value);
  }

  if (value && typeof value === 'object' && !Array.isArray(value)) {
    const list = Object.keys(value).reduce((acc, key) => {
      const itemValue = getFieldValueForGraph({ value: value[key], fieldsMeta });
      if (itemValue !== undefined) {
        acc.push(`${key}: ${itemValue}`);
      }
      return acc;
    }, []);

    return `{${list.join(', ')}}`;
  }

  return JSON.stringify(value);
}

function composeGqlQueryParameter({ dataKey, dataSource, formData, context, fieldsMeta }) {
  const isArray = Array.isArray(dataSource);
  const isObject = !isArray && typeof dataSource === 'object';
  const isConstant =
    !isArray && !isObject && (typeof dataSource !== 'string' || !dataSource.startsWith('$'));
  const isContext = !isArray && !isObject && !isConstant && dataSource.startsWith('$ctx');
  let value;

  if (isObject) {
    const subfieldsValues = Object.entries(dataSource)
      .map(([subDataKey, subDataSource]) =>
        composeGqlQueryParameter({
          dataKey: subDataKey,
          dataSource: subDataSource,
          formData,
          context,
          fieldsMeta,
        }),
      )
      .filter((v) => v !== undefined);

    value = `{${subfieldsValues.join(', ')}}`;
  } else if (isConstant) {
    value = getFieldValueForGraph({ value: dataSource });
  } else if (isContext) {
    value = getFieldValueForGraph({ value: context[dataSource.slice(5)] });
  } else if (isArray) {
    value = `[${dataSource.join(', ')}]`;
  } else {
    value = getFieldValueForGraph({
      value: formData[dataSource.slice(1)],
      fieldsMeta,
      fieldName: dataSource.slice(1),
    });
  }

  if (value === undefined) return value;
  return `${dataKey}: ${value}`;
}

function composeGqlQueryParametersList({ action, data, context, fieldsMeta }) {
  return Object.entries(action.parameters)
    .map(([dataKey, dataSource]) =>
      composeGqlQueryParameter({ dataKey, dataSource, formData: data, context, fieldsMeta }),
    )
    .filter((v) => v !== undefined)
    .join(', ');
}

function tokenizeParameterFunction(str) {
  return str
    .slice(3)
    .replaceAll(' ', '')
    .split(/[(,)]/)
    .filter((t) => !!t);
}

function composeRequestVarGetValue({
  action,
  fieldName,
  dataSource,
  formData,
  context,
  fieldsMeta,
  formMeta,
}) {
  if (typeof dataSource === 'object') {
    return Object.keys(dataSource).reduce((acc, key) => {
      if (key === '__typename') return acc;

      acc[key] = composeRequestVarGetValue({
        action,
        fieldName: key,
        dataSource: dataSource[key],
        formData,
        context,
        fieldsMeta,
        formMeta,
      });
      return acc;
    }, {});
  }

  if (typeof dataSource !== 'string' || !dataSource.startsWith('$')) {
    return getFieldValueForGraph({
      value: dataSource,
      fieldsMeta,
      fieldName,
      gqlObject: false,
      mapper: null,
      doNotStringify: true,
    });
  }

  if (dataSource.startsWith('$ctx.')) {
    return getFieldValueForGraph({
      value: context[dataSource.slice(5)],
      fieldsMeta,
      fieldName: dataSource.slice(5),
      gqlObject: false,
      mapper: null,
      doNotStringify: true,
    });
  }

  if (dataSource.startsWith('$f.')) {
    const [fname, ...args] = tokenizeParameterFunction(dataSource);

    const getUuidRefInput = (ref) => {
      if (ref?.value) {
        const [typename, id] = ref.value.split(':');
        return { typename, id };
      }

      if (ref?.id && ref?.__typename) {
        return { typename: ref.__typename, id: ref.id };
      }

      return ref;
    };

    // eslint-disable-next-line no-eval
    const value = eval(formMeta.fns[fname]).call(
      { getUuidRefInput },
      ...args.map((key) => {
        if (key.startsWith('$ctx.')) return context[key.slice(5)];
        if (key.startsWith('$')) return formData[key.slice(1)];
        return key;
      }),
    );

    return value;
  }

  return getFieldValueForGraph({
    value: formData[dataSource.slice(1)],
    fieldsMeta,
    fieldName: dataSource.slice(1),
    gqlObject: false,
    mapper: null,
    doNotStringify: true,
  });
}

function clearTypenames(obj) {
  if (!obj) return obj;

  if (Array.isArray(obj)) {
    return obj.map(clearTypenames);
  }

  if (typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      if (key === '__typename') return acc;
      acc[key] = clearTypenames(obj[key]);
      return acc;
    }, {});
  }

  return obj;
}

function composeRequestVar({
  action,
  dataKey,
  dataSource,
  formData,
  context,
  fieldsMeta,
  formMeta,
  depth = 0,
}) {
  const isObjectValue = typeof dataSource === 'object';
  const splitPath = action.path.split('.');
  let value;

  if (depth === 0) {
    let graphFieldType = null;

    if (
      dataKey === 'data' &&
      isObjectValue &&
      ['create', 'createWithAutogenLinkedId', 'update'].includes(splitPath[2])
    ) {
      graphFieldType = `${splitPath[1]}LogIn`;
    }

    if (dataKey === 'where' && ['list', 'listEditor'].includes(splitPath[2])) {
      graphFieldType = `${splitPath[1]}WhereIn`;
    }

    if (graphFieldType) {
      value = clearTypenames(
        composeRequestVarGetValue({
          action,
          fieldName: dataKey,
          dataSource,
          formData,
          context,
          fieldsMeta,
          formMeta,
        }),
      );

      const valueinparam = composeGqlQueryParameter({
        dataKey,
        dataSource,
        formData,
        context,
        fieldsMeta,
      });

      return [`$${dataKey}: ${graphFieldType}`, dataKey, value, valueinparam];
    }
  }

  if (value === undefined) return value;
  return `${dataKey}: ${value}`;
}

function composeRequestVarsList({ action, data, context, fieldsMeta, formMeta }) {
  return Object.entries(action.parameters)
    .map(([dataKey, dataSource]) =>
      composeRequestVar({
        action,
        dataKey,
        dataSource,
        formData: data,
        context,
        fieldsMeta,
        formMeta,
      }),
    )
    .filter((v) => v !== undefined);
}

export function composeGqlQueryFromAction({ action, data, context, fieldsMeta }) {
  const pathTokens = action.path.split('.').reverse();
  const query = pathTokens.reduce((acc, slug, index) => {
    if (index === 0) {
      const params = composeGqlQueryParametersList({ action, data, context, fieldsMeta });
      if (params) slug = `${slug}(${params})`;
    }

    if (acc) return `${slug} {${acc}}`;
    return slug;
  }, action.responseVars);

  return gql`
    ${query}
  `;
}

export function composeGqlQueryFromActionWithVariables({
  action,
  data,
  context,
  fieldsMeta,
  formMeta,
}) {
  const requestVars = composeRequestVarsList({ action, data, context, fieldsMeta, formMeta });
  const pathTokens = action.path.split('.').reverse();

  let query = pathTokens.reduce((acc, slug, index) => {
    if (index === 0) {
      const params = composeGqlQueryParametersList({ action, data, context, fieldsMeta });
      if (params) slug = `${slug}(${params})`;
    }

    if (index === pathTokens.length - 1 && requestVars.length) {
      slug += ` QueryWithVars(${requestVars.map((rv) => rv[0]).join(', ')})`;
    }

    if (acc) return `${slug} {${acc}}`;
    return slug;
  }, action.responseVars);

  if (requestVars) {
    const variables = requestVars.reduce((acc, rv) => {
      acc[rv[1]] = parseJson(rv[2]);
      return acc;
    }, {});

    requestVars.forEach((rv) => {
      query = query.replace(rv[3], `${rv[1]}: $${rv[1]}`);
    });

    return {
      query: gql`
        ${query}
      `,
      variables,
    };
  }

  return gql`
    ${query}
  `;
}
