<template>
  <a-button
    :type="buttonType"
    :disabled="disabled"
    @click="click"
  >
    <slot>{{ $t('app.export') }}</slot>
  </a-button>
</template>

<script>
export default {
  name: 'ExportButton',

  props: {
    buttonType: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    click() {
      this.$emit('click', this.exportMethod);
    },

    exportMethod(filename, text, type = 'text') {
      const link = document.createElement('a');
      const head = {
        text: 'data:text/plain;charset=utf-8,',
        csv: 'data:application/octet-stream;charset=utf-8,',
      }[type];

      link.setAttribute('download', filename);
      link.setAttribute('href', `${head}${encodeURIComponent(text)}`);

      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
