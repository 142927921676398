<template>
  <a-input
    v-model="input"
    :name="config.field"
    size="small"
    allowClear
    @pressEnter="$emit('apply-filters')"
  />
</template>

<script>
export default {
  name: 'FilterElementString',
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: '',
    };
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.value = value;
        this.$emit('input', value);
      },
    },
  },
};
</script>
