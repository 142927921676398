import Vue from 'vue';
import store from '@/store';
import { parseJson } from '@/helpers/json';
import { preparePayload } from '@/helpers/dataProcessing';
import CONFIG_QUERY from '@/queries/config';
import CREATE_MUTATION from '@/queries/mutations/create';
import UPDATE_MUTATION from '@/queries/mutations/update';
import BaseService from './BaseService';

class TableConfigService extends BaseService {
  constructor() {
    super();
    this.configId = null;
    this.configEntity = null;
  }

  get config() {
    return this.configEntity?.data.config;
  }

  set config(value) {
    this.configEntity.data.config = value;
  }

  async load() {
    if (!this.configEntity) {
      const { data } = await store.state.apolloClientOldApi.query({
        ...CONFIG_QUERY,
        variables: { name: 'tables' },
      });

      this.prepareConfig(data.table.documents[0]);
    }

    return this.configEntity;
  }

  prepareConfig(configEntity) {
    let config = configEntity?.data?.config || {};
    config = parseJson(config);

    this.configId = configEntity?.id;
    this.configEntity = Vue.observable({
      id: configEntity?.id,
      data: {
        title: 'forms',
        config,
      },
    });
  }

  getTableConfig(type) {
    if (!type) throw new Error('Missing argument[0] `EntityType`');

    return this.config[type];
  }

  async updateTablesConfig(transProvider) {
    const { data, error } = await store.state.apolloClientOldApi.mutate({
      mutation: this.configId ? UPDATE_MUTATION : CREATE_MUTATION,
      variables: {
        type: 'Config',
        id: this.configEntity.id,
        data: preparePayload(this.configEntity.data, store.state.meta.components.Config.fields),
      },
    });

    if (error) {
      this.emitError(transProvider.$t('entity.error.configSave'), error.message);
      return;
    }

    if (data.create) {
      this.configEntity.id = data.create.document.id;
    }
  }
}

export default new TableConfigService();
