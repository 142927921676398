<template>
  <error-output :errors="errors">
    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
    <label
      ref="label"
      :class="['autocomplete', { focused, disabled: disabled || readOnly }]"
      @click="onLabelClick"
    >
      <div class="autocomplete__wrapper">
        <span
          v-for="(item, index) of items"
          :key="index"
        >
          {{ item.label }}
          <a-icon
            v-if="!disabled && !readOnly"
            type="close"
            @click.prevent="removeRef"
          />
        </span>
        <a-select
          v-if="!readOnly"
          v-model="items"
          showSearch
          labelInValue
          class="autocomplete__input"
          :getPopupContainer="getPopupContainer"
          :notFoundContent="loadingKeys ? undefined : null"
          :defaultActiveFirstOption="false"
          :filterOption="false"
          :options="options"
          :disabled="disabled"
          mode="default"
          @search="onSearch"
          @focus="focused = true"
          @blur="focused = false"
        >
          <a-spin
            v-if="loadingKeys"
            slot="notFoundContent"
            size="small"
          />
        </a-select>
      </div>
    </label>
  </error-output>
</template>

<script>
import ENUMS_LIST_QUERY from '@/queries/enumsList';
import ErrorOutput from '@/components/base/ErrorOutput';

export default {
  name: 'EditFormEnumModelField',
  components: {
    ErrorOutput,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    enumsList: {
      ...ENUMS_LIST_QUERY,
      loadingKey: 'loadingKeys',
    },
  },
  data() {
    return {
      search: '',
      focused: false,
      loadingKeys: 0,
    };
  },
  computed: {
    items: {
      get() {
        return this.value ? [{ key: this.value, label: this.value }] : [];
      },
      set(value) {
        this.$emit('input', value?.key || '');
      },
    },
    options() {
      if (this.loadingKeys) return [];

      return this.enumsList.reduce((list, { name }) => {
        list.push({
          title: name,
          value: name,
        });

        return list;
      }, []);
    },
    filteredOptions() {
      return this.options.filter((option) => option.title.toLowerCase().includes(this.search));
    },
  },
  methods: {
    getPopupContainer() {
      return this.$refs.label;
    },
    onSearch(value) {
      this.search = value;
    },
    onLabelClick(event) {
      // prevent dropdown from opening again after value selection
      if (this.focused) event.preventDefault();
    },
    removeRef() {
      this.items = undefined;
    },
  },
};
</script>

<style lang="scss"></style>
