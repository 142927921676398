import Vue from 'vue';
import debounce from 'lodash/debounce';
import store from '@/store';
import { parseJson } from '@/helpers/json';
import { preparePayload } from '@/helpers/dataProcessing';
import { prepareFormConfig, prepareEmbedConfig } from '@/helpers/formLayoutParameters';
import CONFIG_QUERY from '@/queries/config';
import CREATE_MUTATION from '@/queries/mutations/create';
import UPDATE_MUTATION from '@/queries/mutations/update';
import BaseService from './BaseService';

class FormConfigService extends BaseService {
  constructor() {
    super();
    this.configEntity = null;
    this.parsedFormConfigs = [];
    this.parsedEmbedConfigs = [];
    this.parsedMetaFormConfigs = [];
    this.debouncedUpdate = debounce(this.updateFormConfig, 200);
  }

  get config() {
    return this.configEntity?.data.config;
  }

  set config(value) {
    this.configEntity.data.config = value;
  }

  async load() {
    if (!this.configEntity) {
      const { data } = await store.state.apolloClientOldApi.query({
        ...CONFIG_QUERY,
        variables: { name: 'forms' },
      });

      this.prepareConfig(data.table.documents[0]);
    }

    return this.configEntity;
  }

  prepareConfig(configEntity) {
    let config = configEntity?.data?.config || {};
    config = parseJson(config);

    const globals = config.globals || {};
    const forms = this.prepareFormsConfig(config);
    const embeds = this.prepareEmbedsConfig(config);

    this.configEntity = Vue.observable({
      id: configEntity?.id,
      data: {
        title: 'forms',
        config: {
          globals,
          forms,
          embeds,
          metaForms: {},
        },
      },
    });
  }

  prepareFormsConfig(config) {
    const forms = config.forms || {};
    const parsed = Object.values(store.state.meta.entities).reduce((acc, meta) => {
      if (meta.publishable) {
        acc[meta.name] = forms[meta.name];
      }
      acc[meta.name] = this.parseFormConfig(meta, forms[meta.name]);
      return acc;
    }, {});

    return Object.values(store.state.meta.components).reduce((acc, meta) => {
      acc[meta.name] = this.parseFormConfig(meta, forms[meta.name]);
      return acc;
    }, parsed);
  }

  prepareEmbedsConfig(config) {
    const embeds = config.embeds || {};
    const parsed = Object.values(store.state.meta.components).reduce((acc, meta) => {
      acc[meta.name] = this.parseEmbedConfig(meta, embeds[meta.name]);
      return acc;
    }, {});

    return Object.values(store.state.meta.embeds).reduce((acc, meta) => {
      acc[meta.name] = this.parseEmbedConfig(meta, embeds[meta.name]);
      return acc;
    }, parsed);
  }

  getFormConfig(type) {
    if (!type) throw new Error('Missing argument[0] `Type`');

    const currentConfig = this.config.forms[type];
    if (currentConfig && this.parsedFormConfigs.includes(type)) {
      return currentConfig;
    }

    this.parsedFormConfigs.push(type);
    const parsedConfig = this.parseFormConfig(store.state.meta.components[type], currentConfig);

    if (!currentConfig) {
      this.config.forms[type] = parsedConfig;
    } else {
      Object.assign(this.config.forms[type], parsedConfig);
    }

    return this.config.forms[type];
  }

  getPublishableFormConfig(type, meta = null) {
    if (!type) throw new Error('Missing argument[0] `FormId`');
    if (!meta) throw new Error('Missing argument[1] `Meta`');

    const currentConfig = this.config.forms[type];
    if (currentConfig && this.parsedMetaFormConfigs.includes(type)) {
      return currentConfig;
    }

    // 22.06.2023
    // Временно отключаем кэш конфигов для publishable. Баг при
    // последовательном открытии формы на создание / обновление --
    // набор полей отличается, и из-за этого происходит ошибка.
    // this.parsedMetaFormConfigs.push(type);

    meta = {
      ...meta,
      fields: meta.fields.filter(
        (f) => !['history', 'history-entry', 'button', 'hidden'].includes(f.renderer),
      ),
    };

    const parsedConfig = this.parseFormConfig(meta, currentConfig);
    if (!currentConfig) {
      this.config.forms[type] = parsedConfig;
    } else {
      Object.assign(this.config.forms[type], parsedConfig);
    }

    return this.config.forms[type];
  }

  getMetaFormConfig(formId, meta = null) {
    if (!formId) throw new Error('Missing argument[0] `FormId`');
    if (!meta) throw new Error('Missing argument[1] `Meta`');

    const currentConfig = this.config.metaForms[formId];
    if (currentConfig && this.parsedMetaFormConfigs.includes(formId)) {
      return currentConfig;
    }

    this.parsedFormConfigs.push(formId);
    const parsedConfig = this.parseFormConfig(meta, currentConfig);

    if (!currentConfig) {
      this.config.metaForms[formId] = parsedConfig;
    } else {
      Object.assign(this.config.metaForms[formId], parsedConfig);
    }

    return this.config.metaForms[formId];
  }

  getEmbedConfig(type) {
    if (!type) throw new Error('Missing argument[0] `Type`');

    const currentConfig = this.config.embeds[type];

    if (currentConfig && this.parsedEmbedConfigs.includes(type)) {
      return currentConfig;
    }

    this.parsedEmbedConfigs.push(type);
    this.config.embeds[type] = this.parseEmbedConfig(
      store.state.meta.embeds[type] || store.state.meta.components[type],
      currentConfig,
    );

    return this.config.embeds[type];
  }

  getProcessFormConfig(type) {
    if (!type) throw new Error('Missing argument[0] `Type`');

    const currentConfig = this.config.forms[type];
    if (currentConfig && this.parsedFormConfigs.includes(type)) {
      return currentConfig;
    }

    this.parsedFormConfigs.push(type);
    const parsedConfig = this.parseFormConfig(store.state.meta.processes[type], currentConfig);

    if (!currentConfig) {
      this.config.forms[type] = parsedConfig;
    } else {
      Object.assign(this.config.forms[type], parsedConfig);
    }

    return this.config.forms[type];
  }

  parseFormConfig(entityMeta, currentConfig) {
    return prepareFormConfig(entityMeta, currentConfig);
  }

  parseEmbedConfig(entityMeta, currentConfig) {
    return prepareEmbedConfig(entityMeta, currentConfig);
  }

  updateFormConfigField(type, fieldName, parameter, value, transProvider) {
    const config = this.getFormConfig(type);
    const field = config.tabs
      .map((tab) => tab.columns.flat())
      .flat()
      .find(({ name }) => name === fieldName);

    field[parameter] = value;
    this.debouncedUpdate(transProvider);
  }

  updateEmbedConfigField(type, fieldName, parameter, value, transProvider) {
    const config = this.getEmbedConfig(type);
    const field = config.columns.find(({ name }) => name === fieldName);
    field[parameter] = value;
    this.debouncedUpdate(transProvider);
  }

  async updateFormConfig(transProvider) {
    const { data, error } = await store.state.apolloClientOldApi.mutate({
      mutation: this.configEntity.id ? UPDATE_MUTATION : CREATE_MUTATION,
      variables: {
        type: 'Config',
        id: this.configEntity.id,
        data: preparePayload(this.configEntity.data, store.state.meta.components.Config.fields),
      },
    });

    if (error) {
      this.emitError(transProvider.$t('entity.error.configSave'), error.message);
      return;
    }

    if (data.create) {
      this.configEntity.id = data.create.document.id;
    }
  }
}

export default new FormConfigService();
