<template>
  <error-output :errors="errors">
    <a-textarea
      ref="focusOnMount"
      v-model="input"
      class="form-textarea"
      :style="{ height }"
      :disabled="!readOnly && disabled"
      :readOnly="readOnly"
    />
  </error-output>
</template>

<script>
import FocusOnMountMixin from '@/components/base/focusOnMount.mixin';
import ErrorOutput from '@/components/base/ErrorOutput';

export default {
  name: 'EditFormText',
  mixins: [FocusOnMountMixin],
  components: {
    ErrorOutput,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    height() {
      return `${this.config.textHeight}px`;
    },
  },
};
</script>

<style lang="scss">
.form-textarea.ant-input {
  height: 100px;
  resize: vertical;
}
</style>
