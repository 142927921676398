<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->
  <section class="auro-release">
    <header class="auro-release__header">
      <a-row
        type="flex"
        justify="space-between"
      >
        <a-col>
          {{ release.artistRef.title }}
        </a-col>
        <a-col v-if="release.artistRef">
          <router-link :to="artistRoute">
            {{ $t('aurora.artistInfo') }}
          </router-link>
        </a-col>
      </a-row>
    </header>

    <section class="auro-release__section">
      <a-row :gutter="30">
        <a-col :span="5">
          <base-image
            v-if="release.image"
            class="auro-release__cover"
            :path="release.image"
          />
          <div
            v-else
            class="auro-release__cover auro-release__cover--empty"
          ></div>

          <div class="auro-release__genres">
            <a-tag
              v-for="genre of release.genreRef"
              :key="genre.value"
              class="auro-release__order-status"
              color="blue"
            >
              {{ genre.title }}
            </a-tag>
          </div>

          <h3>
            {{ getLabel('Release', 'createdAt') }}
          </h3>
          <field-display
            :field="getFieldConfig('Release', 'createdAt')"
            :value="release.createdAt"
          />
          <h3>
            {{ getLabel('Release', 'id') }}
          </h3>
          <field-display
            :field="getFieldConfig('Release', 'id')"
            :value="release.id"
          />
        </a-col>
        <a-col :span="12">
          <!-- eslint-disable-next-line vuejs-accessibility/heading-has-content -->
          <h2>
            <field-display
              :field="getFieldConfig('Release', 'title')"
              :value="release.title"
            />
          </h2>

          <div class="auro-release__type">
            <field-display
              :field="getFieldConfig('Release', 'type')"
              :value="release.type"
            />
          </div>

          <div class="auro-release__date">
            <field-display
              :field="getFieldConfig('Release', 'date')"
              :value="release.date"
            />
          </div>

          <template v-for="(value, field) of release">
            <template v-if="!skipFields.includes(field) && nonEmptyValue(value)">
              <h3 :key="field + '-title'">{{ getLabel('Release', field) }}</h3>
              <field-display
                :key="field"
                :field="getFieldConfig('Release', field)"
                :value="value"
              >
                <template
                  #embedd
                  v-if="field === 'tracks'"
                >
                  <a-collapse :bordered="false">
                    <a-collapse-panel
                      v-for="(track, trackIndex) of value"
                      :key="trackIndex"
                      :header="track.data.name"
                    >
                      <template v-for="(trackProp, trackField) of track.data">
                        <template
                          v-if="!skipTrackFields.includes(trackField) && nonEmptyValue(trackProp)"
                        >
                          <h3 :key="trackField + '-title'">
                            {{ getEmbedLabel('Track', trackField) }}
                          </h3>
                          <field-display
                            :key="trackField"
                            :field="getTrackFieldConfig(trackField)"
                            :value="trackProp"
                          />
                        </template>
                      </template>
                      <!-- eslint-disable-next-line vuejs-accessibility/media-has-caption -->
                      <audio
                        v-if="track.data.file"
                        controls
                        :src="mediaUrl(track.data.file)"
                      >
                        {{ $t('app.noAudioSupport') }}
                      </audio>
                    </a-collapse-panel>
                  </a-collapse>
                </template>
              </field-display>
            </template>
          </template>
        </a-col>
        <a-col :span="7">
          <h3>{{ $t('aurora.comment') }}</h3>
          <edit-form-text
            v-model="setStatusData.comment"
            :placeholder="$t('SOPromocodes.placeholders.codes')"
            :config="{ textHeight: 90 }"
          />

          <h3>{{ getLabel('Release', 'status') }}</h3>
          <ref-autocomplete2
            v-model="setStatusData.status"
            :config="getFieldConfig('Release', 'status')"
          />
          <a-button
            type="primary"
            :disabled="!setStatusData.status || !setStatusData.comment"
            @click="setStatus"
          >
            {{ $t('entity.save') }}
          </a-button>
          <auro-release-history :release="release" />
        </a-col>
      </a-row>
    </section>
  </section>
</template>

<script>
import store from '@/store';
import { mediaUrl } from '@/helpers';
import FormConfigService from '@/services/FormConfigService';
import BaseImage from '@/components/base/BaseImage';
import EditFormText from '@/components/edit-form/fields/EditFormText';
import RefAutocomplete2 from '@/components/edit-form/fields/ref/RefAutocomplete2';
import FieldDisplay from '../FieldDisplay';
import AuroReleaseHistory from './AuroReleaseHistory';

export default {
  name: 'AuroReleaseCard',

  components: {
    BaseImage,
    FieldDisplay,
    EditFormText,
    RefAutocomplete2,
    AuroReleaseHistory,
  },

  props: {
    release: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      skipFields: [
        'artistRef',
        'image',
        'genresRef',
        'comment',
        'createdAt',
        'updated',
        'status',
        'id',
        'title',
        'type',
        'date',
      ],
      skipTrackFields: ['name', 'file'],
      setStatusData: {
        releaseId: this.release.id,
        status: this.release.status,
        comment: '',
      },
    };
  },

  computed: {
    artistRoute() {
      return {
        name: 'DataPagePopup',
        params: {
          type: this.$route.params.type,
          pId: this.release.artistRef.value.split(':')[1],
          pType: 'Artist',
        },
      };
    },
  },

  watch: {
    release() {
      this.setStatusData.comment = '';
    },
  },

  methods: {
    mediaUrl,

    nonEmptyValue(value) {
      return !!(Array.isArray(value) ? value?.length : value);
    },

    getFieldConfig(type, name) {
      const field = store.state.meta.entities
        .find((e) => e.name === type)
        .fields.find((f) => f.name === name);

      return field || { name, renderer: 'string' };
    },

    getTrackFieldConfig(name) {
      const field = store.state.meta.embeds.Track.fields.find((f) => f.name === name);

      return field || { name, renderer: 'string' };
    },

    getLabel(entity, name) {
      return (
        FormConfigService.getFormConfig(entity).locale[store.state.lang].fields[name]?.label || name
      );
    },

    getEmbedLabel(entity, name) {
      return (
        FormConfigService.getEmbedConfig(entity).locale[store.state.lang].fields[name]?.label ||
        name
      );
    },

    setStatus() {
      this.$emit('setStatus', this.setStatusData);
    },
  },
};
</script>

<style lang="scss">
.auro-release {
  background-color: #f9f9f9;
  width: 100%;

  .ant-form-item-control {
    line-height: 32px;
  }

  &__header {
    padding: 20px 22px;
    margin-bottom: 25px;
    font-weight: 500;
    background: rgba(0, 0, 0, 0.05);
  }

  &__section {
    padding: 0 22px 20px;
  }

  &__cover {
    max-width: 100%;
    margin-bottom: 25px;

    &--empty {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      padding: 50% 0;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.25);

      &:before,
      &:after {
        position: absolute;
        top: 0;
        width: 1px;
        height: 150%;
        background: rgba(0, 0, 0, 0.25);
        content: '';
      }

      &:before {
        left: 0;
        transform: rotate(-45deg);
        transform-origin: top left;
      }

      &:after {
        right: 0;
        transform: rotate(45deg);
        transform-origin: top right;
      }
    }
  }

  &__genres {
    margin-bottom: 12px;
  }

  &__type {
    margin: -22px 0 -5px;
  }

  &__date {
    margin-bottom: 25px;
  }

  // .ant-collapse-borderless >
  .ant-collapse-item:last-child {
    border-bottom: none;
  }
}
</style>
